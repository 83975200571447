//@flow
import DOMPurify from "dompurify";

export const stripBodyTags = (description: string) => {
    // eslint-disable-next-line no-useless-escape
    const bodyStartRegex = /<body(\s?[\w\d\.\/:"=]*)*>/gim,
        bodyEndRegex = /<\/body>/gim;
    return DOMPurify.sanitize(
        description.replace(bodyStartRegex, "").replace(bodyEndRegex, ""),
    );
};
