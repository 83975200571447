// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { set } from "./assignmentsValues";

const { constants: _constants, reducer, actions } = createApiModule(
    "inspection",
    ({ inspectionId }) => {
        return `/inspections/${inspectionId}`;
    },
    {
        errorHandler: notifyApiError("Opdracht details ophalen mislukt"),
    },
);

export const loadInspection = (
    inspectionId: string,
    overrideErrorMessage?: string,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionId } }, true, overrideErrorMessage),
    ).then(response => {
        if (response && response.type === constants.SUCCESS) {
            return dispatch(
                set({
                    selectedRecordIndex: null,
                    selectedRecord: response.payload,
                }),
            );
        }
        return false;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
