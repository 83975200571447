// @flow

import type { Person } from "@types";

export const display = (person?: ?Person) => {
    if (!person) return "";
    return `${person.firstName} ${person.lastName}`;
};

export const sort = (a: Person, b: Person) => {
    const aName = display(a).toLowerCase();
    const bName = display(b).toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
};
