// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "assignToMe",
    ({ inspectionId }) => `/inspections/${inspectionId}/assignee`,

    {
        method: "PUT",
        errorHandler: notifyApiError("Opdracht kon niet worden toegewezen"),
    },
);

export const assignToMe = (inspectionId: string) => (dispatch: *) => {
    return dispatch(load({ path: { inspectionId } }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Opdracht is met succes toegewezen",
            }),
        );

        dispatch(loadInspection(inspectionId));
        return true;
    });
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
