// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { storage } from "@utils";
import { set as setContacts } from "./contactPersonsValue";

const { constants: _constants, reducer, actions } = createApiModule(
    "getContactPersons",
    ({ inspectionId }) => `/inspections/${inspectionId}/contacts`,

    {
        method: "GET",
        errorHandler: notifyApiError("Ontvangers verslagen ophalen mislukt"),
    },
);

export const getContactPersons = (inspectionId: string) => (dispatch: *) => {
    const offlineContacts = storage.get(`offline-contacts-${inspectionId}`);
    let parsedContacts = [];
    if (offlineContacts) {
        parsedContacts = JSON.parse(offlineContacts); // string to Array
    }
    if (navigator.onLine) {
        return dispatch(load({ path: { inspectionId } }, true)).then(action => {
            if (action && action.type === constants.SUCCESS) {
                return dispatch(
                    setContacts([...action.payload, ...parsedContacts]),
                );
            }
            return dispatch(setContacts([]));
        });
    } else {
        return dispatch(setContacts(parsedContacts));
    }
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
