// @flow

import * as yup from "yup";
import moment from "moment-timezone";
import { REQUESTS, ASSIGNMENTS, VALIDATION, REGEX } from "@constants";

/**
 * SelectedPoint
 */
export const selectedPoint = yup.object().shape({
    selectedPoint: yup.object().required(),
});

/**
 * Selected Assignment Reasons
 */
export const selectedAssignmentReasons = yup.object().shape({
    selectedReasons: yup
        .array()
        .of(
            yup
                .object()
                .shape({
                    shortDescription: yup.string(),
                    id: yup.string().required(),
                    comment: yup.string().nullable(),
                })
                .required(),
        )
        .min(1)
        .required(VALIDATION.REQUIRED),
});

export const locationsAndTypes = yup.object().shape({
    visits: yup.array().nullable(),
    activityTypes: yup
        .array()
        .of(yup.string())
        .nullable(),
    institutionTypes: yup
        .array()
        .of(yup.string())
        .nullable(),
    mainInstitutionType: yup
        .string()
        .nullable()
        .when("selectedPointType", {
            is: "VOORZIENING",
            then: yup
                .string()
                .nullable()
                .notRequired(),
            otherwise: yup.string().required(),
        }),
    selectedPointType: yup.string().nullable(),
});

/**
 * Selected Request Reason
 */
export const selectedRequestReason = yup.object().shape({
    selectedReasons: yup
        .array()
        .of(
            yup.object().shape({
                id: yup.string().required(),
                shortDescription: yup.string().required(),
                comment: yup.string().nullable(),
            }),
        )
        .min(1)
        .max(1)
        .required(VALIDATION.REQUIRED),
});

/*
 *  checker for xxxDateBefore
 *  should be after createdAt and after xxxAfterDate
 *  WARNING: typeError always take priority over required for dates, even if null
 */
const checkAgainstCreationAndAfter = (creationDate, afterDate, schema) => {
    const validCreation =
        !!creationDate && yup.date().isValidSync(creationDate);
    const validAfter = !!afterDate && yup.date().isValidSync(afterDate);
    if (!validCreation && !validAfter) {
        return schema
            .typeError(VALIDATION.VALID_BEFORE_AFTER_DATE)
            .required(VALIDATION.VALID_BEFORE_AFTER_DATE);
    }

    let limiter;
    if (validCreation && validAfter)
        limiter = moment.max(
            moment(afterDate).add(1, "day"),
            moment(creationDate),
        );
    else
        limiter = validAfter
            ? moment(afterDate).add(1, "day")
            : validCreation && creationDate;
    const schemaWithOptionalLimit = limiter
        ? schema.min(
              limiter,
              "Deze datum moet minimum op de aanmaakdatum of 1 dag na de begindatum liggen",
          )
        : schema;
    return yup.date().isValidSync(afterDate)
        ? schemaWithOptionalLimit.nullable().typeError(VALIDATION.INVALID_DATE)
        : schemaWithOptionalLimit
              .typeError(VALIDATION.VALID_BEFORE_AFTER_DATE)
              .required(VALIDATION.VALID_BEFORE_AFTER_DATE);
};

/*
 *  checker for xxxDateAfter
 *  should be after createdAt
 *  WARNING: typeError always take priority over required for dates, even if null
 */
const checkAgainstCreationAndBefore = (creationDate, beforeDate, schema) => {
    const schemaWithOptionalLimit =
        !!creationDate && yup.date().isValidSync(creationDate)
            ? schema.min(
                  creationDate,
                  "Deze datum moet na de aanmaakdatum liggen.",
              )
            : schema;
    return yup.date().isValidSync(beforeDate)
        ? schemaWithOptionalLimit.nullable().typeError(VALIDATION.INVALID_DATE)
        : schemaWithOptionalLimit
              .typeError(VALIDATION.VALID_BEFORE_AFTER_DATE)
              .required(VALIDATION.VALID_BEFORE_AFTER_DATE);
};

/**
 * Selected Dates
 */
export const rangePicker = (
    creationKey: string,
    beforeKey: string,
    afterKey: string,
) =>
    yup.object().shape(
        {
            creationLimit: yup.date(),
            [creationKey]: yup
                .date()
                .required(VALIDATION.REQUIRED)
                .typeError(VALIDATION.INVALID_DATE)
                .when("creationLimit", (creationLimit, schema) =>
                    creationLimit
                        ? schema.max(
                              creationLimit,
                              "Deze datum kan enkel richting het verleden aangepast worden",
                          )
                        : schema,
                ),
            [afterKey]: yup
                .date()
                .default(null)
                .when([creationKey, beforeKey], checkAgainstCreationAndBefore),
            [beforeKey]: yup
                .date()
                .default(null)
                .when([creationKey, afterKey], checkAgainstCreationAndAfter),
        },
        [[afterKey, beforeKey]],
    );

/*
 * schema containing only the dates for a request
 */
export const requestDateRange = rangePicker(...REQUESTS.DATE_KEYS);
export const editRequestDateRange = rangePicker(...REQUESTS.EDIT_DATE_KEYS);
export const selectedDatesRequest = yup
    .object()
    .shape({
        externalReference: yup.string(),
        extraInformation: yup.string().nullable(),
    })
    .concat(requestDateRange);
/*
 * schema containing only the dates for an inspection
 */
export const inspectionDateRange = rangePicker(...ASSIGNMENTS.DATE_KEYS);
export const selectedDatesInspection = yup
    .object()
    .shape({
        externalReference: yup.string(),
        extraInformation: yup.string().nullable(),
    })
    .concat(inspectionDateRange);

export const addressSchema = (requiredMessage: *, zipCodeLimit: number = 10) =>
    yup.object().shape({
        street: yup
            .string()
            .ensure()
            .matches(
                /^(?=.*[A-Za-zÀ-ÿ])([A-Za-zÀ-ÿ0-9.'*`´’,\- "]{1,100})$/gm,
                VALIDATION.STREET,
            )
            .max(100, VALIDATION.MAX_CHARACTERS)
            .required(requiredMessage || VALIDATION.REQUIRED),
        streetNumber: yup
            .string()
            .ensure()
            .matches(
                /^(?=.*[0-9])([A-Za-z0-9()\x20/]{1,20})$/gm,
                VALIDATION.STREETNUMBER,
            )
            .max(20, VALIDATION.MAX_CHARACTERS)
            .required(requiredMessage || VALIDATION.REQUIRED),
        zipCode: yup
            .string()
            .ensure()
            .min(4, VALIDATION.MIN_CHARACTERS)
            .max(zipCodeLimit, VALIDATION.MAX_CHARACTERS)
            .matches(/^(?:(?:[1-9])(?:\d{3}))$/gm, VALIDATION.ZIPCODE)
            .required(requiredMessage || VALIDATION.REQUIRED),
        city: yup
            .string()
            .ensure()
            .max(64, VALIDATION.MAX_CHARACTERS)
            .matches(/^[A-Za-zÀ-ÿ.'*`´’,\- "]{1,64}$/gm, VALIDATION.CITY)
            .required(requiredMessage || VALIDATION.REQUIRED),
    });

const countrySchema = (requiredMessage /*, required*/) =>
    yup.object().shape({
        country: yup
            .string()
            .ensure()
            .max(20, VALIDATION.MAX_CHARACTERS)
            .required(requiredMessage || VALIDATION.REQUIRED),
    });

const addressWithCountrySchema = (requiredMessage: string) =>
    addressSchema(requiredMessage).concat(countrySchema(requiredMessage));

/*
 *   Submitter
 */
const hasPartialValues = address => {
    const keys = Object.keys(address);
    return keys.length > 0 && keys.some(key => !address[key]);
};

export const submitter = yup.object().shape(
    {
        anonymity: yup
            .mixed()
            .oneOf(["NONE", "FULL", "INSPECTION_POINT"])
            .required(VALIDATION.REQUIRED),
        dutchSpeaking: yup.boolean(),
        submitterFirstName: yup
            .string()
            .max(100, VALIDATION.MAX_CHARACTERS)
            .when("anonymity", (anonymity, schema) =>
                anonymity !== "FULL"
                    ? schema.required(VALIDATION.REQUIRED)
                    : schema,
            ),
        submitterLastName: yup
            .string()
            .max(100, VALIDATION.MAX_CHARACTERS)
            .when("anonymity", (anonymity, schema) =>
                anonymity !== "FULL"
                    ? schema.required(VALIDATION.REQUIRED)
                    : schema,
            ),
        submitterEmailAddress: yup
            .string()
            .matches(REGEX.EMAIL, {
                message: VALIDATION.EMAIL,
                excludeEmptyString: true,
            })
            .when(
                ["submitterAddress", "anonymity"],
                (submitterAddress, anonymity) =>
                    anonymity !== "FULL" && // not anonymous AND partially filled address
                    (!submitterAddress || hasPartialValues(submitterAddress))
                        ? yup
                              .string()
                              .matches(REGEX.EMAIL, {
                                  message: VALIDATION.EMAIL,
                                  excludeEmptyString: true,
                              })
                              .required(VALIDATION.EMAIL_OR_ADDRESS)
                        : yup
                              .string()
                              .matches(REGEX.EMAIL, {
                                  message: VALIDATION.EMAIL,
                                  excludeEmptyString: true,
                              })
                              .nullable(),
            ),
        submitterPhoneNumber: yup.string(),
        submitterAddress: yup
            .object()
            .when(
                ["anonymity", "submitterEmailAddress"],
                (anonymity, submitterEmailAddress) =>
                    anonymity === "FULL" ||
                    yup
                        .string()
                        .matches(REGEX.EMAIL, {
                            message: VALIDATION.EMAIL,
                            excludeEmptyString: true,
                        })
                        .required()
                        .isValidSync(submitterEmailAddress) // full anonimity OR valid email => optional
                        ? addressWithCountrySchema(
                              VALIDATION.ADDRESS_COMPLETE,
                          ).defined()
                        : addressWithCountrySchema(VALIDATION.EMAIL_OR_ADDRESS)
                              .typeError(VALIDATION.EMAIL_OR_ADDRESS)
                              .required(VALIDATION.EMAIL_OR_ADDRESS),
            ), // mark whole object as required
    },
    [["submitterEmailAddress", "submitterAddress"]],
);

/**
 * Contact Functional Entity
 */
export const contactFunctionalEntity = yup.object().shape({
    requestedBy: yup.string().required(VALIDATION.REQUIRED),
    functionalEntityTeamId: yup.string().nullable(),
    feContactFirstName: yup.string().when("requestedBy", requestedBy => {
        return requestedBy === "FE"
            ? yup.string().required(VALIDATION.REQUIRED)
            : yup.string();
    }),
    feContactLastName: yup.string().when("requestedBy", requestedBy => {
        return requestedBy === "FE"
            ? yup.string().required(VALIDATION.REQUIRED)
            : yup.string();
    }),
    feContactEmailAddress: yup.string().when("requestedBy", requestedBy => {
        return requestedBy === "FE"
            ? yup
                  .string()
                  .matches(REGEX.EMAIL, {
                      message: VALIDATION.EMAIL,
                      excludeEmptyString: true,
                  })
                  .required(VALIDATION.REQUIRED)
            : yup.string().matches(REGEX.EMAIL, {
                  message: VALIDATION.EMAIL,
                  excludeEmptyString: true,
              });
    }),
});

/**
 *  Visit form
 */

export const VisitPeriod = yup.object().shape({
    startVisitDate: yup
        .date()
        .typeError(VALIDATION.INVALID_DATE)
        .required(VALIDATION.REQUIRED),
    startVisitTime: yup
        .date()
        .typeError(VALIDATION.INVALID_TIME)
        .required(VALIDATION.REQUIRED),
    endVisitDate: yup
        .date()
        .typeError(VALIDATION.INVALID_TIME)
        .required(VALIDATION.REQUIRED)
        .when("startVisitDate", (startVisitDate, schema) =>
            startVisitDate !== null && yup.date().isValidSync(startVisitDate)
                ? schema.min(
                      startVisitDate,
                      "Eindmoment bezoek moet na het startmoment liggen",
                  )
                : schema,
        ),
    announced: yup.boolean(),
});

export const VisitSchema = yup.object().shape({
    title: yup
        .string()
        .required(VALIDATION.REQUIRED)
        .max(256, VALIDATION.MAX_CHARACTERS),
    inspectionPointName: yup
        .string()
        .ensure()
        .required(VALIDATION.REQUIRED),
    address: addressSchema(undefined, 4).required(VALIDATION.REQUIRED),
    physicalVisit: yup.boolean(),
});

/**
 * Add reaction
 */
export const reaction = yup.object().shape({
    receivedAt: yup
        .date()
        .max(moment().endOf("day"), VALIDATION.NOT_IN_FUTURE)
        .required("Dit veld is verplicht"),
    type: yup.string().required("Dit veld is verplicht"),
    content: yup.string().nullable(),
    senderType: yup.string().required("Dit veld is verplicht"),
});

export const DateFilterEditMode = yup.object().shape({
    daysInFuture: yup
        .number()
        .min(0)
        .max(100),
    daysInPast: yup
        .number()
        .min(0)
        .max(100),
});
/*
 *   Date Range filter
 */
export const DateRangeFilter = yup.object().shape(
    {
        beforeFrom: yup
            .date()
            .nullable()
            .typeError(VALIDATION.INVALID_DATE)
            .when("beforeUntil", (beforeUntil, schema) =>
                beforeUntil &&
                beforeUntil !== null &&
                yup.date().isValidSync(beforeUntil)
                    ? schema.max(
                          beforeUntil,
                          "Datum moet voor 'tot start datum' zijn",
                      )
                    : schema,
            ),
        beforeUntil: yup
            .date()
            .nullable()
            .typeError(VALIDATION.INVALID_DATE)
            .when("beforeFrom", (beforeFrom, schema) =>
                beforeFrom &&
                beforeFrom !== null &&
                yup.date().isValidSync(beforeFrom)
                    ? schema.min(
                          beforeFrom,
                          "Datum moet na 'voor start datum' zijn",
                      )
                    : schema,
            ),
        afterFrom: yup
            .date()
            .nullable()
            .typeError(VALIDATION.INVALID_DATE)
            .when("afterUntil", (afterUntil, schema) =>
                afterUntil &&
                afterUntil !== null &&
                yup.date().isValidSync(afterUntil)
                    ? schema.max(
                          afterUntil,
                          "Datum moet voor 'tot eind datum' zijn",
                      )
                    : schema,
            ),
        afterUntil: yup
            .date()
            .nullable()
            .typeError(VALIDATION.INVALID_DATE)
            .when("afterFrom", (afterFrom, schema) =>
                afterFrom &&
                afterFrom !== null &&
                yup.date().isValidSync(afterFrom)
                    ? schema.min(
                          afterFrom,
                          "Datum moet na 'voor eind datum' zijn",
                      )
                    : schema,
            ),
    },
    [
        ["beforeFrom", "beforeUntil"],
        ["beforeUntil", "beforeFrom"],
        ["afterFrom", "afterUntil"],
        ["afterUntil", "afterFrom"],
    ],
);
/*
 *   Date filter for tables
 */
export const DateFilter = yup.object().shape(
    {
        before: yup
            .date()
            .typeError(VALIDATION.INVALID_DATE)
            .required(VALIDATION.REQUIRED)
            .when("after", (afterDate, schema) =>
                afterDate && yup.date().isValidSync(afterDate)
                    ? schema.min(
                          afterDate,
                          "EindDatum moet gelijk aan of na startDatum zijn",
                      )
                    : schema,
            ),
        after: yup
            .date()
            .typeError(VALIDATION.INVALID_DATE)
            .required(VALIDATION.REQUIRED)
            .when("before", (beforeDate, schema) =>
                beforeDate && yup.date().isValidSync(beforeDate)
                    ? schema.max(
                          beforeDate,
                          "StartDatum moet gelijk aan of voor einddatum zijn",
                      )
                    : schema,
            ),
        notFilled: yup.boolean(),
        noVisit: yup.boolean(),
    },
    [["before", "after"]],
);

/*
 *   Date filter for tables
 */
export const SingleDateFilter = yup.object().shape(
    {
        date: yup
            .date()
            .typeError(VALIDATION.INVALID_DATE)
            .required(VALIDATION.REQUIRED),
        notFilled: yup.boolean(),
        noVisit: yup.boolean(),
    },
    [["date"]],
);

export const ExemptionSchema = yup.object().shape(
    {
        description: yup.string().required(VALIDATION.REQUIRED),
        startDate: yup
            .date()
            .typeError(VALIDATION.INVALID_DATE)
            .required(VALIDATION.REQUIRED),
        endDate: yup
            .date()
            .nullable()
            .typeError(VALIDATION.INVALID_DATE)
            .when("startDate", (startDate, schema) =>
                startDate && yup.date().isValidSync(startDate)
                    ? schema.min(
                          startDate,
                          "Einddatum moet gelijk aan of na startdatum zijn",
                      )
                    : schema,
            ),
        // TODO: figure out modules...
    },
    [["startDate", "endDate"]],
);

export const ExemptionModuleSchema = yup.object().shape({
    moduleId: yup
        .string()
        .ensure()
        .required(VALIDATION.REQUIRED),
});

export const NewInspectionPointSchema = yup
    .object()
    .shape({
        parent: yup.object().shape({
            name: yup.string(),
            inspectionPointId: yup.string(),
        }),
        name: yup
            .string()
            .ensure()
            .required(VALIDATION.REQUIRED),
        type: yup
            .string()
            .ensure()
            .required(VALIDATION.REQUIRED),
        enterpriseNumber: yup.string().when("type", {
            is: "INRICHTENDE_MACHT",
            then: yup
                .string()
                .ensure()
                .required(VALIDATION.REQUIRED),
            otherwise: yup.string().nullable(),
        }),
        approvalNumber: yup
            .string()
            .ensure()
            .nullable(),
        fileNumber: yup
            .string()
            .nullable()
            .ensure(),
        nrn: yup
            .string()
            .ensure()
            .when("type", (type, schema) =>
                type === "PERSOON"
                    ? schema.required(VALIDATION.REQUIRED)
                    : schema.nullable(),
            ),
        institutionTypeId: yup.string().when("type", {
            is: "VOORZIENING",
            then: yup
                .string()
                .ensure()
                .required(VALIDATION.REQUIRED),
            otherwise: yup.string().nullable(),
        }),
        activityTypeIds: yup
            .array()
            .nullable()
            .when("type", {
                is: "UITBATINGSPLAATS",
                then: yup
                    .array()
                    .min(1, "Kies minimum 1 zorgactiviteitstype")
                    .required(VALIDATION.REQUIRED),
                otherwise: yup.array().nullable(),
            }),
    })
    .concat(addressSchema(undefined, 4));

export const AddFlagSchema = yup.object().shape({
    type: yup
        .string()
        .oneOf(["AIP", "FIN"])
        .required(VALIDATION.REQUIRED),
    comment: yup.string().required(VALIDATION.REQUIRED),
    inspectionPointFlagId: yup.string().required(VALIDATION.REQUIRED),
    startDate: yup
        .date()
        .typeError(VALIDATION.INVALID_DATE)
        .required(VALIDATION.REQUIRED)
        .max(moment().endOf("day"), VALIDATION.NOT_IN_FUTURE),
    endDate: yup
        .date()
        .nullable()
        .typeError(VALIDATION.INVALID_DATE)
        .when("startDate", (startDate, schema) =>
            startDate && yup.date().isValidSync(startDate)
                ? schema.min(startDate, VALIDATION.NOT_BEFORE_START_DATE)
                : schema,
        ),
});

/**
 * ValidationSchemas for VersionControl
 */
export const VersionControlActivateModalSchema = yup.object().shape({
    formDate: yup
        .date()
        .typeError(VALIDATION.INVALID_DATE)
        .min(moment().startOf("day"), VALIDATION.NOT_IN_PAST)
        .required(VALIDATION.REQUIRED),

    remark: yup.string().required(VALIDATION.REQUIRED),
});
export const VersionControlDeactivateModalSchema = yup.object().shape({
    formDate: yup
        .date()
        .typeError(VALIDATION.INVALID_DATE)
        .min(moment().startOf("day"), VALIDATION.NOT_IN_PAST)
        .required(VALIDATION.REQUIRED),

    remark: yup.string().required(VALIDATION.REQUIRED),
});
export const VersionControlCancelModalSchema = yup.object().shape({
    formDate: yup.date().typeError(VALIDATION.INVALID_DATE),
    remark: yup.string(),
});
