// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadTemplates } from "./notification-templates";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteTemplate",
    ({ templateId }) => `/notification-templates/${templateId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Sjabloon verwijderen mislukt"),
    },
);

export const deleteTemplate = (templateId: string) => (dispatch: *) => {
    return dispatch(load({ path: { templateId } }, true)).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Sjabloon is succesvol verwijderd",
                }),
            );

            return dispatch(loadTemplates({}, true));
        }
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
