// @flow
import type { Definition } from "@types";

export const internalGroups: Definition = [
    {
        label: "Voornaam",
        id: "first-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Achternaam",
        id: "last-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Mailadres",
        id: "member-email-id",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export const editInternalGroups: Definition = [
    {
        label: "Voornaam",
        id: "first-name",
        isSortable: true,
        sortName: "firstName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Achternaam",
        id: "last-name",
        isSortable: true,
        sortName: "lastName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Mailadres",
        id: "email-address",
        isSortable: true,
        sortName: "emailAddress",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Acties",
        id: "member-actions-id",
        isSortable: false,
        isFilterable: false,
        type: "actions",
    },
];
export const externalGroups: Definition = [
    {
        label: "Voornaam",
        id: "first-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Achternaam",
        id: "last-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Mailadres",
        id: "member-email-id",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export const editExternalGroups: Definition = [
    {
        label: "Voornaam",
        id: "first-name",
        isSortable: true,
        sortName: "firstName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Achternaam",
        id: "last-name",
        isSortable: true,
        sortName: "lastName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Mailadres",
        id: "email-address",
        isSortable: true,
        sortName: "emailAddress",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Acties",
        id: "member-actions-id",
        isSortable: false,
        isFilterable: false,
        type: "actions",
    },
];

export const clusters: Definition = [
    {
        label: "Voornaam",
        id: "first-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Achternaam",
        id: "last-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Mailadres",
        id: "member-email-id",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export const editClustersGroups: Definition = [
    {
        label: "Interne groep",
        id: "internal-group",
        isSortable: true,
        sortName: "internalGroup",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Acties",
        id: "cluster-group-actions-id",
        isSortable: false,
        isFilterable: false,
        type: "actions",
    },
];

export const editClusters: Definition = [
    {
        label: "Voornaam",
        id: "first-name",
        isSortable: true,
        sortName: "firstName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Achternaam",
        id: "last-name",
        isSortable: true,
        sortName: "lastName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Mailadres",
        id: "email-address",
        isSortable: true,
        sortName: "emailAddress",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Acties",
        id: "member-actions-id",
        isSortable: false,
        isFilterable: false,
        type: "actions",
    },
];

export const editInternalPersons: Definition = [
    {
        label: "Voornaam",
        id: "first-name",
        isSortable: true,
        sortName: "firstName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Roepnaam",
        id: "call-name",
        isSortable: true,
        sortName: "callName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Achternaam",
        id: "last-name",
        isSortable: true,
        sortName: "lastName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Mailadres",
        id: "email-address",
        isSortable: true,
        sortName: "emailAddress",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Team",
        id: "team-id",
        isSortable: true,
        sortName: "teamId",
        isFilterable: true,
        type: "team",
    },
    {
        label: "Groep",
        id: "group-id",
        isSortable: true,
        sortName: "groupId",
        isFilterable: true,
        type: "internalGroup",
    },
    {
        label: "Cluster",
        id: "cluster-id",
        isSortable: true,
        sortName: "clusterId",
        isFilterable: true,
        type: "cluster",
    },
];

export const editExternalPersons: Definition = [
    {
        label: "Voornaam",
        id: "first-name",
        isSortable: true,
        sortName: "firstName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Roepnaam",
        id: "call-name",
        isSortable: true,
        sortName: "callName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Achternaam",
        id: "last-name",
        isSortable: true,
        sortName: "lastName",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Mailadres",
        id: "email-address",
        isSortable: true,
        sortName: "emailAddress",
        isFilterable: true,
        type: "text",
    },
    {
        label: "Groep",
        id: "group-id",
        isSortable: true,
        sortName: "groupId",
        isFilterable: true,
        type: "externalGroup",
    },
];
