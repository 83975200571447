// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "linkInspectionPoint",
    ({ inspectionPointId, externalId }) =>
        `/inspectionpoints/${inspectionPointId}?external-inspection-point-id=${externalId}`,
    {
        method: "PATCH",
        errorHandler: notifyApiError(
            "Modular inspectiepunt linken aan externe bron mislukt",
        ),
    },
);
export const linkInspectionPoint = (
    inspectionPointId: string,
    externalId: string,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionPointId, externalId } }, true),
    ).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Inspectiepunt succesvol gelinkt aan externe bron",
            }),
        );
        return true;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
