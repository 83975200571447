// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "startReactionPeriod",
    ({ inspectionId }) => `/inspections/${inspectionId}/reaction-period`,

    {
        method: "Post",
        errorHandler: notifyApiError("Reactietermijn starten mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

/*
 *   Send Notification
 */
export const startReactionPeriod = (inspectionId: string) => (dispatch: *) => {
    return dispatch(load({ path: { inspectionId } }, true)).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Reactieperiode is succesvol gestart.",
                }),
            );

            dispatch(loadInspection(inspectionId));

            return action.payload;
        }
        return null;
    });
};
