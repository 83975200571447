// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "inspectionPointsInstitutionTypes",
    ({ inspectionPointId }) =>
        `/inspectionpoints/${inspectionPointId}/children/institutiontypes`,

    {
        method: "GET",
        errorHandler: notifyApiError("Zorgvoorzieningstype ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
