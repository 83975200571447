// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadClusters } from "./get-clusters";

const { constants: _constants, reducer, actions } = createApiModule(
    "addClusterMembers",
    ({ clusterId }) => `/clusters/${clusterId}/members`,
    {
        method: "POST",
        errorHandler: notifyApiError("Gebruiker toevoegen aan cluster mislukt"),
    },
);

/*
 *   execute call to add a member to a cluster
 */
export const addClusterMembers = (clusterId: string, userId: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { clusterId }, data: { userId } }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Gebruiker toegevoegd aan cluster",
                    }),
                );
                return dispatch(loadClusters({}, true));
            }
            return true;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
