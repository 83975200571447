// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeInspectionInstitutionType",
    ({ inspectionId, typeId }) =>
        `/inspections/${inspectionId}/institution-types/${typeId}`,

    {
        method: "DELETE",
        errorHandler: notifyApiError("ZVT verwijderen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const removeZVT = (inspectionId: string, typeId: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId, typeId } }, true)).then(
        action => {
            if (!action || action.type !== constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "ZVT succesvol verwijderd",
                }),
            );
            return dispatch(
                loadInspection(
                    inspectionId,
                    "ZVT verwijderd,  maar het vernieuwen van de inspectieinformatie is mislukt",
                ),
            );
        },
    );
};
