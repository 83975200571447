// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "unlinkRequest",
    ({ inspectionId, requestId }) =>
        `/inspections/${inspectionId}/inspection-requests/${requestId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Aanvraag ontkoppelen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const unlinkRequest = (inspectionId: string, requestId: string) => (
    dispatch: *,
) =>
    dispatch(load({ path: { inspectionId, requestId } }, true)).then(
        response => {
            if (!response || !response.type === constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Aanvraag succesvol ontkoppeld",
                }),
            );
            return dispatch(
                loadInspection(
                    inspectionId,
                    "Aanvraag succesvol ontkoppeld, maar opdracht informatie vernieuwen mislukt",
                ),
            );
        },
    );
