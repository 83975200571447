// @flow

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { create as createApi } from "../api";
import reducer from "./modules";

/**
 * Create the store
 *
 * TODO: Add check if in dev mode or in prod mode
 */
export const create = (): * => {
    const storeFactory = applyMiddleware(store =>
        thunk.withExtraArgument(createApi(store.dispatch))(store),
    )(createStore);

    return storeFactory(
        reducer,
        (typeof window !== "undefined" &&
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()) ||
            undefined,
    );
};
