const INSPECTION_POINTS = {
    STATUS: {
        ACTIVE: "ACTIVE",
        ENDED: "ENDED",
        PENDING: "INVESTIGATING",
        INVESTIGATING: "INVESTIGATING",
    },
    STATUS_LABELS: {
        ACTIVE: "Actief",
        ENDED: "Stopgezet",
        PENDING: "In onderzoek",
        INVESTIGATING: "In onderzoek",
    },
    TYPE: {
        INRICHTENDE_MACHT: "IM",
        VOORZIENING: "VZ",
        PERSOON: "P",
        UITBATINGSPLAATS: "UP",
    },
    TYPE_LABELS: {
        INRICHTENDE_MACHT: "Inrichtende macht",
        VOORZIENING: "Voorziening",
        PERSOON: "Persoon",
        UITBATINGSPLAATS: "Uitbatingsplaats",
    },
    FILTERS: [
        {
            label: "Naam",
            id: "name",
            type: "text",
            value: "",
            helperText: "Minimum 3 karakters",
            minLength: 3,
        },
        {
            label: "Postcode",
            id: "zipCode",
            type: "text",
            value: "",
            helperText: "Minimum 4 karakters",
            minLength: 4,
        },
        {
            label: "Plaats",
            id: "city",
            type: "text",
            value: "",
            helperText: "Minimum 3 karakters",
            minLength: 3,
        },
        {
            label: "Dossiernummer",
            id: "fileNumber",
            type: "text",
            value: "",
            helperText: "Geef het volledige nummer in",
        },
        {
            label: "Erkenningsnummer",
            id: "approvalNumber",
            type: "text",
            value: "",
            helperText: "Geef het volledige nummer in",
        },
        {
            label: "Ondernemingsnummer",
            id: "enterpriseNumber",
            type: "text",
            value: "",
            helperText: "Geef het volledige nummer in",
            minLength: 10,
            maxLength: 10,
        },
        {
            label: "HCO nummer",
            id: "healthCareOrganizationNumber",
            type: "text",
            value: "",
            helperText: "Geef het volledige nummer in",
        },
        {
            label: "Rijksregisternummer",
            id: "rrn",
            type: "text",
            value: "",
            helperText: "Geef het volledige nummer in",
            minLength: 11,
            maxLength: 11,
        },
        {
            label: "Type",
            id: "type",
            type: "dropdown",
            value: "",
            options: [
                // Keep keys object id & description,
                // they should be the same as Markering API response, both use the same renderer
                { id: "INRICHTENDE_MACHT", description: "Inrichtende macht" },
                { id: "VOORZIENING", description: "Voorziening" },
                { id: "UITBATINGSPLAATS", description: "Uitbatingsplaats" },
                { id: "PERSOON", description: "Persoon" },
            ],
            helperText: " ",
        },
        {
            label: "Markering",
            id: "flagId",
            type: "dropdown",
            value: "",
            options: [],
            helperText:
                "Filteren op Markering dient te gebeuren in combinatie met andere filter(s).",
        },
    ],
    FLAG_TYPES: {
        ATTENTION: "AIP",
        FINANCIAL: "FIN",
    },
};

export default INSPECTION_POINTS;
