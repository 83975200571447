// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getCustomQueriesAction } from "./get-custom-queries";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "addCustomQuery",
    () => "/custom-queries",
    {
        method: "POST",
        errorHandler: notifyApiError("Gecombineerde filter toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const addCustomQueryAction = (data: *) => (dispatch: *) => {
    dispatch(clear());

    return dispatch(load({ data }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Gecombineerde filter toegevoegd",
            }),
        );
        return dispatch(getCustomQueriesAction(undefined, true));
    });
};
