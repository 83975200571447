// @flow

import createApiModule from "../create-api-module";
import { getContactPersons } from "./get-contact-persons";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { storage } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteContactPerson",
    ({ inspectionId, contactId }) =>
        `/inspections/${inspectionId}/contacts/${contactId}`,

    {
        method: "DELETE",
        errorHandler: notifyApiError(
            "Ontvanger verslagen kon niet verwijderd worden",
        ),
    },
);

export const deleteContactPerson = (
    inspectionId: string,
    contactId: string,
    offlineSyncMessage?: string,
) => (dispatch: *) => {
    if (contactId.includes("offline")) {
        // contact was offline added
        const key = `offline-contacts-${inspectionId}`;
        const contactsFromStorage = storage.get(key);
        if (contactsFromStorage) {
            const contacts = JSON.parse(contactsFromStorage);
            const newContacts = contacts.filter(item => item.id !== contactId);
            if (newContacts.length > 0) {
                storage.set(key, JSON.stringify(newContacts));
            } else {
                storage.remove(key);
            }
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message:
                        offlineSyncMessage ||
                        "Ontvanger verslagen werd succesvol verwijderd",
                }),
            );

            return dispatch(getContactPersons(inspectionId));
        }
    } else {
        if (navigator.onLine) {
            return dispatch(
                load({ path: { inspectionId, contactId } }, true),
            ).then(action => {
                if (action && action.type === constants.SUCCESS) {
                    dispatch(
                        notify({
                            severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                            message:
                                "Ontvanger verslagen is succesvol verwijderd",
                        }),
                    );

                    return dispatch(getContactPersons(inspectionId));
                }
            });
        } else {
            //race-condition in store updates... no workaround.
            setTimeout(() => {
                return dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.ERROR,
                        message:
                            "U bent offline. Ontvanger verslagen kon niet verwijderd worden.",
                    }),
                );
            }, 1000);
        }
    }
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
