// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as loadReportTitles } from "./report-titles";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeReportTitle",
    ({ reportTitle }) => `/report-titles/${reportTitle}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Verslagtitel verwijderen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const removeReportTitleAction = (reportTitle: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { reportTitle } }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Verslagtitel is verwijderd.",
            }),
        );
        dispatch(loadReportTitles(undefined, true));
    });
};
