// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getInterlocutors } from "./get-interlocutors";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateInterlocutor",
    ({ inspectionId, visitId, interlocutorId }) =>
        `/inspections/${inspectionId}/visits/${visitId}/interlocutors/${interlocutorId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Gesprekspartner aanpassen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const updateInterlocutorAction = (
    inspectionId: string,
    visitId: string,
    data: *,
) => (dispatch: *) => {
    const { id: interlocutorId, value } = data;
    return dispatch(
        load(
            {
                path: { inspectionId, visitId, interlocutorId },
                data: { value },
            },
            true,
        ),
    ).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Gesprekspartner aangepast",
            }),
        );

        return dispatch(
            getInterlocutors({ path: { inspectionId, visitId } }, true),
        );
    });
};
