// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { createCallHandler } from "./requests";
import { constructQueryString } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "watchedAssignments",
    ({ query }) => `/inspections${constructQueryString(query, true)}`,
    {
        method: "GET",
        errorHandler: notifyApiError(
            "Gevolgde opdrachten ophalen mislukt",
            false,
        ),
        clearOnFetch: true,
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const update = actions.load;
export const refetch = actions.refetch;
export const cancel = actions.cancel;

export const constants = _constants;

export const loadWatchedAssignments = createCallHandler(
    "watchedAssignments",
    load,
    cancel,
);
