// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadReactions } from "./get-reactions";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteReactionAttachments",
    ({ inspectionId, reactionId, attachmentId }) =>
        `/inspections/${inspectionId}/reactions/${reactionId}/attachments/${attachmentId}`,

    {
        method: "DELETE",
        errorHandler: notifyApiError("Bijlage kon niet worden verwijderd"),
    },
);

export const remove = (inspectionId: string, reactionId: *, file: *) => (
    dispatch: *,
) => {
    return dispatch(
        load(
            {
                path: {
                    inspectionId,
                    reactionId: reactionId,
                    attachmentId: file.documentId,
                },
            },
            true,
        ),
    )
        .then(response => {
            if (response && response.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: `${file.name} is met succes verwijderd`,
                    }),
                );
                dispatch(loadReactions(inspectionId));
            }
            return false;
        })
        .then(() => dispatch(clear()));
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
