//@flow
import React from "react";
import { ASSIGNMENTS } from "../Assignments";
import { REQUESTS } from "../Requests";

export const LOCATIONS_ASSIGNMENTS = ["mijn opdrachten", "alle opdrachten"];
export const LOCATIONS_REQUESTS = ["mijn aanvragen", "alle aanvragen"];

export const ROLES = [
    { label: "beheerder", value: "ROLE_ADMIN" },
    { label: "minibeheerder", value: "ROLE_MINIADMIN" },
    { label: "secretariaat", value: "ROLE_SECRETARIAT" },
    { label: "inspecteur", value: "ROLE_INSPECTOR" },
];

export const SIMPLE_FILTERS_ASSIGNMENTS = [
    {
        label: "Inspectiepunt",
        filterId: "inspection-point",
        headerType: "inspectionPoint",
    },
    {
        filterId: "status",
        headerType: "text",
        options: Object.keys(ASSIGNMENTS.LABELS).map<*>((key, index) => ({
            label: ASSIGNMENTS.LABELS[key],
            value: key,
            index,
        })),
        label: "Status",
    },
    {
        filterId: "reason-id",
        headerType: "reason",
        label: "Reden",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        filterId: "groups",
        headerType: "groups",
        options: {
            mountOnEnter: false,
        },
    },
    {
        filterId: "target-date",
        headerType: "date",
        label: "Streefdatum",
    },
    {
        filterId: "last-visit-date",
        headerType: "date",
        label: "Laatste bezoek",
    },
    {
        filterId: "last-report-sent",
        headerType: "date",
        label: "Datum verstuurd",
    },
    {
        filterId: "deadline-extension",
        label: "Uitstel",
        headerType: "postponed",
    },
    {
        label: "Hoofdinspecteur",
        filterId: "lead-inspector-id",
        headerType: "person",
        options: {
            mountOnEnter: false,
        },
    },
    {
        label: "Toegewezen aan",
        filterId: "assignee-id",
        headerType: "person",
        options: {
            mountOnEnter: false,
        },
    },
];

export const SIMPLE_FILTERS_REQUESTS = [
    {
        label: "Inspectiepunt",
        filterId: "inspection-point",
        headerType: "inspectionPoint",
    },
    {
        filterId: "status",
        headerType: "text",
        options: Object.keys(REQUESTS.LABELS).map<*>((key, index) => ({
            label: REQUESTS.LABELS[key],
            value: key,
            index,
        })),
        label: "Status",
    },
    {
        filterId: "reason-id",
        headerType: "reason",
        label: "Reden",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        filterId: "groups",
        headerType: "groups",
        options: {
            mountOnEnter: false,
        },
    },
    // {
    //     filterId: "requested-date",
    //     headerType: "date",
    //     label: "Uitvoeringsdatum",
    // },
    {
        filterId: "requested-date-range",
        headerType: "date-range",
        label: "Uitvoeringsdatum",
    },
    {
        label: "Toegewezen aan",
        filterId: "assignee-id",
        headerType: "person",
        options: {
            mountOnEnter: false,
        },
    },
];
