export const INFRINGEMENTS = {
    LABELS: {
        ACTIVE: "Actief",
        RESOLVED: "Afgesloten",
        SUPERSEDED: "Vervangen",
        DUPLICATE: "Afgesloten als duplicaat",
    },
    STATUSES: {
        ACTIVE: "ACTIVE",
        RESOLVED: "RESOLVED",
        SUPERSEDED: "SUPERSEDED",
        DUPLICATE: "DUPLICATE",
    },
};
