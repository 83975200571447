// @flow

export const format = (address: *) =>
    !!address && Object.keys(address).some(key => !!address[key])
        ? address.country
            ? `${address.street} ${address.streetNumber}, ${address.zipCode} ${address.city}, ${address.country}`
            : `${address.street} ${address.streetNumber}, ${address.zipCode} ${address.city}`
        : "(adres ontbreekt)";

export const checkMissingAddress = (mainAddress: *, visits: *) => {
    return (
        mainAddress.city === null ||
        !!visits.find(visit => visit.address.city === null)
    );
};
