// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { loadInspection } from "./inspection";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "changeLabel",
    ({ inspectionId }) => `/inspections/${inspectionId}/label`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Netwerklabel toekennen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const changeLabel = (inspectionId: string, label: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId }, data: { label } })).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: label
                            ? "Netwerklabel is toegekend."
                            : "Netwerklabel is verwijderd.",
                    }),
                );
                dispatch(loadInspection(inspectionId));
                return true;
            }
            return null;
        },
    );
};
