// @flow

const definition = [
    {
        id: "action",
        isFilterable: false,
        isSortable: false,
        label: "Actie",
        type: "text",
    },
    {
        id: "new-value",
        isFilterable: false,
        isSortable: false,
        label: "Nieuwe waarde",
        type: "text",
    },
    {
        id: "updated-at",
        isFilterable: false,
        isSortable: false,
        label: "Bijgewerkt op",
        type: "text",
    },
    {
        id: "updated-by",
        isFilterable: false,
        isSortable: false,
        label: "Wie",
        type: "text",
    },
];

export default definition;
