// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "saveReviewFeedback",
    ({ inspectionId }) => `/inspections/${inspectionId}/reviews`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Feedback opslaan mislukt"),
    },
);

export const saveFeedbackForInspection = (
    inspectionId: string,
    personId?: string,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionId }, data: { uuid: personId } }, true),
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
