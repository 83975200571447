// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadDeadlineReasons } from "./deadline-extension-reasons";

const { constants: _constants, reducer, actions } = createApiModule(
    "addDeadlineExtensionReason",
    () => `/deadline-extension-reasons`,

    {
        method: "POST",
        errorHandler: notifyApiError("Reden toevoegen mislukt"),
    },
);

export const addDeadlineReason = (value: string) => (dispatch: *) => {
    return dispatch(load({ data: { value } }, true)).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Reden is succesvol toegevoegd",
                }),
            );
            dispatch(loadDeadlineReasons({}, true));
            return true;
        }
        return null;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
