export const CONTACT_PERSON_TYPES = [
    {
        label: "Inrichtende Macht",
        value: "AUTHORITY",
    },
    {
        label: "Voorziening",
        value: "INSTITUTION",
    },
    {
        label: "Uitbatingsplaats",
        value: "SITE",
    },
    {
        label: "Persoon",
        value: "PERSON",
    },
    {
        label: "Overige",
        value: "OTHER",
    },
    {
        label: "Inrichtende Macht",
        value: "IM",
    },
    {
        label: "Klacht indiener",
        value: "KI",
    },
    {
        label: "Zorginspectie",
        value: "ZI",
    },
    {
        label: "Functionele entiteit",
        value: "FE",
    },
];
export const CONTACT_PERSON_TYPES_DROPDOWN_LIST = [
    {
        label: "Inrichtende Macht",
        value: "AUTHORITY",
    },
    {
        label: "Voorziening",
        value: "INSTITUTION",
    },
    {
        label: "Uitbatingsplaats",
        value: "SITE",
    },
    {
        label: "Persoon",
        value: "PERSON",
    },
    {
        label: "Overige",
        value: "OTHER",
    },
];

export const CONTACT_PERSON_SHORTHAND_TYPES = {
    AUTHORITY: "IM",
    INSTITUTION: "VZ",
    SITE: "UP",
    PERSON: "P",
    OTHER: "Overige",
    KI: "KI",
    FE: "FE",
    IM: "IM",
    ZI: "ZI",
};
