// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getReportsForAssignment } from "./get-reports-for-assignment";

const { constants: _constants, reducer, actions } = createApiModule(
    "publishReport",
    ({ inspectionId, reportId }) =>
        `/inspections/${inspectionId}/reports/${reportId}/publication`,
    {
        method: "POST",
        errorHandler: notifyApiError("Definitief verslag publiceren mislukt"),
    },
);

export const publishReport = (
    inspectionId: string,
    reportId: string,
    publicationDate: *,
) => (dispatch: *) => {
    return dispatch(
        load(
            {
                path: { inspectionId, reportId },
                data: { publicationDate },
            },
            true,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Definitief verslag is succesvol gepubliceerd",
                }),
            );
            dispatch(getReportsForAssignment(inspectionId));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
