// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { loadReactions } from "./get-reactions";
import { loadInspection } from "./inspection";

import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateReaction",
    ({ inspectionId, reactionId }) =>
        `/inspections/${inspectionId}/reactions/${reactionId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Reactie updaten mislukt"),
    },
);

export const updateReaction = (
    inspectionId: string,
    reactionId: string,
    data: *,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionId, reactionId }, data }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(loadReactions(inspectionId));

            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Reactie is aangepast",
                }),
            );
            return dispatch(loadInspection(inspectionId));
        }
        return null;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
