// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { startDownload, getMIMEFormat, getFileExtension } from "@utils";

let lastDocumentId: string = "";
const sameAsLast = (id: string) => !!lastDocumentId && lastDocumentId === id;

const { constants: _constants, reducer, actions } = createApiModule(
    "downloadDocument",
    ({ fileId }) => `/documents/${fileId}`,
    {
        method: "GET",
        errorHandler: notifyApiError("Downloaden van het bestand is mislukt"),
    },
);

const fetchDocument = (file: *, dispatch: *, isSameAsLast: boolean) => {
    !isSameAsLast &&
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.INFO,
                message: `${file.name} wordt opgehaald...`,
                autoHide: false,
            }),
        );
    return dispatch(
        load(
            {
                path: { fileId: file.documentId },
                responseType: "arraybuffer",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/pdf",
                },
            },
            !isSameAsLast,
        ),
    );
};

export const downloadDocument = (file: *) => (dispatch: *, getState: *) => {
    const isSameAsLast = sameAsLast(file.documentId);
    lastDocumentId = file.documentId;
    return fetchDocument(file, dispatch, isSameAsLast).then((response: any) => {
        if (!response || (response.type && response.type !== constants.SUCCESS))
            return null;
        const data =
            response === "SUCCESS"
                ? new Blob([getState().downloadDocument.data], {
                      type: getMIMEFormat(getFileExtension(file.name)),
                  })
                : new Blob([response.payload], {
                      type: getMIMEFormat(getFileExtension(file.name)),
                  });
        const uri = window.URL.createObjectURL(data);
        startDownload(dispatch, uri, file.name);
        window.URL.revokeObjectURL(uri);
        return true;
    });
};

export const getPreviewDocumentUrl = (file: *) => (
    dispatch: *,
    getState: *,
) => {
    const isSameAsLast = sameAsLast(file.documentId);
    lastDocumentId = file.documentId;
    return fetchDocument(file, dispatch, isSameAsLast).then((response: any) => {
        if (
            !response ||
            (response?.type && response.type !== constants.SUCCESS)
        )
            return null;

        !isSameAsLast &&
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: `${file.name} opgehaald...`,
                }),
            );
        const data =
            response === "SUCCESS"
                ? new Blob([getState().downloadDocument.data], {
                      type: "application/pdf",
                  })
                : new Blob([response.payload], { type: "application/pdf" });
        const uri = window.URL.createObjectURL(data);
        return uri;
    });
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
// reducer
export default reducer;

export const constants = _constants;
