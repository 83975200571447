// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { set as setModules } from "./modules";
import { set as setReportTypesValues } from "./report-types-values";
import { ModulesUtil } from "@utils";
import cloneDeep from "lodash.clonedeep";

const { constants: _constants, reducer, actions } = createApiModule(
    "exemptions",
    ({ inspectionPointId, includeArchived }) =>
        `/inspectionpoints/${inspectionPointId}/exemptions?include-inactive=${includeArchived}`,
    {
        errorHandler: notifyApiError("Afwijkingen ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const loadAndApplyExemptions = (
    inspectionPointId: string,
    store: string,
) => (dispatch: *, getState: *) => {
    return dispatch(
        load({ path: { inspectionPointId, includeArchived: false } }, true),
    ).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;
        const counterForModule = ModulesUtil.countOccurrencesForModule(
            action.payload,
        );
        if (store === "modules") {
            let assignedModules = cloneDeep(getState().modules);
            if (assignedModules && assignedModules.length) {
                assignedModules = ModulesUtil.applyExtraProperty({
                    modules: assignedModules,
                    assigned: true,
                    counterForModule,
                    extraPropertyKey: "exemptions",
                });
                assignedModules = ModulesUtil.countMarkersOnChildren(
                    assignedModules,
                    true,
                );
                return dispatch(setModules(assignedModules));
            }
            return { payload: assignedModules };
        }
        if (store === "reportTypesValues") {
            let modules = ModulesUtil.applyExtraProperty({
                modules: getState().reportTypesValues,
                assigned: false,
                counterForModule,
                extraPropertyKey: "exemptions",
            });
            modules = ModulesUtil.countMarkersOnChildren(modules, false);
            return dispatch(setReportTypesValues(modules));
        }
    });
};
