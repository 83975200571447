// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadTemplates } from "./notification-templates";

const { constants: _constants, reducer, actions } = createApiModule(
    "editTemplate",
    ({ templateId }) => `/notification-templates/${templateId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Sjabloon bewerken mislukt"),
    },
);

export const editTemplate = (data: *) => (dispatch: *) => {
    return dispatch(load({ path: { templateId: data.id }, data }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Sjabloon is succesvol bewerkt",
                    }),
                );

                dispatch(loadTemplates({}, true));
                return true;
            }
            return null;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
