// @flow

const definition = [
    {
        label: "Datum",
        id: "date",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Beschrijving",
        id: "description",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Acties",
        id: "file-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
