// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { loadInspection } from "./inspection";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateTargetDate",
    ({ inspectionId }) => `/inspections/${inspectionId}/target-date`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Streefdatum aanpassen mislukt"),
    },
);

/*
 *   execute call to update the status of an assignment
 *   and if succesful also update selected assignment record
 */
export const updateTargetDate = (inspectionId: string, targetDate: *) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { inspectionId }, data: { targetDate } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Streefdatum aangepast",
                }),
            );
            return dispatch(loadInspection(inspectionId));
        }
        return null;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
