// @flow

import createApiModule from "../create-api-module";
import { set as setValuesStore } from "./report-types-values";
import { notifyApiError } from "./notifications";
import { loadAndApplyShortcomings } from "./shortcomings";
import { loadAndApplyExemptions } from "./exemptions";

const { constants: _constants, reducer, actions } = createApiModule(
    "reportTypes",
    () => `/forms`,
    {
        errorHandler: notifyApiError("Rapport types ophalen mislukt", false),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const update = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const loadAndStore = (inspectionPointId: string) => (dispatch: *) => {
    return dispatch(load()).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;
        dispatch(setValuesStore(action.payload));
        return dispatch(
            loadAndApplyShortcomings(inspectionPointId, "reportTypesValues"),
        ).then(() =>
            dispatch(
                loadAndApplyExemptions(inspectionPointId, "reportTypesValues"),
            ),
        );
    });
};
