// @flow
const definition = [
    {
        label: "BESTEMMELING",
        id: "recipient",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },

    {
        label: "ONDERWERP",
        id: "subject",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: "DATUM",
        id: "sentAt",
        isSortable: false,
        isFilterable: true,
        type: "singleDate",
    },
    {
        label: "TYPE",
        id: "type",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
