// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadInternalGroups } from "./get-internal-groups";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeInternalGroupEmail",
    ({ groupId }) => `/groups/${groupId}/email-addresses`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Email verwijderen van groep mislukt"),
    },
);

/*
 *   execute call to remove email to group
 */
export const removeInternalGroupEmail = (
    groupId: string,
    emailAddress: string,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { groupId }, data: { emailAddress: emailAddress } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Email verwijderd van groep",
                }),
            );
            return dispatch(loadInternalGroups(undefined, true));
        }
        return true;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
