// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "addVisit",
    ({ inspectionId }) => `/inspections/${inspectionId}/visits`,

    {
        method: "POST",
        errorHandler: notifyApiError("Bezoek toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const batchAddVisits = (inspectionId: string, data: Array<*>) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId }, data }, true)).then(
        action => {
            if (!action || action.type !== constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Inspectiepunten toegevoegd aan opdracht",
                }),
            );
            dispatch(clear());
            dispatch(
                loadInspection(
                    inspectionId,
                    "Inspectiepunten toevoegen is gelukt, maar het vernieuwen van de inspectieinformatie is mislukt.",
                ),
            );
            return true;
        },
    );
};
