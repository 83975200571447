// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadReferences } from "./institution-type-references";

const { constants: _constants, reducer, actions } = createApiModule(
    "addInstitutionTypeReference",
    () => `/institutiontype-references`,

    {
        method: "POST",
        errorHandler: notifyApiError(
            "Zorgvoorzieningstype referentie toevoegen mislukt",
        ),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const addReference = (data: *) => (dispatch: *) => {
    return dispatch(load({ data })).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Zorgvoorzieningstype referentie toegevoegd",
            }),
        );
        dispatch(loadReferences(undefined, true));
        return true;
    });
};
