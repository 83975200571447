// @flow

// libs
import cloneDeep from "lodash.clonedeep";

// redux
import createApiModule from "../create-api-module";
import {
    load as add,
    clear as clearAdd,
    constants as addConstants,
} from "./add-module";
import { load as remove, constants as deleteConstants } from "./delete-module";
import {
    load as removeAll,
    constants as deleteAllConstants,
} from "./delete-all-modules";

import { set as setModules } from "./modules";
import { update as notify, notifyApiError } from "./notifications";
import { loadInspection } from "./inspection";
import { loadAndApplyShortcomings } from "./shortcomings";
import { loadAndApplyExemptions } from "./exemptions";

// other imports
import type { AddModuleOptions, DeleteModuleOptions } from "@types";
import { NOTIFICATIONS } from "@constants";
import { storage, ModulesUtil } from "@utils";

/*
 *   Reducer module to get modules for visit
 */
const { constants: _constants, reducer, actions } = createApiModule(
    "visitModules",
    ({ inspectionId, visitId }) =>
        `/inspections/${inspectionId}/visits/${visitId}/modules`,
    {
        errorHandler: notifyApiError(
            "Toegewezen modules ophalen mislukt",
            false,
        ),
        clearOnFetch: true,
        clearOnError: true,
    },
);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
export const constants = _constants;

/**
 *  Load modules for a visit and update the modules store
 */
export const loadModulesForVisit = (
    inspectionId: string,
    visitId: string,
    inspectionPointId: string,
) => (dispatch: *) => {
    return dispatch(load({ path: { inspectionId, visitId } }, true)).then(
        action => {
            if (!action || action.type !== constants.SUCCESS) return;
            let modules = [...action.payload];
            modules.forEach(category => {
                category.moduleInstances.forEach(module => {
                    if (module.type === "PARENT") {
                        module.submoduleInstances.forEach(subModule => {
                            subModule.isOfflineAvailable = storage.isOfflineAvailable(
                                subModule,
                                visitId,
                            );
                        });
                    } else {
                        module.isOfflineAvailable = storage.isOfflineAvailable(
                            module,
                            visitId,
                        );
                    }
                });
            });
            modules = ModulesUtil.markParentClonesOnAllCategories(modules);

            dispatch(setModules(modules));
            return dispatch(
                loadAndApplyShortcomings(inspectionPointId, "modules"),
            ).then(() =>
                dispatch(loadAndApplyExemptions(inspectionPointId, "modules")),
            );
        },
    );
};

/**
 *   Delete all modules
 *   Make call, on success update store & notify
 */
export const deleteAllModules = (inspectionId: string, visitId: string) => (
    dispatch: *,
    getState: *,
) => {
    return dispatch(
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message:
                "Bent u zeker dat u alle modules - inclusief de ingevulde modules - wilt verwijderen?",
            secondaryActionText: "Nee",
            primaryActionText: "Ja, verwijder alles",
            primaryAction: () =>
                doDeleteAll(dispatch, getState, inspectionId, visitId),
        }),
    );
};

/**
 *   Delete module
 *   Make call, on success update store & notify
 */
export const deleteModule = (options: DeleteModuleOptions) => (
    dispatch: *,
    getState: *,
) => {
    return dispatch(
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: options.parent
                ? "Bent u zeker dat u deze modulegroep - inclusief de ingevulde submodules - wilt verwijderen?"
                : "Bent u zeker dat u deze module wilt verwijderen?",
            secondaryActionText: "Nee",
            primaryActionText: "Ja, verwijder",
            primaryAction: () =>
                doDelete(dispatch, getState, options).then(
                    success =>
                        success && options.callback && options.callback(),
                ),
        }),
    );
};

const doDelete = (dispatch: *, getState: *, options: DeleteModuleOptions) => {
    const {
        inspectionId,
        visitId,
        instance: { instanceId },
    } = options;
    return dispatch(
        remove({ path: { inspectionId, visitId, instanceId } }),
    ).then(action => {
        if (!action || action.type !== deleteConstants.SUCCESS) return false;
        const { type } = options;
        let updatedModules = ModulesUtil.filterInstanceFromTree(
            cloneDeep(getState().modules),
            instanceId,
            type,
        );
        updatedModules = ModulesUtil.countMarkersOnChildren(
            updatedModules,
            true,
        );
        updatedModules = ModulesUtil.markParentClonesOnAllCategories(
            updatedModules,
        );
        dispatch(setModules(updatedModules));
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: options.parent
                    ? "Modulegroep verwijderd"
                    : "Module verwijderd",
            }),
        );
        const { selectedRecord } = getState().assignmentsValues;
        const numberOfVisitModulesHasChanged = ModulesUtil.hasVisitHasModulesChanged(
            selectedRecord,
            visitId,
            updatedModules,
        );
        if (numberOfVisitModulesHasChanged)
            dispatch(loadInspection(inspectionId));

        return true;
    });
};

const doDeleteAll = (
    dispatch: *,
    getState: *,
    inspectionId: string,
    visitId: string,
) => {
    return dispatch(removeAll({ path: { inspectionId, visitId } }, true)).then(
        action => {
            if (!action || action.type !== deleteAllConstants.SUCCESS) return;
            let updatedModules = [];
            dispatch(setModules(updatedModules));
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "All modules verwijderd.",
                }),
            );
            const { selectedRecord } = getState().assignmentsValues;
            if (
                ModulesUtil.hasVisitHasModulesChanged(
                    selectedRecord,
                    visitId,
                    updatedModules,
                )
            )
                dispatch(loadInspection(inspectionId));
        },
    );
};

/**
 *   Add module
 *   Make call, on success update store & notify
 */
export const addModule = (options: AddModuleOptions) => (
    dispatch: *,
    getState: *,
) => {
    const {
        inspectionId,
        visitId,
        id,
        target,
        formTemplateId,
        versionId,
    } = options;
    dispatch(clearAdd());
    // adding all modules of a formtype needs no module id
    // but adding a module needs its formtemplate id to know where to add it
    const data: any =
        target === "formTemplateId"
            ? { [target]: id, versionId }
            : { [target]: id, formTemplateId, versionId };
    if (options.parent && options.parent.instanceId)
        data.moduleInstanceGroupId = options.parent.instanceId;

    return dispatch(
        add(
            {
                path: { inspectionId, visitId },
                data,
            },
            true,
        ),
    ).then(action => {
        if (!action || action.type !== addConstants.SUCCESS) return; // do nothing on fail
        const { type, parent } = options;
        const updatedModules = ModulesUtil.updateModules({
            modules: cloneDeep(getState().modules),
            type,
            parent,
            action,
            target,
            infringements: getState().shortcomings.data,
            exemptions: getState().exemptions.data,
        });
        dispatch(setModules(updatedModules));
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Module toegevoegd",
            }),
        );
        const { selectedRecord } = getState().assignmentsValues;
        if (
            ModulesUtil.hasVisitHasModulesChanged(
                selectedRecord,
                visitId,
                updatedModules,
            )
        )
            dispatch(loadInspection(inspectionId));
        return true;
    });
};
