// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateVersionName",
    ({ formId, versionId }) => `/forms/${formId}/versions/${versionId}/name`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Versienaam aanpassen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const updateVersionName = (
    formId: string,
    versionId: string,
    data: *,
) => (dispatch: *) => {
    return dispatch(load({ path: { formId, versionId }, data }, true)).then(
        response => {
            if (!response || response.type !== constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Versienaam aangepast",
                }),
            );
            return true;
        },
    );
};
