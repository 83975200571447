// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getReportsForAssignment } from "./get-reports-for-assignment";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "openUpModulesAgain",
    ({ inspectionId, reportState }) =>
        reportState === "DRAFT"
            ? `/inspections/${inspectionId}/draft-reports`
            : `/inspections/${inspectionId}/final-reports`,

    {
        method: "DELETE",
        errorHandler: notifyApiError(
            "De modules weer editeerbaar maken is mislukt.",
        ),
    },
);

export const openUpModulesAgain = (
    inspectionId: string,
    reportState: string,
) => (dispatch: *) => {
    return dispatch(
        load(
            {
                path: { inspectionId, reportState },
            },
            true,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: `De modules kunnen weer bewerkt worden.`,
                }),
            );
            dispatch(loadInspection(inspectionId));
            dispatch(getReportsForAssignment(inspectionId));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
