// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "notificationTemplate",
    ({ inspectionId, notificationTemplateId, reportId }) => {
        if (reportId) {
            return `/inspections/${inspectionId}/notification-template?notification-template-id=${notificationTemplateId}&reportId=${reportId}`;
        }
        return `/inspections/${inspectionId}/notification-template?notification-template-id=${notificationTemplateId}`;
    },

    {
        method: "Get",
        clearOnError: true,
        errorHandler: notifyApiError("Template ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const loadNotificationTemplate = (
    inspectionId: string,
    notificationTemplateId: string,
    reportId: string,
) => (dispatch: *) =>
    dispatch(
        load(
            { path: { inspectionId, notificationTemplateId, reportId } },
            true,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            return action.payload;
        }
        return null;
    });
