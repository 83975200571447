// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getReportsForAssignment } from "./get-reports-for-assignment";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "declineDraftReport",
    ({ inspectionId }) => `/inspections/${inspectionId}/draft-ready-to-publish`,

    {
        method: "DELETE",
        errorHandler: notifyApiError(
            "De afkeuring van het onwerpverslag is mislukt",
        ),
    },
);

export const declineDraftReport = (inspectionId: string) => (dispatch: *) => {
    return dispatch(
        load(
            {
                path: { inspectionId },
            },
            true,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Het ontwerpverslag is afgekeurd",
                }),
            );
            dispatch(loadInspection(inspectionId));
            dispatch(getReportsForAssignment(inspectionId));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
