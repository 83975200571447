// @flow
import createValueModule from "../create-value-module";

const { reducer, actions } = createValueModule("widgets", {
    header: {
        init: false,
        content: "",
    },
    footer: {
        init: false,
        content: "",
    },
});

export default reducer;

export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
