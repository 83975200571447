// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { constructQueryString } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "requests",
    ({ query }) => {
        const base = "/inspection-requests";
        return query ? base + constructQueryString(query, true) : base;
    },
    {
        method: "GET",
        errorHandler: notifyApiError("Aanvragen ophalen mislukt"),
        clearOnFetch: true,
    },
);

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const cancel = actions.cancel;
export const constants = _constants;

export default reducer;

type SourceType = "IPD" | "teamDashboard" | "personalDashboard" | "list" | null;
type CallOptions = {
    query: *,
    source: SourceType,
    background?: boolean,
    force?: boolean,
};

export const createCallHandler = (storeKey: string, load: *, cancel: *) => {
    let lastSource: SourceType = null;
    return ({
        query,
        source,
        background = false,
        force = false,
    }: CallOptions) => (dispatch: *) => {
        if (!source)
            throw new ReferenceError(
                "Source missing, where are you calling from?",
            );
        if (!lastSource) lastSource = source;

        // background: cancel last even if it was a list
        if (background) dispatch(cancel());
        // foreground, list, but last was sth else, cancel too
        if (!background && source === "list" && source !== lastSource)
            dispatch(cancel());
        // change query on list page with call in flight, cancel prev
        if (source === "list" && source === lastSource) dispatch(cancel());

        lastSource = source;
        // foreground: list itself needs priority over layout,
        // so if not list, ignore
        if (!background && source !== "list" && lastSource === "list") return;
        return dispatch(load({ path: { query } }, force));
    };
};

export const loadRequests = createCallHandler("requests", load, cancel);
