// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { startDownload } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "previewAnonymizedReport",
    ({ inspectionId }) => `/inspections/${inspectionId}/redacted-report`,
    {
        method: "GET",
        errorHandler: notifyApiError("Downloaden van het bestand is mislukt"),
    },
);

export const previewAnonymizedReport = (inspectionId: *) => (dispatch: *) => {
    dispatch(
        notify({
            severity: NOTIFICATIONS.SEVERITY.INFO,
            message: "Het verslag wordt gegenereerd...",
            autoHide: false,
        }),
    );
    return dispatch(
        load(
            {
                path: { inspectionId },
                responseType: "arraybuffer",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/pdf",
                },
            },
            true,
        ),
    ).then(response => {
        if (!response || response.type !== constants.SUCCESS) return null;
        const uri = window.URL.createObjectURL(new Blob([response.payload]));
        return startDownload(dispatch, uri, `${inspectionId}.pdf`);
    });
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
// reducer
export default reducer;

export const constants = _constants;
