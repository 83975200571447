// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "sendNotification",
    ({ inspectionId }) => `/inspections/${inspectionId}/notifications`,

    {
        method: "POST",
        clearOnError: true,
        errorHandler: notifyApiError("E-mail is niet verzonden"),
    },
);

/*
 *   Send Notification
 */
export const sendNotification = (inspectionId: string, data: *) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId }, data }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "E-mail is succesvol verzonden",
                    }),
                );
                dispatch(clear());
                dispatch(loadInspection(inspectionId));

                return action.payload;
            }
            return null;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
