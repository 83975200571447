// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "addModule",
    ({ inspectionId, visitId }) =>
        `/inspections/${inspectionId}/visits/${visitId}/modules`,

    {
        method: "POST",
        errorHandler: notifyApiError("Module toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
