// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadInternalGroups } from "./get-internal-groups";

const { constants: _constants, reducer, actions } = createApiModule(
    "addInternalGroupMembers",
    ({ groupId }) => `/groups/${groupId}/members`,
    {
        method: "POST",
        errorHandler: notifyApiError("Gebruiker toevoegen aan groep mislukt"),
    },
);

/*
 *   execute call to add a member to group
 */
export const addInternalGroupMembers = (groupId: string, userId: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { groupId }, data: { userId } }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Gebruiker toegevoegd aan groep",
                    }),
                );
                return dispatch(loadInternalGroups({}, true));
            }
            return true;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
