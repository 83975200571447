// @flow
import { ASSIGNMENTS } from "@constants";
const definition = [
    {
        label: "Zorgvoorzieningstype",
        id: "institution-type-id",
        isSortable: false,
        isFilterable: true,
        type: "ZVT",
    },
    {
        label: "Reden",
        id: "reason-id",
        isSortable: false,
        isFilterable: true,
        type: "reason",
    },
    {
        label: "Rapporttype",
        id: "report-type",
        isSortable: false,
        isFilterable: true,
        type: "reportType",
    },
    {
        label: "Openbaarheidsniveau",
        id: "disclosure-level",
        isSortable: false,
        isFilterable: true,
        type: "text",
        options: Object.keys(ASSIGNMENTS.DISCLOSURE_LEVELS).map<*>(
            (key, index) => ({
                label: ASSIGNMENTS.DISCLOSURE_LEVELS[key],
                value: key,
                index,
            }),
        ),
    },
];

export default definition;
