// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadReferences } from "./institution-type-references";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeInstitutionTypeReferenceEmail",
    ({ referenceId, emailId }) =>
        `/institutiontype-references/${referenceId}/email-addresses/${emailId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError(
            "E-mail van zorgvoorzieningstype referentie verwijderen mislukt",
        ),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const removeReferenceEmail = (referenceId: string, emailId: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { referenceId, emailId } }, true)).then(
        response => {
            if (!response || response.type !== constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message:
                        "E-mailadres verwijderd van zorgvoorzieningstype referentie",
                }),
            );
            return dispatch(loadReferences(undefined, true));
        },
    );
};
