// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "getCustomQueries",
    () => "/custom-queries",
    {
        method: "GET",
        errorHandler: notifyApiError("Gecombineerde filters ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
export const cancel = actions.cancel;

export const constants = _constants;
