// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { update } from "./assignmentsValues";
import type { Person } from "@types";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateReviewer",
    ({ inspectionId }) => `/inspections/${inspectionId}/reviews`,
    {
        method: "PATCH",
        errorHandler: notifyApiError("Kwalitetiscontroleur aanpassen mislukt"),
    },
);

/*
 *   execute call to request a review
 *   and if succesful also update selected assignment record
 */
export const updateReviewer = (inspectionId: string, reviewer: Person) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { inspectionId }, data: { uuid: reviewer.id } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            // success notification done in AssingmentLayout using modal
            return dispatch(update({ selectedRecord: { assignee: reviewer } }));
        }
        return undefined;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
