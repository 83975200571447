// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "shortcoming",
    ({ inspectionPointId, shortcomingId }) =>
        `/inspectionpoints/${inspectionPointId}/shortcomings/${shortcomingId}`,
    {
        errorHandler: notifyApiError("Details inbreuk ophalen mislukt", false),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
