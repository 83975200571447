// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadClusters } from "./get-clusters";

const { constants: _constants, reducer, actions } = createApiModule(
    "createCluster",
    () => `/clusters`,
    {
        method: "POST",
        errorHandler: notifyApiError("Cluster aanmaken mislukt"),
    },
);
export const createCluster = (data: *) => (dispatch: *) => {
    return dispatch(load({ data }, true)).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Cluster aangemaakt",
                }),
            );
            return dispatch(loadClusters(undefined, true));
        }
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
