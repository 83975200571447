// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeVisit",
    ({ inspectionId, visitId }) =>
        `/inspections/${inspectionId}/visits/${visitId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Bezoek verwijderen mislukt"),
    },
);

export const removeVisit = (inspectionId: string, visitId: string) => (
    dispatch: *,
) => {
    return dispatch(
        load({
            path: { inspectionId, visitId },
        }),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Bezoek is succesvol verwijderd",
                }),
            );
            dispatch(loadInspection(inspectionId));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
