// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { startDownload } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "modulePdf",
    ({ moduleInstanceId }) => `/forms/submissions/${moduleInstanceId}/preview`,
    {
        method: "GET",
        errorHandler: notifyApiError("Formulier preview ophalen mislukt"),
    },
);

export const downloadPdf = (moduleInstanceId: string, fileName: string) => (
    dispatch: *,
) => {
    dispatch(
        notify({
            severity: NOTIFICATIONS.SEVERITY.INFO,
            message: `Preview van ${fileName} wordt gegenereerd...`,
            autoHide: false,
        }),
    );
    return dispatch(load({ path: { moduleInstanceId } }, true)).then(
        response => {
            if (!response || response.type !== constants.SUCCESS) return false;
            return startDownload(dispatch, response.payload.uri, fileName);
        },
    );
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
