// @flow
import cloneDeep from "lodash.clonedeep";
import { isArrayWithContent } from "../arrays";

export const validateAndUpdateStore = (
    storeName: string,
    setter: *,
    fields: Array<*>,
) => (key: *, data: *, pageIndex: *, extraKeys?: string[]) => (
    dispatch: *,
    getState: *,
) => {
    const update = cloneDeep(getState()[storeName]);

    update[key] = data;

    const relatedStepData = !isArrayWithContent(extraKeys)
        ? {} //$FlowFixMe
        : extraKeys.reduce((obj, key) => {
              obj[key] = update[key];
              return obj;
          }, {});

    return fields[pageIndex]
        .validate(
            {
                [key]: data,
                ...relatedStepData,
            },
            { abortEarly: false },
        )
        .then(() => {
            update.pages[pageIndex].isValid = true;
        })
        .catch(error => {
            console.log(error);
            update.pages[pageIndex].isValid = false;
            update.pages[pageIndex].isComplete = false;
        })
        .finally(() => dispatch(setter(update)));
};

export const validateAndUpdateStoreMultiple = (
    storeName: string,
    setter: *,
    fields: Array<*>,
) => (data: *, pageIndex: *, extraKeys?: string[]) => (
    dispatch: *,
    getState: *,
) => {
    const update = {
        ...cloneDeep(getState()[storeName]),
        ...data,
    };

    const relatedStepData = !isArrayWithContent(extraKeys)
        ? {} //$FlowFixMe
        : extraKeys.reduce((obj, key) => {
              obj[key] = update[key];
              return obj;
          }, {});

    return fields[pageIndex]
        .validate(
            {
                ...data,
                ...relatedStepData,
            },
            { abortEarly: false },
        )
        .then(() => {
            update.pages[pageIndex].isValid = true;
        })
        .catch(error => {
            console.log(error);
            update.pages[pageIndex].isValid = false;
            update.pages[pageIndex].isComplete = false;
        })
        .finally(() => dispatch(setter(update)));
};
