// @flow

const definition = [
    {
        label: "Inrichtende Macht",
        id: "authority-id",
        isSortable: false,
        isFilterable: true,
        type: "inspectionPointId",
        icon: "MYLOCATION",
        options: {
            searchLabel: "Zoek Inrichtende Macht",
            queryOptions: { type: "INRICHTENDE_MACHT" },
        },
    },
    {
        label: "Postcode",
        id: "zipcode",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: "ZVT",
        id: "institution-type-id",
        isSortable: false,
        isFilterable: true,
        type: "ZVT",
        icon: "MODULE",
    },
    {
        label: "Inspectiepunt",
        id: "inspection-point-id",
        sortName: "inspectionPointName",
        isSortable: false,
        isFilterable: true,
        type: "inspectionPointId",
        icon: "MYLOCATION",
        options: {
            searchLabel: "Zoek Inspectiepunt",
        },
    },
    {
        label: "Inspecteur",
        id: "inspector-id",
        isSortable: false,
        isFilterable: true,
        type: "person",
        icon: "PERSON",
    },
    {
        label: "Cluster",
        id: "cluster-id",
        isSortable: false,
        isFilterable: true,
        type: "cluster",
    },
    {
        label: "Actie",
        id: "action",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
