// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { constructQueryString } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "persons",
    ({ query }) => `/persons${constructQueryString(query, true)}`,
    {
        errorHandler: notifyApiError("Personenlijst ophalen mislukt", false),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
