// @flow
import { update as notify } from "@stores/notifications";
import { NOTIFICATIONS } from "@constants";

export const startDownload = (dispatch: *, uri: string, fileName: string) => {
    if (!document?.body) throw new Error("Error, missing <body>.");

    const link = document.createElement("a");
    link.href = uri;
    link.setAttribute("download", fileName.replace(/\s+/, "-"));
    link.style.display = "none";
    // $FlowFixMe
    document.body.appendChild(link);
    link.click();
    dispatch(
        notify({
            severity: NOTIFICATIONS.SEVERITY.SUCCESS,
            message: "Download gestart",
        }),
    );
    // cleanup the link element, otherwise they pile up
    // $FlowFixMe
    document.body.removeChild(link);
    return true;
};
