// @flow

export const sortOnProperty = (propName: string, reverse?: boolean = false) => (
    a: *,
    b: *,
) => {
    var smaller = reverse === true ? 1 : -1;
    var bigger = reverse === true ? -1 : 1;

    // do nothing if the properties don't exist
    if (!a[propName] && !b[propName]) return 0;

    if (typeof a[propName] === "string")
        return a[propName].localeCompare(b[propName]);

    if (a[propName] > b[propName]) return bigger;
    else if (a[propName] < b[propName]) return smaller;
    else return 0;
};

/*
 *  Method to pass to array.find() to find elements with a specific property value
 *  use: const element = list.find(findByProp("id", someIdValue))
 */
export const findByProp = (propName: string) => (value: *): * => (el: *) =>
    !!el[propName] && el[propName] === value;

/*
 *  Applies findByProp to a given list
 *  use:    const findIdInList = findByPropInList("id", id);
 *          const element = findIdInList(list);
 */
export const findByPropInList = (propName: string, value: *): * => (
    list: Array<*>,
) => list.find(findByProp(propName)(value));

/*
 *  Check if an array exists and has elements
 *  %checks is a Flow feature that lets Flow know this function checks types
 *  more info: https://flow.org/en/docs/types/functions/#toc-predicate-functions
 */
export const isArrayWithContent = (array: any): boolean %checks =>
    !!array && Array.isArray(array) && array.length > 0;

/*
 *   !! ONLY FOR SPECIFIC OPTION TYPES ARRAYS
 *   Rank selected list options first in an array of options
 *   Option type = { label: string, value: string, index: number }
 *   selected list = should be array of strings, but Flow goes crazy if it's typed that way
 */
type Option = {
    label: string,
    value: string,
    index?: number,
};
export const rankSelectedFirst = (
    selection: ?Array<*>,
    caseInsensitive?: boolean = false,
) => (a: Option, b: Option): number => {
    const compareIndexes = (a, b) =>
        a.index !== undefined && b.index !== undefined ? a.index - b.index : 0;

    if (!isArrayWithContent(selection)) return compareIndexes(a, b);

    if (caseInsensitive) {
        const hasValue = (value: string) => (el: string) =>
            el.toLowerCase() === value.toLowerCase();
        const hasValueA = hasValue(a.value),
            hasValueB = hasValue(b.value);

        if (selection.some(hasValueA) && !selection.some(hasValueB)) return -1;
        else if (!selection.some(hasValueA) && selection.some(hasValueB))
            return 1;

        return compareIndexes(a, b);
    }

    if (selection.includes(a.value) && !selection.includes(b.value)) return -1;
    else if (!selection.includes(a.value) && selection.includes(b.value))
        return 1;

    return compareIndexes(a, b);
};

/*
 *  Trim certain number of elements
 */

export const truncate = (data: Array<*>, limit?: number) => {
    if (!isArrayWithContent(data)) return "";
    if (limit && data.length > limit) data = data.slice(0, limit).concat("...");
    return data.map((el: *) => el).join(", ");
};

/*
 *   filter element at specific index from array
 */
export const filterIndex = (arr: Array<*>, index: number): Array<*> =>
    arr.filter((el, i) => i !== index);
