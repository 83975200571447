// @flow

import createApiModule from "../create-api-module";
import { update } from "./assignmentsValues";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { PersonUtil } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "addInspectors",
    ({ inspectionId }) => `/inspections/${inspectionId}/inspectors`,
    {
        method: "POST",
        errorHandler: notifyApiError("Inspecteur toevoegen mislukt"),
    },
);

/*
 *   execute call to add an inspector
 *   and if succesful also update selected assignment record
 */
export const addInspector = (
    inspectionId: string,
    inspector: { id: string, firstName: string, lastName: string },
) => (dispatch: *, getState: *) => {
    return dispatch(
        load({ path: { inspectionId }, data: { uuid: inspector.id } }, true),
    ).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: `${PersonUtil.display(inspector)} toegewezen`,
            }),
        );
        const {
            assignmentsValues: { selectedRecord },
        } = getState();
        const inspectors = Array.isArray(selectedRecord.inspectors)
            ? selectedRecord.inspectors.concat(inspector)
            : [inspector];
        return dispatch(update({ selectedRecord: { inspectors } }));
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
