// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getExemptions } from "./exemptions";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateExemption",
    ({ inspectionPointId, exemptionId }) =>
        `/inspectionpoints/${inspectionPointId}/exemptions/${exemptionId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Afwijking aanpassen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const updateExemption = (
    inspectionPointId: string,
    exemptionId: string,
    data: *,
    includeArchived: boolean,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionPointId, exemptionId }, data }),
    ).then(response => {
        // clear to prevent params/data contamination between updates
        dispatch(clear());

        if (!response || response.type !== constants.SUCCESS) return false;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Afwijking aangepast.",
            }),
        );
        return dispatch(
            getExemptions(
                { path: { inspectionPointId, includeArchived } },
                true,
            ),
        );
    });
};
