// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "notificationTemplates",
    ({ type }) =>
        type
            ? `/notification-templates?type=${type}`
            : `/notification-templates`,
    {
        method: "Get",
        clearOnError: true,
        errorHandler: notifyApiError("Templates ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
