import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "getClusters",
    ({ clusterId }) => {
        const base = "/clusters";
        return clusterId ? `${base}/${clusterId}` : base;
    },
    {
        method: "GET",
        errorHandler: notifyApiError("Lijst van clusters ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
