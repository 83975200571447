export const NOTIFICATIONS = {
    SEVERITY: {
        SUCCESS: "success",
        ERROR: "error",
        INFO: "info",
        WARNING: "warning",
    },
    TYPE: {
        MODAL: "modal",
    },
};

export default NOTIFICATIONS;
