// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "addVisitPeriod",
    ({ inspectionId, visitId }) =>
        `/inspections/${inspectionId}/visits/${visitId}/periods`,

    {
        method: "POST",
        errorHandler: notifyApiError("Bezoekperiode toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

/*
 *   execute call to add a visit period
 */
export const addVisitPeriod = (
    inspectionId: string,
    visitId: string,
    data: *,
) => (dispatch: *) => {
    return dispatch(load({ path: { inspectionId, visitId }, data }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        message: "Bezoekmoment toegevoegd",
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    }),
                );
                return action.payload;
            }
            return false;
        },
    );
};
