// leave routes that set their own helmet tag empty strings;
export const ROUTES_MAP = {
    aanvragen: {
        "zoek-inspectiepunt":
            "Inspectieaanvraag aanmaken > Zoek hoofdinspectiepunt",
        "opdracht-overzicht": "Inspectieaanvraag aanmaken > Overzicht",
        "kies-redenen": "Inspectieaanvraag aanmaken > Selecteer reden",
        "kies-inspectiepunt":
            "Inspectieaanvraag aanmaken > Gegevens inspectiepunten toevoegen",
        "data-aanvullen": "Inspectieaanvraag aanmaken > Datums & bijlages",
        persoonsinformatie: "Inspectieaanvraag aanmaken > Aangevraagd door",
        historiek: "Inspectieaanvraag > Historiek",
        "aanvraag-details": "",
        "aanvragen-team": "Alle aanvragen",
        "mijn-aanvragen": "Mijn aanvragen",
    },
    opdrachten: {
        "mijn-opdrachten": "Mijn opdrachten",
        "opdrachten-team": "Alle opdrachten",
        "mijn-gevolgde-opdrachten": "Gevolgde Opdrachten",
        afgewerkt: "",
        bijlagen: "",
        contactpersonen: "",
        emails: "",
        gegevens: "",
        historiek: "",
        "inspectie-details": "",
        inspectiepunten: "",
        "interne-communicatie": "",
        reactie: "",
        verslagen: "",
    },
    beheer: {
        bulkopdrachten: "Beheer > Bulk opdrachten uploaden",
        emailsjablonen: "Beheer > E-mail sjablonen",
        "keuzelijst-uitstel": "Beheer > Keuzelijst uitstel",
        "labels-netwerkinspectie": "Beheer > Labels netwerkinspectie",
        "nieuw-inspectiepunt": "Beheer > Nieuwe inspectiepunten",
        redenen: "Beheer > Redenen",
        gebruikers: {
            "interne-groepen": "Beheer > Gebruikers > Interne groepen",
            "interne-personen": "Beheer > Gebruikers > Interne personen",
            regioverdeling: "Beheer > Gebruikers > Regioverdeling",
            clusters: "Beheer > Gebruikers > Clusters",
        },
        zvg: "Beheer > Zorgvoorzieningsgroepen",
    },
    "geavanceerd-zoeken": {
        aanvragen: "Geavanceerd Zoeken > Aanvragen",
        opdrachten: "Geavanceerd Zoeken > Opdrachten",
    },
    inspectie: {
        "zoek-inspectiepunt": "Opdracht aanmaken > Zoek hoofdinspectiepunt",
        "opdracht-overzicht": "Opdracht aanmaken > Overzicht",
        "kies-redenen": "Opdracht aanmaken > Selecteer redenen",
        "kies-inspectiepunt":
            "Opdracht aanmaken > Gegevens inspectiepunten toevoegen",
        "data-aanvullen": "Opdracht aanmaken > Datums & bijlages",
    },
    inspectiepunt: {
        "zoek-inspectiepunt": "Inspecitepunt zoeken",
        aanvragen: "",
        dossier: "",
        historiek: "",
        inbreuken: "",
        opdrachten: "",
        aandachtspunten: "",
    },
};
