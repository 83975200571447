// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { loadModulesForVisit } from "./visit-modules";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateModuleProperty",
    ({ inspectionId, visitId, moduleInstanceId }) =>
        `/inspections/${inspectionId}/visits/${visitId}/modules/${moduleInstanceId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Module eigenschap aanpassen mislukt"),
    },
);

export default reducer;

export const sendModulePropToBackend = ({
    inspectionId,
    visitId,
    inspectionPointId,
    moduleInstanceId,
    propToUpdate,
    valueToUpdate,
}: *) => (dispatch: *) => {
    return dispatch(
        load(
            {
                path: { inspectionId, visitId, moduleInstanceId },
                data: {
                    property: propToUpdate,
                    value: valueToUpdate,
                },
            },
            true,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            return dispatch(
                loadModulesForVisit(inspectionId, visitId, inspectionPointId),
            );
        }
        return undefined;
    });
};

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
