// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadReferences } from "./institution-type-references";

const { constants: _constants, reducer, actions } = createApiModule(
    "addInstitutionTypeReferenceEmail",
    ({ referenceId }) =>
        `/institutiontype-references/${referenceId}/email-addresses`,
    {
        method: "POST",
        errorHandler: notifyApiError(
            "E-mail van zorgvoorzieningstype referentie verwijderen mislukt",
        ),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const addReferenceEmail = (
    referenceId: string,
    emailAddress: string,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { referenceId }, data: { emailAddress } }, true),
    ).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message:
                    "E-mailadres toegevoegd aan zorgvoorzieningstype referentie",
            }),
        );
        dispatch(loadReferences(undefined, true));
        return response;
    });
};
