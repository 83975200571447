export const REQUESTS = {
    STATUSES: {
        CANCELLED: "CANCELLED",
        CLOSED: "CLOSED",
        CREATED: "CREATED",
        INCLUDED_IN_INSPECTION: "INCLUDED_IN_INSPECTION",
        VALIDATED: "VALIDATED",
        VALIDATION_REQUESTED: "VALIDATION_REQUESTED",
    },
    LABELS: {
        CANCELLED: "Geannuleerd",
        CLOSED: "Afgewerkt",
        CREATED: "Nieuw",
        INCLUDED_IN_INSPECTION: "Opgenomen",
        VALIDATED: "Toegekend",
        VALIDATION_REQUESTED: "Te valideren",
    },
    DATE_KEYS: [
        "inspectionRequestCreatedAt",
        "requestedInspectionDateBefore",
        "requestedInspectionDateAfter",
    ],
    EDIT_DATE_KEYS: [
        "inspectionRequestCreatedOn",
        "requestedInspectionDateBefore",
        "requestedInspectionDateAfter",
    ],
    REQUESTERS: [
        { label: "Functionele Entiteit", value: "FE" },
        { label: "Zorginspectie", value: "ZI" },
        { label: "Inrichtende Macht", value: "IM" },
    ],
    HISTORY: {
        CREATE: "Aanvraag aangemaakt",
        STATUS_TRANSITION: "Statusovergang",
        ASSIGN: "Hoofdinspecteur toegewezen",
        REQUEST_VALIDATION: "Validator toegewezen",
        ADD_ATTACHMENT: "Bijlage toegevoegd",
        REMOVE_ATTACHMENT: "Bijlage verwijderd",
        UPDATE: "Aanvraag aangepast",
        CHANGE_TEAM: "Team gewijzigd",
    },
};
