// @flow
import createApiModule from "../create-api-module";
import { NOTIFICATIONS } from "@constants";
import { notifyApiError, update as notify } from "./notifications";
import { startDownload } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "downloadInbreukenCSV",
    ({ inspectionPointId }) =>
        `/inspectionpoints/${inspectionPointId}/shortcomings/export`,
    {
        method: "GET",
        errorHandler: notifyApiError("Downloaden van het bestand is mislukt"),
    },
);

export const fetchCSV = (inspectionPointId: string, dispatch: *) => {
    dispatch(
        notify({
            severity: NOTIFICATIONS.SEVERITY.INFO,
            message: `CSV wordt opgehaald...`,
            autoHide: false,
        }),
    );
    return dispatch(
        load(
            {
                path: {
                    inspectionPointId: inspectionPointId,
                },
                responseType: "arraybuffer",
                headers: {
                    "Content-Type": "text/csv",
                    Accept: "text/csv",
                },
            },
            true,
        ),
    );
};

export const downloadInbreukenCSV = (
    inspectionPointId: string,
    inspectionPointName: string,
) => (dispatch: *, getState: *) => {
    return fetchCSV(inspectionPointId, dispatch).then((response: any) => {
        if (!response || (response.type && response.type !== constants.SUCCESS))
            return null;
        const data =
            response === "SUCCESS"
                ? new Blob([getState().downloadDocument.data], {
                      type: `text/csv`,
                  })
                : new Blob([response.payload], { type: `text/csv` });
        const uri = window.URL.createObjectURL(data);
        startDownload(
            dispatch,
            uri,
            `${inspectionPointName ?? inspectionPointId}-inbreuken`,
        );
        window.URL.revokeObjectURL(uri);
        return true;
    });
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
// reducer
export default reducer;

export const constants = _constants;
