// @flow

const definition = [
    {
        label: "Beschrijving",
        id: "description",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Bestandsnaam",
        id: "file-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Datum toegevoegd",
        id: "date-added",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Wie",
        id: "who",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Actie",
        id: "action",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
];

export default definition;
