// @flow

import createApiModule from "../create-api-module";
import { set } from "./assignmentsValues";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import cloneDeep from "lodash.clonedeep";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeInspectors",
    ({ inspectionId, inspectorId }) =>
        `/inspections/${inspectionId}/inspectors/${inspectorId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Inspecteur verwijderen mislukt"),
    },
);

/*
 *   execute call to remove an inspector
 *   and if succesful also update selected assignment record
 */
export const removeInspector = (inspectionId: string, inspectorId: string) => (
    dispatch: *,
    getState: *,
) => {
    return dispatch(load({ path: { inspectionId, inspectorId } }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Inspecteur verwijderd",
                    }),
                );
                // do not try to copy state objects with nested properties by destructuring!
                // always leads to pass by reference confusion
                let assignmentsValues = cloneDeep(getState().assignmentsValues);
                const update = assignmentsValues.selectedRecord.inspectors.filter(
                    p => p.id !== inspectorId,
                );
                assignmentsValues.selectedRecord.inspectors = update;
                return dispatch(set(assignmentsValues));
            }
            return false;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
