// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { set } from "./reactions";

const { constants: _constants, reducer, actions } = createApiModule(
    "getReactions",
    ({ inspectionId }) => `/inspections/${inspectionId}/reactions`,

    {
        method: "GET",
        errorHandler: notifyApiError("Reactie ophalen mislukt"),
    },
);

export const loadReactions = (inspectionId: string) => (
    dispatch: *,
    getState: *,
) => {
    return dispatch(load({ path: { inspectionId } }, true)).then(response => {
        if (response && response.type === constants.SUCCESS) {
            const reactions = [...getState().getReactions.data];
            return dispatch(set(reactions));
        }
        return false;
    });
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
