// @flow
const definition = [
    {
        label: "Volledige beschrijving",
        id: "fullDescription",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Opdracht nummer",
        id: "inspectionReference",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Datum inspectie",
        id: "inspectionDate",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
