// @flow

import createApiModule from "../create-api-module";
import { getContactPersons } from "./get-contact-persons";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { storage } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "addContactPersons",
    ({ inspectionId }) => `/inspections/${inspectionId}/contacts`,

    {
        method: "POST",
        errorHandler: notifyApiError("ContactPerson toevoegen mislukt"),
    },
);

export const addContactPerson = (inspectionId: string, values: *) => (
    dispatch: *,
) => {
    if (navigator.onLine) {
        return dispatch(
            load({ path: { inspectionId }, data: { ...values } }, true),
        ).then(action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Gegevens ontvanger toevoegen is gelukt",
                    }),
                );

                return dispatch(getContactPersons(inspectionId));
            }
        });
    } else {
        const storageContacts = storage.get(`offline-contacts-${inspectionId}`);
        let offlineContacts = [];

        if (storageContacts) {
            offlineContacts = JSON.parse(storageContacts);
        }

        offlineContacts.push({
            id: `offline-${new Date().getTime()}`,
            ...values,
        });
        storage.set(
            `offline-contacts-${inspectionId}`,
            JSON.stringify(offlineContacts),
        );
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message: "Ontvanger verslagen werd offline toegevoegd",
            }),
        );
        return dispatch(getContactPersons(inspectionId));
    }
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
