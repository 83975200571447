// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "addClusterGroups",
    ({ clusterId }) => `/clusters/${clusterId}/groups`,
    {
        method: "POST",
        errorHandler: notifyApiError("Groep toevoegen aan cluster mislukt"),
    },
);

/*
 *   execute call to add a (internal)Group to a cluster
 */
export const addClusterGroups = (clusterId: string, groupId: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { clusterId }, data: groupId }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Groep toegevoegd aan cluster",
                    }),
                );
            }
            return true;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
