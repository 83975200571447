// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { loadReactions } from "./get-reactions";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "addReaction",
    ({ inspectionId }) => `/inspections/${inspectionId}/reactions`,

    {
        method: "POST",
        errorHandler: notifyApiError("Reactie kon niet worden toegevoegd"),
    },
);

export const addReaction = (inspectionId: string, reaction: *) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { inspectionId }, data: { ...reaction } }, true),
    )
        .then(response => {
            if (!response || response.type !== constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Reactie is met succes toegevoegd",
                }),
            );
            dispatch(loadReactions(inspectionId));
            if (reaction.type === "DEADLINE_EXTENSION_REQUESTED")
                dispatch(
                    loadInspection(
                        inspectionId,
                        "Reactie met succes toegevoegd, maar opdracht gegevens vernieuwen is mislukt",
                    ),
                );
            return true;
        })
        .then(() => dispatch(clear()));
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
