// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadDeadlineReasons } from "./deadline-extension-reasons";

const { constants: _constants, reducer, actions } = createApiModule(
    "editDeadlineExtensionReason",
    ({ reasonId }) => `/deadline-extension-reasons/${reasonId}`,

    {
        method: "DELETE",
        errorHandler: notifyApiError("Reden verwijderen mislukt"),
    },
);

export const deleteDeadlineReason = (reasonId: string) => (dispatch: *) => {
    return dispatch(load({ path: { reasonId } }, true)).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Reden is succesvol verwijderd",
                }),
            );
            dispatch(loadDeadlineReasons({}, true));
            return true;
        }
        return null;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
