// @flow

import { findByProp, filterIndex, isArrayWithContent } from "../arrays";

/*
 *   INTERNAL
 */
type ApplyExtraPropertyOptions = {
    modules: Array<*>,
    assigned: boolean,
    counterForModule: (moduleId: string) => number,
    extraPropertyKey: string,
};

type UpdateModulesOptions = {
    modules: Array<*>,
    type: string,
    parent?: *,
    action: *,
    target: string,
    infringements?: Array<*>,
    exemptions?: Array<*>,
};

type UpdateCategoryWithParentModuleOptions = {
    category: *,
    payload: *,
    infringements?: Array<*>,
    exemptions?: Array<*>,
};

const countModulesWithFilter = (filterFn: Function) => (
    modules: ?Array<*>,
): number => {
    if (!modules || !modules.length) return 0;
    return modules.reduce((sum: number, module: *) => {
        const sublength: number = module.moduleInstances.reduce(
            (acc: number, instance) => {
                if (instance.submoduleInstances)
                    return (
                        acc +
                        instance.submoduleInstances.filter(filterFn).length
                    );
                return acc;
            },
            0,
        );
        const numberInstances = module.moduleInstances.filter(
            instance => instance.type !== "PARENT" && filterFn(instance),
        ).length;
        // eslint nags about string concat, but these are all numbers..
        // eslint-disable-next-line smells/no-complex-string-concat
        return sum + numberInstances + sublength;
    }, 0);
};

const addInfringements = infringements => instance => {
    const infringementsCount = countOccurrencesForModule(infringements)(
        instance.moduleId,
    );
    return infringementsCount
        ? { ...instance, infringements: infringementsCount }
        : instance;
};

const addExemptions = exemptions => instance => {
    const exemptionsCount = countOccurrencesForModule(exemptions)(
        instance.moduleId,
    );
    return exemptionsCount
        ? { ...instance, exemptions: exemptionsCount }
        : instance;
};

const mapMarkerList = (
    instances: Array<*>,
    list?: Array<*>,
    instanceMapper: Function,
) => {
    if (!list || !list.length) return instances;
    return instances.map(instanceMapper(list));
};

const sumChildCounters = (subListKey = "submoduleInstances") => (
    instance: *,
) => {
    if (!isArrayWithContent(instance[subListKey])) return instance;
    instance.infringements = 0;
    instance[subListKey].forEach((sub: *) => {
        instance.infringements =
            (instance.infringements || 0) + (sub.infringements || 0);
    });
    instance.exemptions = 0;
    instance[subListKey].forEach((sub: *) => {
        instance.exemptions =
            (instance.exemptions || 0) + (sub.exemptions || 0);
    });
    return instance;
};

const getNewCategoryWithMarkers = (
    action: *,
    infringements?: Array<*>,
    exemptions?: Array<*>,
) => {
    return {
        ...action.payload,
        moduleInstances: action.payload.moduleInstances.map(instance => {
            instance = addInfringements(infringements)(instance);
            instance = addExemptions(exemptions)(instance);
            if (!instance.submoduleInstances) return instance;

            instance = {
                ...instance,
                submoduleInstances: mapMarkerList(
                    mapMarkerList(
                        instance.submoduleInstances,
                        infringements,
                        addInfringements,
                    ),
                    exemptions,
                    addExemptions,
                ),
            };
            instance = sumChildCounters("submoduleInstances")(instance);
            return instance;
        }),
    };
};

const mapExtraInstances = (
    extraInstances: Array<*>,
    infringements?: Array<*>,
    exemptions?: Array<*>,
) => {
    extraInstances = mapMarkerList(
        extraInstances,
        infringements,
        addInfringements,
    );
    extraInstances = mapMarkerList(extraInstances, exemptions, addExemptions);
    return extraInstances;
};

const updateCategoryWithParentModule = ({
    category,
    payload,
    infringements,
    exemptions,
}: UpdateCategoryWithParentModuleOptions) => {
    let parent = payload.moduleInstances[0];
    if (!parent) return category;
    parent.submoduleInstances = mapExtraInstances(
        parent.submoduleInstances,
        infringements,
        exemptions,
    );
    parent = sumChildCounters("submoduleInstances")(parent);

    category.moduleInstances = category.moduleInstances.concat(
        mapExtraInstances([parent], infringements, exemptions),
    );

    category = sumChildCounters("submoduleInstances")(category);
    category = markParentClones(
        category,
        payload.moduleInstances[0].moduleId,
        payload.moduleInstances[0].displayName,
    );
    return category;
};

const occursForModule = (moduleId: string) => (item: *) =>
    !!item.module?.id && item.module.id === moduleId;

const findByInstanceId = findByProp("instanceId");

/*
 *   EXPORTED
 */
export const countModules = countModulesWithFilter(() => true);
export const countModulesWithContent = countModulesWithFilter(
    instance => instance.hasContents,
);
export const countOccurrencesForModule = (list?: Array<*>) => (
    moduleId: string,
) => {
    if (!list || !list.length) return 0;
    return list.filter(occursForModule(moduleId)).length;
};

const removeCounter = displayName => displayName.replace(/\s\(\d+\)$/, "");

export const markParentClones = (
    category: *,
    parentModuleId: *,
    originalName: string,
) => {
    const clones = category.moduleInstances.filter(
        (el: *) => el.moduleId === parentModuleId,
    );
    if (clones.length === 0) return category;
    if (clones.length === 1) {
        const index = category.moduleInstances.findIndex(
            (el: *) => el.moduleId === parentModuleId,
        );
        category.moduleInstances[index].displayName = removeCounter(
            category.moduleInstances[index].displayName,
        );
        return category;
    }

    category.moduleInstances = category.moduleInstances.map((instance: *) => {
        if (instance.type !== "PARENT") return instance;
        const count =
            clones.findIndex((el: *) => el.instanceId === instance.instanceId) +
            1;
        if (!count) return instance;
        return {
            ...instance,
            displayName: `${originalName} (${count})`,
        };
    });
    return category;
};

export const markParentClonesOnAllCategories = (categories: *) =>
    categories.map(category => {
        const parentsMap = new Map();
        category.moduleInstances
            .filter(el => el.type === "PARENT")
            .forEach(el => parentsMap.set(el.moduleId, el));
        parentsMap.forEach(parent => {
            category = markParentClones(
                category,
                parent.moduleId,
                removeCounter(parent.displayName),
            );
        });
        return category;
    });

export const applyExtraProperty = ({
    modules,
    assigned,
    counterForModule,
    extraPropertyKey,
}: ApplyExtraPropertyOptions) => {
    // quit early if no data
    if (!modules || !modules.length || !counterForModule || !extraPropertyKey)
        return modules;

    const nestedListProp = assigned ? "submoduleInstances" : "submodules";
    const listProp = assigned ? "moduleInstances" : "modules";
    const idName = assigned ? "moduleId" : "id";

    const mapModules = modules =>
        modules.map(module => {
            const propertyCount = counterForModule(module[idName]);
            if (module[nestedListProp]) {
                module[nestedListProp] = mapModules(module[nestedListProp]);
            }
            if (propertyCount) {
                return {
                    ...module,
                    [extraPropertyKey]: propertyCount,
                };
            } else return module;
        });
    return modules.map<*>(category => ({
        ...category,
        [listProp]: mapModules(category[listProp]),
    }));
};

export const countMarkersOnChildren = (modules: *, assigned: boolean) => {
    if (!modules || !modules.length) return modules;

    const listProp = assigned ? "moduleInstances" : "modules";
    const subListProp = assigned ? "submoduleInstances" : "submodules";
    return modules.map<*>((category: *) => ({
        ...category,
        [listProp]: category[listProp].map(sumChildCounters(subListProp)),
    }));
};

export const findInstancePath = (modules: Array<*>, id: string): number[] => {
    return modules.reduce((result: *, category: *, categoryIndex: number) => {
        let instanceIndex = category.moduleInstances.findIndex(
            findByInstanceId(id),
        );
        if (instanceIndex > -1) return [categoryIndex, instanceIndex];
        else {
            let subIndex;
            let instanceIndex = category.moduleInstances.findIndex(instance => {
                subIndex =
                    instance.submoduleInstances &&
                    instance.submoduleInstances.findIndex(findByInstanceId(id));
                return subIndex > -1;
            });
            if (instanceIndex > -1 && subIndex !== undefined && subIndex > -1)
                return [categoryIndex, instanceIndex, subIndex];
        }
        return result;
    }, []);
};

export const filterInstanceFromTree = (
    modules: Array<*>,
    instanceId: string,
    type: string,
) => {
    const [categoryIndex, moduleIndex, subIndex] = findInstancePath(
        modules,
        instanceId,
    );
    if (type === "submodule" && subIndex !== undefined) {
        modules[categoryIndex].moduleInstances[
            moduleIndex
        ].submoduleInstances = filterIndex(
            modules[categoryIndex].moduleInstances[moduleIndex]
                .submoduleInstances,
            subIndex,
        );
        // if still submodules left, just return
        if (
            modules[categoryIndex].moduleInstances[moduleIndex]
                .submoduleInstances.length > 0
        )
            return modules;
    }
    modules[categoryIndex].moduleInstances = filterIndex(
        modules[categoryIndex].moduleInstances,
        moduleIndex,
    );
    if (!modules[categoryIndex].moduleInstances.length) {
        modules = filterIndex(modules, categoryIndex);
    }
    return modules;
};

export const hasVisitHasModulesChanged = (
    selectedRecord: *,
    visitId: string,
    updatedModules: Array<*>,
) => {
    const visit = selectedRecord.visits.find(visit => visit.id === visitId);
    if (visit.moduleCount && visit.moduleCount > 1) return false;
    if (!visit.moduleCount && updatedModules.length >= 1) return true;
    if (visit.moduleCount === 1 && updatedModules.length === 0) return true;
};

/*
 *   Handle adding modules to the tree
 *   everything has to end up in the right place
 */
export const updateModules = ({
    modules,
    type,
    parent,
    action,
    target,
    infringements,
    exemptions,
}: UpdateModulesOptions): Array<*> => {
    const {
        payload: { moduleInstances: newInstances, id: categoryId },
    } = action;
    let category: * = modules.find(el => el.id === categoryId);
    if (!category) {
        const data = getNewCategoryWithMarkers(
            action,
            infringements,
            exemptions,
        );
        return modules.concat(data);
    } else {
        // make sure the tree is merged when adding all modules for a type
        if (target === "formTemplateId") {
            newInstances.forEach(instance => {
                instance = addInfringements(infringements)(instance);
                instance = addExemptions(exemptions)(instance);
                const customAction = {
                    payload: {
                        moduleInstances: [instance],
                        id: action.payload.id,
                    },
                };
                modules = updateModules({
                    modules,
                    type,
                    parent,
                    action: customAction,
                    target: "",
                    infringements,
                    exemptions,
                });
            });
            return modules;
        }

        // adding a parent => should no longer merge
        const notParent = action.payload.moduleInstances[0].type !== "PARENT";

        if (notParent && !parent) {
            category.moduleInstances = category.moduleInstances.concat(
                mapExtraInstances(newInstances, infringements, exemptions),
            );
            category = sumChildCounters("submoduleInstances")(category);
        } else if (parent && parent.instanceId) {
            const parentIndex = category.moduleInstances.findIndex(
                el => el.instanceId === parent.instanceId,
            );

            category.moduleInstances[
                parentIndex
            ].submoduleInstances = category.moduleInstances[
                parentIndex
            ].submoduleInstances.concat(
                mapExtraInstances(newInstances, infringements, exemptions),
            );
            category.moduleInstances[parentIndex] = sumChildCounters(
                "submoduleInstances",
            )(category.moduleInstances[parentIndex]);
        } else {
            category = updateCategoryWithParentModule({
                category,
                payload: action.payload,
                infringements,
                exemptions,
            });
        }
        // probably redundant thanks to pass by reference
        modules[modules.indexOf(category)] = category;
    }
    return modules;
};
