// @flow
import createApiModule from "../create-api-module";
import { update as notify } from "./notifications";
import { update as updateFiles } from "./uploader-value";
import { isArrayWithContent } from "@utils";
import { NOTIFICATIONS } from "@constants";
import {
    load as addToInspection,
    constants as notesConstants,
} from "./add-note";

import { load as loadNotes } from "./notes";

const { constants: _constants, reducer, actions } = createApiModule(
    "uploadImageAttachment",
    () => `/documents`,
    {
        method: "POST",
        errorHandler: undefined,
    },
);

const uploadImagesUUIDs = [];

export const attachImagesAsANote = (inspectionId: string) => (dispatch: *) => {
    if (uploadImagesUUIDs.length > 0) {
        const documentId = uploadImagesUUIDs[0];

        return dispatch(
            addToInspection(
                {
                    path: { inspectionId },
                    data: {
                        content: "Formuliersbijlage",
                        documentId,
                        inspectionId,
                        internal: false,
                    },
                },
                true,
            ),
        ).then((response: *) => {
            if (response) {
                if (
                    response.type === notesConstants.SUCCESS &&
                    !isArrayWithContent(response.payload.errors)
                ) {
                    dispatch(
                        notify({
                            severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                            message: `Afbeelding(en) met succes geupload`,
                        }),
                    );
                    // Remove the first element of the array
                    uploadImagesUUIDs.shift();

                    if (uploadImagesUUIDs.length > 0) {
                        return dispatch(attachImagesAsANote(inspectionId));
                    } else {
                        dispatch(
                            loadNotes(
                                { path: { inspectionId, internal: false } },
                                true,
                            ),
                        );
                    }
                }
            }
        });
    }
};

export const uploadImageAttachment = (
    acceptedFiles?: Array<*>,
    inspectionId: string,
) => (dispatch: *, getState: *) => {
    const { uploaderValue } = getState();

    let filesNeededForUpload = [];
    if (acceptedFiles) {
        filesNeededForUpload = [...acceptedFiles];
        updateFiles({
            toUpload: [...filesNeededForUpload],
        });
    } else {
        filesNeededForUpload = uploaderValue.toUpload;
    }

    if (filesNeededForUpload && filesNeededForUpload.length > 0) {
        const file = filesNeededForUpload[0];
        // necessary to use the name and size to render it in FR
        const config = {
            size: file.size,
            name: file.name,
        };
        var formData = new FormData();
        formData.append("file", file);

        return dispatch(
            load(
                {
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
                true,
            ),
        ).then((response: *) => {
            if (response) {
                if (
                    response.type === constants.SUCCESS &&
                    !isArrayWithContent(response.payload.errors)
                ) {
                    uploadImagesUUIDs.push(response.payload.uuid);
                    dispatch(
                        notify({
                            severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                            message: `Afbeelding(en) met succes geupload`,
                        }),
                    );
                    // Remove the first element of the array
                    filesNeededForUpload.shift();
                    dispatch(
                        updateFiles({
                            uploaded: [
                                ...uploaderValue.uploaded,
                                {
                                    uuid: response.payload.uuid,
                                    name: config.name,
                                    size: config.size,
                                },
                            ],
                            toUpload: filesNeededForUpload,
                        }),
                    );

                    if (filesNeededForUpload.length > 0) {
                        return dispatch(
                            uploadImageAttachment(
                                filesNeededForUpload,
                                inspectionId,
                            ),
                        );
                    } else {
                        return dispatch(attachImagesAsANote(inspectionId));
                    }
                }
            }

            if (
                isArrayWithContent(response.payload.errors) ||
                response.type === constants.ERROR ||
                response.type === constants.FAILURE
            ) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.ERROR,
                        message: "Afbeelding(en) uploaden mislukt",
                    }),
                );
            }
        });
    }
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
// reducer
export default reducer;

export const constants = _constants;
