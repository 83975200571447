// @flow
import React from "react";
import { ASSIGNMENTS } from "@constants";
import type { Definition } from "@types";

export const assignments: Definition = [
    {
        label: "Opdrachtnummer",
        id: "reference",
        sortName: "reference",
        isSortable: true,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Inspectiepunt",
        id: "inspection-point",
        sortName: "mainInspectionPointName",
        isSortable: true,
        isFilterable: true,
        type: "inspectionPoint",
    },
    {
        label: "Hoofd-IP-nr",
        id: "external-id",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        isSortable: false,
        isFilterable: true,
        type: "groups",
    },
    {
        label: "Reden",
        id: "reason-id",
        isSortable: false,
        isFilterable: true,
        type: "reason",
    },
    {
        label: "Status",
        id: "status",
        sortName: "status",
        isSortable: true,
        isFilterable: true,
        options: Object.keys(ASSIGNMENTS.LABELS).map<*>((key, index) => ({
            label: ASSIGNMENTS.LABELS[key],
            value: key,
            index,
        })),
        type: "text",
    },
    {
        label: "Streefdatum",
        id: "target-date",
        sortName: "targetDate",
        isSortable: true,
        isFilterable: true,
        type: "date",
    },
    {
        label: "Laatste bezoek",
        id: "last-visit-done",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Datum verstuurd",
        id: "last-report-sent",
        isSortable: false,
        isFilterable: true,
        type: "date",
    },
    {
        label: "Hoofdinspecteur",
        id: "lead-inspector-id",
        isSortable: false,
        isFilterable: true,
        type: "person",
        options: {
            hidePersonsForIp: true,
        },
    },
    {
        label: "Toegewezen aan",
        id: "assignee-id",
        isSortable: false,
        isFilterable: true,
        type: "person",
    },
];

export const assignmentsForExternal: Definition = [
    {
        label: "Opdrachtnummer",
        id: "reference",
        sortName: "reference",
        isSortable: true,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Inspectiepunt",
        id: "inspection-point",
        sortName: "mainInspectionPointName",
        isSortable: true,
        isFilterable: true,
        type: "inspectionPoint",
    },
    {
        label: "Hoofd-IP-nr",
        id: "external-id",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        isSortable: false,
        isFilterable: true,
        type: "groups",
    },
    {
        label: "Reden",
        id: "reason-id",
        isSortable: false,
        isFilterable: true,
        type: "reason",
    },
    {
        label: "Status",
        id: "status",
        sortName: "status",
        isSortable: true,
        isFilterable: true,
        options: Object.keys(ASSIGNMENTS.LABELS).map<*>((key, index) => ({
            label: ASSIGNMENTS.LABELS[key],
            value: key,
            index,
        })),
        type: "text",
    },
    {
        label: "Streefdatum",
        id: "target-date",
        sortName: "targetDate",
        isSortable: true,
        isFilterable: true,
        type: "date",
    },
    {
        label: "Laatste bezoek",
        id: "last-visit-done",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Datum verstuurd",
        id: "last-report-sent",
        isSortable: false,
        isFilterable: true,
        type: "date",
    },
];
