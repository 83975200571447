// @flow

export const set = (key: *, value: *) =>
    typeof window !== undefined && localStorage.setItem(key, value);

export const get = (key: *) => {
    return typeof window !== "undefined"
        ? localStorage.getItem(key)
        : undefined;
};
export const remove = (key: *) => {
    return typeof window !== "undefined" && localStorage.removeItem(key);
};

export const isOfflineAvailable = (item: *, visitId: string) => {
    return Boolean(localStorage.getItem(`${visitId}-${item.instanceId}-form`));
};
