// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { getRequest } from "./request";

const { constants: _constants, reducer, actions } = createApiModule(
    "request",
    ({ requestId, documentId }) =>
        `/inspection-requests/${requestId}/attachments/${documentId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Bijlage verwijderen mislukt"),
    },
);

export const deleteRequestAttachment = (
    requestId: string,
    documentId: string,
) => (dispatch: *) => {
    return dispatch(load({ path: { requestId, documentId } }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                return dispatch(getRequest(requestId));
            }
            return null;
        },
    );
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
