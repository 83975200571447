// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadAdditionalInformation } from "./get-additional-information-for-IP";

const { constants: _constants, reducer, actions } = createApiModule(
    "addAdditionalInformationForInspectionPoint",
    ({ inspectionPointId }) =>
        `/inspectionpoints/${inspectionPointId}/additional-information`,
    {
        method: "POST",
        errorHandler: notifyApiError("Bijkomende informatie opslaan mislukt"),
    },
);

export const addAdditionalInformation = (
    inspectionPointId: string,
    content: string,
) => (dispatch: *) => {
    return dispatch(
        load(
            {
                path: { inspectionPointId },
                data: { content },
            },
            true,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Bijkomende informatie succesvol opgeslagen",
                }),
            );
            dispatch(
                loadAdditionalInformation(
                    { path: { inspectionPointId } },
                    true,
                ),
            );
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
