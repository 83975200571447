// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { loadReactions } from "./get-reactions";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteReaction",
    ({ inspectionId, reactionId }) =>
        `/inspections/${inspectionId}/reactions/${reactionId}`,

    {
        method: "DELETE",
        errorHandler: notifyApiError("Reactie kon niet worden verwijderd"),
    },
);

export const deleteReaction = (inspectionId: string, reactionId: *) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId, reactionId } }, true)).then(
        response => {
            if (response && response.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Reactie is met succes verwijderd",
                    }),
                );
                dispatch(loadReactions(inspectionId));
            }
            return false;
        },
    );
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
