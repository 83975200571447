// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { clear as clearAdditionalInformation } from "./get-additional-information-for-IP";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteAdditionalInformationForInspectionPoint",

    ({ inspectionPointId }) =>
        `/inspectionpoints/${inspectionPointId}/additional-information`,
    {
        method: "DELETE",
        errorHandler: notifyApiError(
            "Bijkomende informatie verwijderen mislukt",
        ),
    },
);
export const deleteAdditionalInformationForInspectionPoint = (
    inspectionPointId: string,
) => (dispatch: *) => {
    return dispatch(
        load(
            {
                path: { inspectionPointId },
            },
            true,
        ),
    ).then(() => {
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Bijkomende informatie is succesvol verwijderd",
            }),
        );
        dispatch(clearAdditionalInformation());
        return true;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
