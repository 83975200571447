// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getRequest } from "./request";

const { constants: _constants, reducer, actions } = createApiModule(
    "cancelRequest",
    ({ inspectionRequestId }) => `/inspection-requests/${inspectionRequestId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Aanvraag annuleren mislukt"),
    },
);

export const cancelRequest = (
    inspectionRequestId: string,
    motivation: string,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionRequestId }, data: { motivation } }),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(getRequest(inspectionRequestId));
            return dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Aanvraag is succesvol geannuleerd",
                }),
            );
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
