// @flow
import React from "react";
import { ASSIGNMENTS } from "@constants";
import type { Definition } from "@types";

export const advancedAssignments: Definition = [
    {
        id: "reference",
        label: "Opdrachtnummer",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        id: "external-reference",
        label: "Externe referentie",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        id: "inspection-point",
        label: "Inspectiepunt",
        sortName: "inspectionPointName",
        isSortable: false,
        isFilterable: true,
        type: "inspectionPoint",
        options: { hideStreet: false },
    },
    {
        label: "Hoofd-IP-nr",
        id: "external-id",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        isSortable: false,
        isFilterable: false,
        type: "groups",
    },
    {
        label: "Reden",
        id: "reason-id",
        isSortable: false,
        isFilterable: true,
        type: "reason",
    },
    {
        label: "Status",
        id: "status",
        sortName: "status",
        isSortable: false,
        isFilterable: true,
        options: Object.keys(ASSIGNMENTS.LABELS).map<*>((key, index) => ({
            label: ASSIGNMENTS.LABELS[key],
            value: key,
            index,
        })),
        type: "text",
    },
    {
        label: "Inspecteur",
        id: "inspector-id",
        isSortable: false,
        isFilterable: true,
        type: "person",
        options: { single: true, hidePersonsForIp: true },
    },
    {
        label: "Team",
        id: "team-id",
        isSortable: false,
        isFilterable: true,
        type: "team",
    },
    {
        label: "Aanmaakdatum",
        id: "created",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Laatste vaststelling",
        id: "last-observation",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Laatste bezoek",
        sortName: "date",
        id: "last-visit-done",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "OV verzonden",
        id: "draft-report-sent",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "DV verzonden",
        id: "final-report-sent",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Afgesloten",
        id: "closed",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Label",
        id: "label",
        sortName: "label",
        isSortable: false,
        isFilterable: true,
        type: "label",
    },
];

export const advancedAssignmentsForExternal: Definition = [
    {
        id: "reference",
        label: "Opdrachtnummer",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        id: "external-reference",
        label: "Externe referentie",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        id: "inspection-point",
        label: "Inspectiepunt",
        sortName: "inspectionPointName",
        isSortable: false,
        isFilterable: true,
        type: "inspectionPoint",
        options: { hideStreet: false },
    },
    {
        label: "Hoofd-IP-nr",
        id: "external-id",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        isSortable: false,
        isFilterable: false,
        type: "groups",
    },
    {
        label: "Reden",
        id: "reason-id",
        isSortable: false,
        isFilterable: true,
        type: "reason",
    },
    {
        label: "Status",
        id: "status",
        sortName: "status",
        isSortable: false,
        isFilterable: true,
        options: Object.keys(ASSIGNMENTS.LABELS).map<*>((key, index) => ({
            label: ASSIGNMENTS.LABELS[key],
            value: key,
            index,
        })),
        type: "text",
    },
    {
        label: "Team",
        id: "team-id",
        isSortable: false,
        isFilterable: true,
        type: "team",
    },
    {
        label: "Aanmaakdatum",
        id: "created",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Laatste vaststelling",
        id: "last-observation",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Laatste bezoek",
        sortName: "date",
        id: "last-visit-done",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "OV verzonden",
        id: "draft-report-sent",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "DV verzonden",
        id: "final-report-sent",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Afgesloten",
        id: "closed",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Label",
        id: "label",
        sortName: "label",
        isSortable: false,
        isFilterable: true,
        type: "label",
    },
];
