// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { set as setTreeValue } from "./tree-value";
import { isArrayWithContent } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "inspectionPointStructure",
    ({ inspectionPointId, includeInactive }) =>
        `/inspectionpoints/${inspectionPointId}/structure?include-siblings=false&include-inactive=${includeInactive}`,

    {
        method: "GET",
        errorHandler: notifyApiError("Structuur ophalen mislukt"),
        clearOnError: true,
        clearOnFetch: true,
    },
);

export const loadInspectionPointStructure = (
    inspectionPointId: string,
    includeInactive: boolean = false,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionPointId, includeInactive } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            const updatedData = [
                {
                    ...action.payload,
                    ...action.payload.inspectionPoint,
                    title: action.payload.inspectionPoint?.name,
                    expanded: true,
                    children:
                        isArrayWithContent(action.payload?.children) &&
                        action.payload.children.map(el => {
                            return {
                                ...el,
                                ...el.inspectionPoint,
                                expanded: true,
                                title: el.inspectionPoint.name,
                                children:
                                    isArrayWithContent(el.children) &&
                                    el.children.map(item => {
                                        return {
                                            ...item,
                                            ...item.inspectionPoint,
                                            title: item.inspectionPoint.name,
                                        };
                                    }),
                            };
                        }),
                },
            ];

            return dispatch(setTreeValue(updatedData));
        }
        return null;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
