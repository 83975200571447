// @flow

import { createMuiTheme } from "@material-ui/core";

//import variables from "./_variables.scss";
const letterSpacingNormal = "normal";
const letterSpacingMedium = "0.078em";

//for now use hardcoded colors, for gatsby-ssr until we find a solution
export default createMuiTheme({
    typography: {
        fontFamily: [
            "FlandersArtSans",
            "flanders-sans",
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        h1: {
            fontSize: "6em",
            lineHeight: 1.25,
            letterSpacing: letterSpacingNormal,
            fontWeight: "normal",
            color: "#333",
        },
        h2: {
            fontSize: "3.75em",
            lineHeight: 1.25,
            letterSpacing: letterSpacingNormal,
            fontWeight: "normal",
            color: "#333",
        },
        h3: {
            fontSize: "3em",
            lineHeight: 1.25,
            letterSpacing: letterSpacingNormal,
            fontWeight: "normal",
            color: "#333",
        },
        h4: {
            fontSize: "2.125em",
            lineHeight: 1.25,
            letterSpacing: letterSpacingNormal,
            fontWeight: "normal",
            color: "#333",
        },
        h5: {
            fontSize: "1.5em",
            lineHeight: 1.25,
            letterSpacing: letterSpacingNormal,
            fontWeight: "normal",
            color: "#333",
        },
        h6: {
            fontSize: "1.25em",
            lineHeight: 1.25,
            letterSpacing: letterSpacingNormal,
            fontWeight: 500,
            color: "#333",
        },
        subtitle1: {
            fontSize: "1em",
            lineHeight: 1.5,
            letterSpacing: letterSpacingNormal,
            fontWeight: 500,
            color: "#333",
        },
        subtitle2: {
            fontSize: "0.875em",
            lineHeight: 1.5,
            letterSpacing: letterSpacingNormal,
            fontWeight: 500,
            color: "#333",
        },
        body1: {
            fontSize: "1em",
            lineHeight: 1.5,
            letterSpacing: letterSpacingNormal,
            fontWeight: "normal",
        },
        body2: {
            fontSize: "0.875em",
            lineHeight: 1.5,
            letterSpacing: letterSpacingNormal,
            fontWeight: "normal",
        },
        button: {
            fontSize: "0.875em",
            fontWeight: 500,
            letterSpacing: letterSpacingMedium,
            lineHeight: 1.75,
        },
        caption1: {
            fontSize: "0.75em",
            lineHeight: 1.5,
            letterSpacing: letterSpacingNormal,
            fontWeight: 500,
        },
        overline: {
            fontSize: "0.625em",
            lineHeight: 1.5,
            letterSpacing: letterSpacingNormal,
            fontWeight: "normal",
        },
    },
    palette: {
        primary: {
            main: "#657f00",
        },
        secondary: {
            main: "#127fa4",
        },
        action: {
            hover: "#f4f6e5",
            focus: "#f4f6e5",
        },
        white: {
            main: "#fff",
        },
        light: {
            main: "#e4e4e4",
        },
        dark: {
            main: "#333",
        },
        success: {
            main: "#24a148",
        },
        error: {
            main: "#da1e28",
        },
        info: {
            main: "#127fa4",
        },
        warning: {
            main: "#fdd13a",
        },
        draft: {
            main: "#fdd13a",
        },
        text: {
            primary: "#333",
            disabled: "#969696",
        },
        teams: {
            welzijn: {
                main: "#8cc63f",
            },
            kinderopvang: {
                main: "#1668b2",
            },
            gehandicaptenzorg: {
                main: "#dc0d79",
            },
            gezondheidszorg: {
                main: "#024862",
            },
            financieel: {
                main: "#9b58ba",
            },
            overige: {
                main: "#626262",
            },
            gemengd: {
                main: "#d99a75",
            },
        },
    },
    overrides: {
        MuiChip: {
            outlined: {
                backgroundColor: "#fff",
            },
            outlinedSecondary: {
                "& > $label": { color: "#333" }, // dark
                "&:not(:hover):not(:focus):not($disabled), &:not($clickable):hover": {
                    borderColor: "#e4e4e4",
                },
                "&$disabled": { borderColor: "#969696", color: "#969696" },
                "&:focus": { borderColor: "#127fa4" },
            },
            deleteIconOutlinedColorSecondary: {
                color: "#333",
            },
            iconColorSecondary: {
                color: "#333",
            },
        },
        MuiOutlinedInput: {
            root: {
                position: "relative",
                "& $notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.87)",
                },
            },
        },
        MuiTableRow: {
            root: {
                "&$selected": { backgroundColor: "#f4f6e5" },
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: "#127fa4",
            },
        },
        MuiCardContent: {
            root: {
                padding: "0.75rem",
            },
        },
    },
    //        0, 1, 2,   3,  4,  5,  6,  7, 8
    spacing: [4, 8, 12, 16, 24, 32, 40, 64, 80, 96, 112, 128], // spacing 0 = 4, 1 = 8 etc...
});
