// @flow

import axios from "axios";
import { update } from "@stores/notifications";
import { NOTIFICATIONS } from "@constants";
import { navigate } from "gatsby";

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language = (() => {
    if (typeof window === "undefined") {
        return "nl-BE";
    }

    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    }

    return navigator.language || (navigator: any).userLanguage;
})();

export const create = (dispatch: *) => {
    const API_BASE = "/api";
    if (!API_BASE) throw new ReferenceError("NO API DEFINED");

    const axiosInstance = axios.create({
        baseURL: API_BASE,
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": language,
        },
        validateStatus: status => status >= 200 && status < 400,
    });

    /*
     *  INTERCEPTOR
     *  If the user is not or no longer logged in,
     *  show them a message and kick them to the login page
     */
    axiosInstance.interceptors.response.use(
        response => response,
        (error: any) => {
            if (error && error.response && error.response.status === 401) {
                dispatch(
                    update({
                        severity: NOTIFICATIONS.SEVERITY.ERROR,
                        message:
                            "Je bent niet aangemeld of je sessie is verstreken. Gelieve opnieuw aan te melden",
                        type: NOTIFICATIONS.TYPE.MODAL,
                        primaryAction: () => {
                            window.location.href = `${window.location.origin}/api/auth/init?fwdref=${window.location.pathname}`;
                        },
                        primaryActionText: "ok",
                    }),
                );
            }
            if (error && error.response && error.response.status === 403) {
                dispatch(
                    update({
                        severity: NOTIFICATIONS.SEVERITY.ERROR,
                        message: "Uw heeft geen toegang tot deze pagina.",
                        type: NOTIFICATIONS.TYPE.MODAL,
                        primaryAction: () => {
                            navigate("/403");
                        },
                        primaryActionText: "ok",
                    }),
                );
            }
            return Promise.reject(error);
        },
    );

    return axiosInstance;
};
