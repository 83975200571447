// @flow

import createApiModule from "../create-api-module";
import { update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "postponeAssignment",
    ({ inspectionId }) => `/inspections/${inspectionId}/deadline-extension`,
    {
        method: "POST",
        //errorHandler: notifyApiError("Opdracht Uitstellen mislukt"),
    },
);

const parseErrorMessage = (message: string) => {
    switch (message) {
        case "can not extend DRAFT_REPORT_DEADLINE because deadline date is currently null.":
            return "Er is nog geen streefdatum voor het ontwerpverslag.";
        case "can not extend FINAL_REPORT_DEADLINE because deadline date is currently null.":
            return "Er is nog geen streefdatum voor het definitief verslag.";
        default:
            return message;
    }
};

export const postponeAssignment = (
    inspectionId: string,
    reason: string,
    comment?: *,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionId }, data: { reason, comment } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            return dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Uitstel opdracht succesvol aangevraagd",
                }),
            );
        }
        if (action && action.type === constants.FAILURE) {
            return dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.ERROR,
                    message: `Opdracht uitstellen mislukt: ${parseErrorMessage(
                        action.payload.data.message,
                    )}`,
                }),
            );
        }

        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
