//@flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "formsImport",
    () => `/forms/import`,
    {
        method: "POST",
        errorHandler: notifyApiError("Versies importeren van AEM mislukt"),
    },
);
export const formsImport = () => (dispatch: *) => {
    dispatch(clear());
    return dispatch(load({}, true)).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Versies succesvol geïmporteerd van AEM",
            }),
        );
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
export const cancel = actions.cancel;

export const constants = _constants;
