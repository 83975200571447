// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateInspectionMainInstitutionType",
    ({ inspectionId }) => `/inspections/${inspectionId}/main-institution-type`,

    {
        method: "PATCH",
        errorHandler: notifyApiError("hoofdZVT aanpassen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const updateMainZVT = (inspectionId: string, data: *) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId }, data }, true)).then(
        action => {
            if (!action || action.type !== constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "hoofdZVT succesvol toegevoegd",
                }),
            );
            return dispatch(
                loadInspection(
                    inspectionId,
                    "hoofdZVT toegevoegd, maar het vernieuwen van de inspectieinformatie is mislukt.",
                ),
            );
        },
    );
};
