// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "inspectionPointChildren",
    ({ inspectionPointId, types }) =>
        types
            ? `/inspectionpoints/${inspectionPointId}/children?${types}`
            : `/inspectionpoints/${inspectionPointId}/children`,

    {
        method: "GET",
        errorHandler: notifyApiError("Data ophalen mislukt"),
    },
);

export const loadInspectionPointChildren = (
    inspectionPointId: string,
    types?: string | null,
) => (dispatch: *) => {
    return dispatch(load({ path: { inspectionPointId, types } }, true)).then(
        action => {
            if (!action || action.type !== constants.SUCCESS) return false;
            return action.payload;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
