// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadRegionalDistribution } from "./get-regional-distribution";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteRegionalDistribution",
    ({ entryId }) => `/regional-distribution/${entryId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Regioverdeling verwijderen mislukt"),
    },
);

export const deleteItem = (entryId: string, query: *) => (dispatch: *) => {
    return dispatch(load({ path: { entryId } }, true)).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Regioverdeling is succesvol verwijderd",
                }),
            );

            return dispatch(
                loadRegionalDistribution({ path: { query } }, true),
            );
        }
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
