// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { constructQueryString } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "getNotificationForInspection",
    ({ inspectionId, query }) =>
        `/inspections/${inspectionId}/notifications${constructQueryString(
            query,
            true,
        )}`,

    {
        method: "GET",
        errorHandler: notifyApiError("E-mails ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
