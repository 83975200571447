export const REPORTITEM = {
    TYPES: {
        DRAFT: "Ontwerpverslag",
        FINAL: "Definitief verslag",
    },
    REPORTER_VERSION: {
        TRUE: "Ja",
        FALSE: "Nee",
    },
    ACTIVATION_STATUS: {
        ACTIVATION_SCHEDULED: "Activering gepland",
        DEACTIVATION_SCHEDULED: "Deactivering gepland",
        ACTIVE: "Actief",
        INACTIVE: "Inactief",
    },
    IMPORT_STATUS: {
        SUCCEEDED: "Geslaagd",
        FAILED: "Mislukt",
    },
};
