// @flow
const definition = [
    {
        label: "Soort",
        id: "type",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Beschrijving",
        id: "description",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Aantal keer vastgesteld",
        id: "established",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Module",
        id: "module",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Acties",
        id: "actions",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
