// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getRequest } from "./request";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateRequestMainVisit",
    ({ inspectionRequestId }) =>
        `/inspection-requests/${inspectionRequestId}/replace-inspection-points`,
    {
        method: "POST",
        errorHandler: notifyApiError("Hoofdbezoek aanpassen mislukt"),
    },
);

export const updateRequestMainVisit = (
    inspectionRequestId: string,
    bodyData: any,
) => (dispatch: *) => {
    return dispatch(
        load({
            path: { inspectionRequestId },
            data: bodyData,
        }),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Hoofdbezoek is succesvol aangepast",
                }),
            );
            dispatch(getRequest(inspectionRequestId));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
