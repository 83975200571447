// @flow

import createValueModule from "../create-value-module";
import * as yup from "yup";
import moment from "moment-timezone";
import {
    selectedPoint,
    locationsAndTypes,
    selectedRequestReason,
    selectedDatesRequest,
    contactFunctionalEntity,
    submitter,
} from "../../definitions";

import { validateAndUpdateStore, validateAndUpdateStoreMultiple } from "@utils";
moment.tz.setDefault("Europe/Brussels");

/**
 *  STORE
 */
const { reducer, actions } = createValueModule("createRequest", {
    pages: [
        {
            id: "zoek-inspectiepunt",
            title: "Inspectiepunt zoeken",
            isValid: false,
            isComplete: false,
        },
        {
            id: "kies-inspectiepunt",
            title: "Gegevens inspectiepunten toevoegen",
            isValid: false,
            isComplete: false,
        },
        {
            id: "kies-reden",
            title: "Selecteer reden",
            isValid: false,
            isComplete: false,
        },
        {
            id: "data-aanvullen",
            title: "Datums en bijlages",
            isValid: false,
            isComplete: false,
        },
        {
            id: "persoonsinformatie",
            title: "Aangevraagd door",
            isValid: false,
            isComplete: false,
        },
        {
            id: "aanvraag-overzicht",
            title: "Aanvraag afronden",
            isValid: true,
            issues: [],
        },
    ],
    activePage: 0,
    selectedPoint: null,
    selectedReasons: null,
    inspectionRequestCreatedAt: moment().startOf("day"),
    requestedInspectionDateAfter: null,
    requestedInspectionDateBefore: null,
    externalReference: "",
    extraInformation: "",
    submitter: {
        submitterFirstName: "",
        submitterLastName: "",
        submitterEmailAddress: "",
        submitterPhoneNumber: "",
        submitterAddress: null,
        anonymity: "",
        dutchSpeaking: true,
    },
    contactFunctionalEntity: {
        requestedBy: "",
        functionalEntityTeamId: "",
        feContactFirstName: "",
        feContactLastName: "",
        feContactEmailAddress: "",
        feContactId: "",
    },
    attachments: [],
    externalReferenceId: "",
    reference: "",
    assignee: null,
    requestedBy: "",
    status: "ACTIVE",
    mainInstitutionType: null,
    institutionTypes: null,
    activityTypes: null,
    visits: [],
    treeId: -1,
});

export default reducer;

export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;

/**
 *  VALIDATION
 */

const FIELDS = [
    selectedPoint,
    locationsAndTypes,
    selectedRequestReason,
    selectedDatesRequest,
    yup.object({ submitter, contactFunctionalEntity }),
];
export const validateAndUpdateRequest = validateAndUpdateStore(
    "createRequest",
    set,
    FIELDS,
);
export const validateAndUpdateRequestMultiple = validateAndUpdateStoreMultiple(
    "createRequest",
    set,
    FIELDS,
);
