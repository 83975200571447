//@flow
import React, { Fragment } from "react";

const exists = elem => !!elem;

export const combinedZvtZatList = (
    zvt: string | string[],
    zat: string[],
    limit?: number,
): * => {
    const zvtList = typeof zvt === "string" ? [zvt] : zvt;
    const zatList =
        zat &&
        zat.filter(exists).map((el, idx) => <em key={`${el}-${idx}`}>{el}</em>);
    let combined = []
        .concat(zvtList)
        .concat(zatList)
        .filter(exists);

    if (!combined.length) return "";
    if (limit && combined.length > limit)
        combined = combined.slice(0, limit).concat("...");
    return (
        <span>
            {combined.map((el, idx) => (
                <Fragment key={idx}>
                    {el}
                    {idx < combined.length - 1 && ", "}
                </Fragment>
            ))}
        </span>
    );
};
