// @flow
import momentNoTZ from "moment";
import moment from "moment-timezone";
import "moment/locale/nl-be";

// source: https://github.com/moment/moment-timezone/issues/647
const setMomentLocale = (localeName: string) => {
    momentNoTZ.locale(localeName);
    // eslint-disable-next-line no-underscore-dangle
    moment.updateLocale(localeName, momentNoTZ.localeData()._config);
    moment.locale(localeName);
};
setMomentLocale("nl-be");

const dateTimeFormat = "DD/MM/YYYY [om] HH[u]mm";
const dateTimeLongFormat = "dddd DD MMMM YYYY [om] HH:mm";
const dateTimePickerFormat = "DD/MM/YYYY HH:mm";

const dateFormat = "DD/MM/YYYY";
const dateLongFormat = "dddd DD MMMM YYYY";
const datePostFormat = "YYYY-MM-DD";
const datePostFormatReversed = "DD-MM-YYYY";

const timeFormat = "HH:mm";
const timeFormatLong = "HH[u]mm";

/*
 *   Turn a js date, ISO string or other valid input into a moment
 *   While preserving the timezone offset if present
 */
export const parseDate = (date?: ?*) => date && moment.parseZone(date);

/*
 *   Formats a given date with the given format
 *   While preserving the timezone offset in the date object
 */
export const formatDate = (format: string) => (date?: ?*) =>
    date ? moment.parseZone(date).format(format) : "";

/*
 *   Turns a date into a string using the dateTimeFormat above
 */
export const displayDateTime = formatDate(dateTimeFormat);
export const displayDateTimeLong = formatDate(dateTimeLongFormat);
export const dateTimePlaceholder = formatDate(dateTimePickerFormat)(moment());
export const timePlaceholder = formatDate("HH:MM")(moment());

/*
 *   Turns a date into a string showing only date, no time
 */
export const displayDate = formatDate(dateFormat);
export const displayDateLong = formatDate(dateLongFormat);
export const datePlaceholder = displayDate(moment());
export const convertDateForAPI = formatDate(datePostFormat);
export const convertDateForAPIReversed = formatDate(datePostFormatReversed);

/*
 * Turns a date into a string showing time only
 */
export const displayTime = formatDate(timeFormat);
export const displayTimeLong = formatDate(timeFormatLong);

/*
 *   Get the correct string to show when an assignment
 *   or a request should be executed
 */
export const getExecutionTiming = (afterDate: ?*, beforeDate: ?*) => {
    if (beforeDate && afterDate)
        return `Tussen ${displayDate(afterDate)} en ${displayDate(beforeDate)}`;
    else if (afterDate && !beforeDate) return `Vanaf ${displayDate(afterDate)}`;
    else if (!afterDate && beforeDate)
        return `Tegen ${displayDate(beforeDate)}`;
    else return "Zowel 'vanaf' datum als 'tegen' datum ontbreekt";
};

/**
 *   Take the date part of one moment object
 *   and the time part of another to create
 *   a moment on the date of the first with the time of the second
 */
export const matchTimeToDate = (dateSource: *, timeSource: *) => {
    const time = {
        hour: moment(timeSource).get("hour"),
        minute: moment(timeSource).get("minute"),
    };
    if (moment.isMoment(dateSource) && !dateSource.isValid()) {
        dateSource = moment(dateSource.toDate());
    }

    return dateSource.clone().set(time);
};
