import { REQUESTS } from "../Requests";
import { ASSIGNMENTS } from "../Assignments";
import { primary, secondary } from "../../styles/_variables.scss";

export const PROCESS_STATUSES = {
    ASSIGNMENTS: [
        [
            {
                status: "CREATED",
                label: ASSIGNMENTS.LABELS.CREATED,
                stageNr: 0,
                color: primary,
            },
        ],

        [
            {
                status: "PLANNED",
                label: ASSIGNMENTS.LABELS.PLANNED,
                stageNr: 1,
                color: primary,
            },
        ],
        [
            {
                status: "PREPARED",
                label: ASSIGNMENTS.LABELS.PREPARED,
                stageNr: 2,
                color: primary,
            },
            {
                status: "VALIDATION_REQUESTED",
                label: ASSIGNMENTS.LABELS.VALIDATION_REQUESTED,
                stageNr: 2,
                color: primary,
            },
            {
                status: "VALIDATED",
                label: ASSIGNMENTS.LABELS.VALIDATED,
                stageNr: 2,
                color: primary,
            },
        ],

        [
            {
                status: "DRAFT_REVIEW_REQUESTED",
                label: ASSIGNMENTS.LABELS.DRAFT_REVIEW_REQUESTED,
                stageNr: 3,
                color: primary,
            },
        ],

        [
            {
                status: "DRAFT_REPORT_CREATED",
                label: ASSIGNMENTS.LABELS.DRAFT_REPORT_CREATED,
                stageNr: 4,
                color: primary,
            },
            {
                status: "DRAFT_REVIEWED",
                label: ASSIGNMENTS.LABELS.DRAFT_REVIEWED,
                stageNr: 4,
                color: primary,
            },
            {
                status: "DRAFT_READY_TO_PUBLISH",
                label: ASSIGNMENTS.LABELS.DRAFT_READY_TO_PUBLISH,
                stageNr: 4,
                color: primary,
            },
        ],

        [
            {
                status: "DRAFT_REPORT_PUBLISHED",
                label: ASSIGNMENTS.LABELS.DRAFT_REPORT_PUBLISHED,
                stageNr: 5,
                color: primary,
            },
            {
                status: "REACTION_RECEIVED",
                label: ASSIGNMENTS.LABELS.REACTION_RECEIVED,
                stageNr: 5,
                color: primary,
            },
            {
                status: "REACTION_PROCESSED",
                label: ASSIGNMENTS.LABELS.REACTION_PROCESSED,
                stageNr: 5,
                color: primary,
            },
            {
                status: "FINAL_REVIEW_REQUESTED",
                label: ASSIGNMENTS.LABELS.FINAL_REVIEW_REQUESTED,
                stageNr: 5,
                color: primary,
            },
            {
                status: "REOPENED",
                label: ASSIGNMENTS.LABELS.REOPENED,
                stageNr: 5,
                color: primary,
            },
        ],

        [
            {
                status: "FINAL_REPORT_CREATED",
                label: ASSIGNMENTS.LABELS.FINAL_REPORT_CREATED,
                stageNr: 6,
                color: primary,
            },
            {
                status: "FINAL_REVIEWED",
                label: ASSIGNMENTS.LABELS.FINAL_REVIEWED,
                stageNr: 6,
                color: primary,
            },
            {
                status: "FINAL_REVIEW_REQUESTED",
                label: ASSIGNMENTS.LABELS.FINAL_REVIEW_REQUESTED,
                stageNr: 6,
                color: primary,
            },
            {
                status: "FINAL_READY_TO_PUBLISH",
                label: ASSIGNMENTS.LABELS.FINAL_READY_TO_PUBLISH,
                stageNr: 6,
                color: primary,
            },
        ],

        [
            {
                status: "FINAL_REPORT_PUBLISHED",
                label: ASSIGNMENTS.LABELS.FINAL_REPORT_PUBLISHED,
                stageNr: 7,
                color: primary,
            },
        ],

        [
            {
                status: "CLOSED",
                label: ASSIGNMENTS.LABELS.CLOSED,
                stageNr: 8,
                color: primary,
            },
            {
                status: "CANCELLED",
                label: ASSIGNMENTS.LABELS.CANCELLED,
                stageNr: -1,
                color: primary,
            },
        ],
    ],

    REQUESTS: [
        [
            {
                status: REQUESTS.STATUSES.CREATED,
                label: REQUESTS.LABELS.CREATED,
                stageNr: 0,
                color: primary,
            },
            {
                status: REQUESTS.STATUSES.CANCELLED,
                label: REQUESTS.LABELS.CANCELLED,
                stageNr: 0,
                color: primary,
            },
            {
                status: REQUESTS.STATUSES.CLOSED,
                label: REQUESTS.LABELS.CLOSED,
                stageNr: 2,
                color: secondary,
            },
        ],
        [
            {
                status: REQUESTS.STATUSES.VALIDATED,
                label: REQUESTS.LABELS.VALIDATED,
                stageNr: 1,
                color: primary,
            },
            {
                status: REQUESTS.STATUSES.VALIDATION_REQUESTED,
                label: REQUESTS.LABELS.VALIDATION_REQUESTED,
                stageNr: 1,
                color: primary,
            },
        ],
        [
            {
                status: REQUESTS.STATUSES.INCLUDED_IN_INSPECTION,
                label: REQUESTS.LABELS.INCLUDED_IN_INSPECTION,
                stageNr: 2,
                color: primary,
            },
        ],
    ],
};
