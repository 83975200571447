// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getDisclosureLevelsParamsAction } from "./get-disclosure-levels-params";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteDisclosureLevelsParams",
    ({ id }) => `/disclosure-levels/${id}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError(
            "Parameters publicatie / actieve openbaarheid sturen verwijderen mislukt.",
        ),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const deleteDisclosureLevelsParamsAction = (id: string) => (
    dispatch: *,
) =>
    dispatch(load({ path: { id } }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message:
                    "Parameters publicatie / actieve openbaarheid sturen verwijderd.",
            }),
        );
        return dispatch(getDisclosureLevelsParamsAction(undefined, true));
    });
