// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getReportsForAssignment } from "./get-reports-for-assignment";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "markReportAsReady",
    ({ inspectionId, type }) =>
        type === "DRAFT"
            ? `/inspections/${inspectionId}/draft-ready-to-publish`
            : `/inspections/${inspectionId}/final-ready-to-publish`,

    {
        method: "PUT",
        errorHandler: notifyApiError(
            "Het klaarmaken voor verzending van het verslag is mislukt.",
        ),
    },
);

export const markReportAsReady = (inspectionId: string, type: string) => (
    dispatch: *,
) => {
    const report = type === "DRAFT" ? "ontwerp verslag" : "definitief verslag";
    const overrideErrorMessage = `Het klaarmaken voor verzending van het ${report} is mislukt.`;

    return dispatch(
        load(
            {
                path: { inspectionId, type },
            },
            true,
            overrideErrorMessage,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: `Het ${report} is klaar om te versturen`,
                }),
            );
            dispatch(loadInspection(inspectionId));
            dispatch(getReportsForAssignment(inspectionId));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
