export const ERRORS = {
    GKB_UNKNOWN_INSPECTION_POINT_ERROR: "Inspectiepunt niet gekend in GKB",
    JWT_VERIFICATION_FAILED: "Authenticatietoken kon niet geverifieerd worden",
    NO_JWT_RECEIVED: "Authenticatietoken ontbreekt",
    PARENT_MODULE_ID_NOT_FOUND:
        "Overkoepelende module kon niet gevonden worden",
    PARENT_MODULE_ID_NOT_PROVIDED: "ID van overkoepelende module ontbreekt",
    REFERENCED_IDS_NOT_FOUND:
        "Gegevens (ids) waarnaar verwezen werd konden niet gevonden worden", // TBD
    REST_CALL_FAILED: "Data ophalen gefaald", // TBD
    ACCESS_DENIED: "Toegang geweigerd",
    CONNECTION_REFUSED: "Verbinding geweigerd",
    ILLEGAL_ARGUMENT: "Foutief argument",
    ILLEGAL_STATE: "Ongeldige status", // TBD
    INVALID_STATUS_CHANGE: "Ongeldige statusovergang",
    MEDIA_TYPE_UNSUPPORTED: "Bestandstype wordt niet ondersteund",
    MESSAGE_NOT_READABLE: "Bericht niet leesbaar",
    MISSING_PARAMETER: "Ontbrekende parameter",
    RESOURCE_NOT_FOUND: "Gegevens niet gevonden",
    UNKNOWN_EXCEPTION: "Onbekende fout",
    VALIDATION_FAILED: "Validatie gefaald",
    NO_MODULES_SUBMITTED: "Geen modules ingediend",
    CONTACT_EMAIL_OR_DESCRIPTION_REQUIRED:
        "Email of beschrijving van contactpersoon vereist",
    CONTACT_TYPE_REQUIRED: "Contactpersoon type vereist",
    DEADLINE_EXTENSION_ALREADY_REQUESTED: "Uitstel reeds aangevraagd",
    DEADLINE_EXTENSION_NOT_ALLOWED_FOR_STATE:
        "Uitstel aanvragen niet toegelaten in deze status",
    DEADLINE_IS_NULL: "Deadline ontbreekt",
    DELETING_AUTO_GENERATED_REPORTS_NOT_ALLOWED:
        "Verwijderen van gegenereerde rapporten niet toegelaten",
    DELETING_INTERNAL_NOTES_NOT_ALLOWED:
        "Interne communicatie verwijderen niet toegestaan",
    FORM_MODULES_MODIFIED: "Formuliermodules werden aangepast",
    INSPECTION_REQUEST_ALREADY_LINKED:
        "Aanvraag reeds gekoppeld aan een opdracht",
    INSPECTION_REQUEST_NOT_LINKED_TO_INSPECTION:
        "Opgegeven aanvraag is niet gekoppeld aan een opdracht",
    INSPECTION_REQUEST_NOT_YET_LINKED:
        "Aanvraag is nog niet gekoppeld aan een opdracht",
    LAST_OBSERVATION_SHOULD_BE_AFTER_LAST_VISIT:
        "Datum laatste observatie moet na het laatste bezoekmoment liggen",
    LINKING_REQUEST_NOT_ALLOWED_FOR_STATE:
        "Aanvraag koppelen niet toegelaten in deze status",
    MAIN_IP_CAN_NOT_BE_DELETED: "Hoofdinspectiepunt kan niet verwijderd worden",
    MODULE_INSTANCE_GROUP_NOT_PART_OF_VISIT:
        "Deze module groep is geen deel van het bezoek",
    NO_DEADLINE_EXTENSION_REQUESTED: "Geen uitstel van de deadline aangevraagd",
    NO_RECIPIENTS_FOUND: "Geen ontvangers gevonden",
    PRECONDITIONS_FOR_REPORT_GENERATION_NOT_MET:
        "Niet voldaan aan de voorwaarden voor rapportgeneratie",
    PUBLICATION_OF_DRAFT_REPORTS_NOT_ALLOWED:
        "Publiceren van ontwerpverslagen is niet toegelaten",
    REACTION_DEADLINE_ALREADY_EXTENDED: "Reactietermijn is reeds verlengd",
    REACTION_DEADLINE_EXPIRED: "Reactietermijn is verlopen",
    REACTION_DEADLINE_NOT_SET: "Geen deadline ingesteld voor reactietermijn",
    REACTIONS_NOT_ALLOWED: "Reacties toevoegen niet toegelaten",
    REASSIGN_REVIEWER_NOT_ALLOWED_FOR_STATE:
        "Wijzigen van reviewer niet toegelaten in deze status",
    REPLACING_DRAFT_REPORT_NOT_ALLOWED_FOR_STATE:
        "Vervangen ontwerpverslag niet toegelaten in deze status",
    REPLACING_FINAL_REPORT_NOT_ALLOWED_FOR_STATE:
        "Vervangen definitief verslag niet toegelaten in deze status",
    UPDATE_NOT_ALLOWED_FOR_STATE: "Aanpassing niet toegelaten in deze status",
    UPDATE_ASSIGNEE_NOT_ALLOWED_WHEN_LINKED:
        "Aanpassen toegewezen persoon niet toegelaten wanneer gelinkt",
    UPLOADING_DRAFT_REPORT_NOT_ALLOWED:
        "Uploaden ontwerpverslag niet toegestaan",
    UPLOADING_FINAL_REPORT_NOT_ALLOWED:
        "Uploaden definitief verslag niet toegestaan",
    VALIDATION_OF_REQUEST_NOT_ALLOWED_FOR_STATE:
        "Aanvraag valideren niet toegelaten in deze status",
    VISIT_PERIODS_ALREADY_EXIST: "Bezoekmoment bestaat reeds",
    VISIT_PERIODS_OVERLAP: "Bezoekmomenten overlappen",
    CAN_NOT_LINK_IPS_OF_DIFFERENT_TYPES:
        "Inspectiepunten van verschillende types kunnen niet gelinkt worden",
    CAN_NOT_UPDATE_EXTERNAL_IP:
        "Extern inspectiepunt kon niet geüpdatet worden",
    SOURCE_IP_SHOULD_BE_OF_TYPE_MODULAR:
        "Broninspectiepunt moet reeds bestaan in modular",
    TARGET_IP_CAN_NOT_BE_OF_TYPE_MODULAR:
        "Doelinspectiepunt mag nog niet bestaan in modular",
    REPERTORIUM_ERROR: "Repertorium fout",
    REPERTORIUM_UNKNOWN_ERROR: "Onbekende fout in repertorium",
    VAPH_EXTERNAL_ID_NOT_KNOWN: "Externe ID niet gevonden in VAPH",
    VAPH_RESPONSE_MISSING_DATA: "Data ontbreekt in VAPH respons",
    VAPH_UNKNOWN_ERROR: "Onbekende fout in VAPH",
    VAPH_NO_DOSSIER_FOR_RRN:
        "Geen data voor persoon met dit gegeven rijksregisternummer beschikbaar bij VAPH",
    INVALID_OR_UNKNOWN_RRN: "Ongeldig rijksregisternummer",
    EXTERNAL_IP_ALREADY_LINKED:
        "Er is al een inspectiepunt gekoppeld aan dit extern inspectiepunt",
    UPDATE_DISCLOSURE_LEVEL_NOT_ALLOWED:
        "Aanpassing naar minder strikte openbaarheid niet toegestaan",
};
