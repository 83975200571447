//@flow
import { MIME_FORMATS } from "@constants";

export const isDefined = (thing: any): boolean %checks =>
    thing !== undefined && thing !== null;

// Convert a string to Pascalcase
export const toPascalCase = (str: string) => {
    return str
        .replace(/(\w)(\w*)/g, (match, firstChar, restOfWord) => {
            return firstChar.toUpperCase() + restOfWord.toLowerCase();
        })
        .replace(/\s+/g, "");
};

/**
 * Returns the file extension of a given filename.
 * @param {string} filename - The name of the file.
 * @returns {string} - The file extension.
 */
export const getFileExtension = (filename: string): string => {
    const dotIndex = filename.lastIndexOf(".");
    if (dotIndex === -1) {
        return "";
    }
    return filename.slice(dotIndex);
};

/**
 * Retrieves the MIME format based on the given file extension.
 * @param {string} extension - The file extension.
 * @returns {string} The corresponding MIME format.
 */
export const getMIMEFormat = (extension: string) => {
    for (const [mime, extensions] of Object.entries(MIME_FORMATS)) {
        if (Array.isArray(extensions) && extensions.includes(extension)) {
            return mime;
        }
    }
    return "application/octet-stream";
};

/**
 *Returns an object with only the keys to keep
 * @param {Object} obj - The object to filter.
 * @param {Array<string>} keysToKeep - The array with keys to keep as string.
 * @returns {Object} The filtered object
 */
export const filterObject = (
    obj: Object,
    keysToKeep: Array<string>,
): Object => {
    const filteredObject = {};

    keysToKeep.forEach((key: string) => {
        if (obj.hasOwnProperty(key)) {
            filteredObject[key] = obj[key];
        }
    });

    return filteredObject;
};

//Check if a table has filters active, exclude the keysToKeep.
export const hasTableFilters = (tableFilters: *, keysToKeep: *) => {
    let currentTableFilters = tableFilters || {};
    let filteredObject = Object.keys(currentTableFilters)
        .filter(key => !keysToKeep.includes(key))
        .reduce((result, key) => {
            result[key] = currentTableFilters[key];
            return result;
        }, {});
    return Object.keys(filteredObject).length === 0 ? false : true;
};
