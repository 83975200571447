// @flow

const definition = [
    {
        label: "Bericht",
        id: "messsage",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },

    {
        label: "Wanneer",
        id: "cretedAt",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Wie",
        id: "reporterVersion",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
];

export default definition;
