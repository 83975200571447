// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getCustomQueries } from "./get-custom-queries";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateCustomQuery",
    ({ id }) => `/custom-queries/${id}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Gecombineerde filter aanpassen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const updateCustomQueryAction = (data: *) => (dispatch: *) => {
    dispatch(clear());
    return dispatch(load({ path: { id: data.id }, data }, true)).then(
        response => {
            if (!response || response.type !== constants.SUCCESS) return;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Gecombineerde filter aangepast",
                }),
            );
            dispatch(getCustomQueries(undefined, true));
        },
    );
};
