// @flow
const definition = [
    {
        label: "INSPECTIEPUNT",
        id: "Inspectiepunt",
        isSortable: false,
        isFilterable: true,
        type: "text",
        icon: "MYLOCATION",
    },

    {
        label: "ADRES",
        id: "adres",
        isSortable: false,
        isFilterable: true,
        type: "text",
        icon: "LOCATION",
    },
    {
        label: "POSTCODE/PLAATS",
        id: "city",
        isSortable: false,
        isFilterable: true,
        type: "text",
        icon: "POST",
    },
    {
        label: "ZORGVOORZIENINGSTYPE",
        id: "zorgactiviteitstype",
        isSortable: false,
        isFilterable: true,
        type: "text",
        icon: "CATEGORY",
    },
    {
        label: "ACTIES",
        id: "actions",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
