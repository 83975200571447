// @flow

const definition = [
    {
        label: "",
        id: "versionNumber",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Versie naam",
        id: "name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Status",
        id: "status",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Activatie datum",
        id: "activationDate",
        isSortable: false,
        isFilterable: false,
        type: "date",
    },
    {
        label: "Deactivatie datum",
        id: "deActivationDate",
        isSortable: false,
        isFilterable: false,
        type: "date",
    },
    {
        label: "Opmerkingen",
        id: "comments",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Acties",
        id: "actions",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
