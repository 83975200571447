// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "getPersonInfo",
    ({ inspectionPointId }) => `/inspectionpoints/${inspectionPointId}/vaph`,

    {
        method: "GET",
        errorHandler: notifyApiError("Persoonsinformatie ophalen mislukt"),
    },
);

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
