// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteReason",
    ({ inspectionId, selectedReasonId }) =>
        `/inspections/${inspectionId}/reasons/${selectedReasonId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Reden verwijderen mislukt"),
    },
);

export const deleteReason = (inspectionId: string, selectedReasonId: *) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { inspectionId, selectedReasonId } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Reden is succesvol verwijderd",
                }),
            );

            return dispatch(loadInspection(inspectionId));
        }
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
