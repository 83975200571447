// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as loadReportTitles } from "./report-titles";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "addReportTitle",
    () => `/report-titles`,
    {
        method: "POST",
        errorHandler: notifyApiError("Verslagtitel toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const addReportTitleAction = (reportTitle: string) => (dispatch: *) => {
    return dispatch(load({ data: { reportTitle } }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Verslagtitel is opgeslagen.",
            }),
        );
        dispatch(loadReportTitles(undefined, true));
    });
};
