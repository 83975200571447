// @flow
const definition = [
    {
        label: "Naam",
        id: "name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Beschrijving",
        id: "description",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Begindatum",
        id: "startDate",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Einddatum",
        id: "endDate",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Module/submodule",
        id: "module",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Acties",
        id: "actions",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
