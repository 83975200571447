// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadNotes } from "./notes";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteNote",
    ({ inspectionId, noteId }) =>
        `/inspections/${inspectionId}/notes/${noteId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Nota verwijderen mislukt"),
    },
);

export const deleteNote = (inspectionId: string, noteId: string) => (
    dispatch: *,
) => {
    return dispatch(
        load({
            path: { inspectionId, noteId },
        }),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Nota is succesvol verwijderd",
                }),
            );
            dispatch(
                loadNotes({ path: { inspectionId, internal: false } }, true),
            );
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
