// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { update as updateFiles } from "./uploader-value";

import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "uploadDocuments",
    () => `/documents`,
    {
        method: "POST",
        errorHandler: notifyApiError("Document uploaden mislukt"),
    },
);

/**
 * Upload documents one by one
 */
export const uploadDocuments = (acceptedFiles?: Array<*>) => (
    dispatch: *,
    getState: *,
) => {
    const { uploaderValue } = getState();

    let files = [];
    if (acceptedFiles) {
        files = acceptedFiles;
        updateFiles({
            toUpload: files,
        });
    } else {
        files = uploaderValue.toUpload;
    }

    if (files && files.length > 0) {
        const file = files[0];
        // necessary to use the name and size to render it in FR
        const config = {
            name: file.name,
            size: file.size,
        };
        var formData = new FormData();
        formData.append("file", file);

        return dispatch(
            load(
                {
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
                true,
            ),
        ).then((response: *) => {
            if (response && response.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: `Document uploaden is gelukt`,
                    }),
                );
                // Remove the first element of the array
                files.shift();
                dispatch(
                    updateFiles({
                        uploaded: [
                            ...uploaderValue.uploaded,
                            {
                                uuid: response.payload.uuid,
                                name: config.name,
                                size: config.size,
                            },
                        ],
                        toUpload: files,
                    }),
                );

                if (files.length > 0) {
                    return dispatch(uploadDocuments());
                } else {
                    return getState().uploaderValue;
                }
            }
        });
    }
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
// reducer
export default reducer;

export const constants = _constants;
