// @flow
import React from "react";
import { REQUESTS } from "@constants";
import type { Definition } from "@types";

/*
 *   not-linked requests
 */
export const requests: Definition = [
    {
        label: "",
        id: "checkbox",
        isSortable: false,
        isFilterable: false,
        type: "text",
        dense: true,
    },
    {
        label: "Aanvraagnummer",
        id: "reference",
        sortName: "reference",
        isSortable: true,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Opdracht",
        id: "inspectionReference",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Inspectiepunt",
        id: "inspection-point",
        sortName: "mainInspectionPointName",
        isSortable: true,
        isFilterable: true,
        type: "inspectionPoint",
    },
    {
        label: "Hoofd-IP-nr",
        id: "external-id",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        isSortable: false,
        isFilterable: true,
        type: "groups",
    },
    {
        label: "Reden",
        id: "reason-id",
        isSortable: false,
        isFilterable: true,
        type: "reason",
    },
    {
        label: "Status",
        id: "status",
        isSortable: false,
        isFilterable: true,
        type: "text",
        options: Object.keys(REQUESTS.LABELS).map<*>((key, index) => ({
            label: REQUESTS.LABELS[key],
            value: key,
            index,
        })),
    },
    {
        label: "Uitvoeringsdatum",
        id: "requested-date-range",
        sortName: "requestedInspectionDateBefore",
        isSortable: true,
        isFilterable: true,
        type: "date-range",
    },
    {
        label: "Aanmaakdatum",
        id: "inspectionRequestCreatedOn",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Toegewezen aan",
        id: "assignee-id",
        isSortable: false,
        isFilterable: true,
        type: "person",
    },
    {
        label: "Referentie opdrachtgever",
        id: "external-reference",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Aanvrager",
        id: "fe-contact-id",
        isSortable: false,
        isFilterable: true,
        type: "feUser",
    },
];

/*
 *   not-linked requests for external user
 */
export const requestsForExternal: Definition = [
    {
        label: "Aanvraagnummer",
        id: "reference",
        sortName: "reference",
        isSortable: true,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Opdracht",
        id: "inspectionReference",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Inspectiepunt",
        id: "inspection-point",
        sortName: "mainInspectionPointName",
        isSortable: true,
        isFilterable: true,
        type: "inspectionPoint",
    },
    {
        label: "Hoofd-IP-nr",
        id: "external-id",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        isSortable: false,
        isFilterable: true,
        type: "groups",
    },
    {
        label: "Reden",
        id: "reason-id",
        isSortable: false,
        isFilterable: true,
        type: "reason",
    },
    {
        label: "Status",
        id: "status",
        isSortable: false,
        isFilterable: true,
        type: "text",
        options: Object.keys(REQUESTS.LABELS)
            .filter(key => key !== "CANCELLED" && key !== "CLOSED")
            .map<*>((key, index) => ({
                label: REQUESTS.LABELS[key],
                value: key,
                index,
            })),
    },
    {
        label: "Uitvoeringsdatum",
        id: "requestedInspectionDate",
        sortName: "requestedInspectionDateBefore",
        isSortable: true,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Aanmaakdatum",
        id: "inspectionRequestCreatedOn",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Mijn referentie",
        id: "external-reference",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Aanvrager",
        id: "fe-contact-id",
        isSortable: false,
        isFilterable: true,
        type: "feUser",
    },
];

/*
 *   linked requests, embedded table
 */
export const linkedRequests: Definition = [
    {
        label: "Reden",
        id: "reason-id",
        isSortable: false,
        isFilterable: false,
        type: "reason",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        isSortable: false,
        isFilterable: false,
        type: "groups",
    },
    {
        label: "Referentie opdrachtgever",
        id: "external-reference",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Uitvoeringsdatum",
        id: "requestedInspectionDate",
        sortName: "requestedInspectionDateBefore",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Aanmaakdatum",
        id: "inspectionRequestCreatedOn",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Aanvraagnummer",
        id: "reference",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Actie",
        id: "actions",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];
