// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { refetch as refetchFeUsers } from "./advanced-search-fe-users";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateFeUsers",
    ({ personId }) => `/fe-users/${personId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Persoon aanpassen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const updateFeUsers = (personId: string, data: *) => (dispatch: *) => {
    return dispatch(load({ path: { personId }, data }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Persoon aangepast",
            }),
        );
        return dispatch(refetchFeUsers());
    });
};
