//@flow
import { convertDateForAPI } from "../date";
import { isArrayWithContent } from "../arrays";
import moment from "moment-timezone";

/*
 *   Turn an object of shape { [string]: string | string[] | number |  number[]} strings, string arrays, numbers or number arrays for values into a querystring
 *
 *   How it works:
 *      The keys of the object need to correspond to the names of the queryparams.
 *      the whole object will then be turned into a querystring with escaped values using encodeURIComponent.
 *      There is also an optional flag to indicate that the querystring you need is starting at
 *      the beginning, i.e. there are no other queryparams before it.
 *
 *      e.g. { "inspection-point-name": "piraatjes", status: ["CREATED", "PREPARED"] }
 *      => "&inspection-point-name=piraatjes&status=CREATED,PREPARED"
 *      or with flag => "?inspection-point-name=piraatjes&status=CREATED,PREPARED"
 *
 *      It's odd to make object keys like that, but its valid javascript.
 *      (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Working_with_Objects#Objects_and_properties)
 *
 *      ! WARNING : do not revert to using encodeURI() instead of encodeURIComponent()
 *        Reason: several characters, such as `#` are valid parts of an url but with specific functions
 *                (see: https://developer.mozilla.org/en-US/docs/Web/API/URL)
 *                e.g. `#` is a fragment identifier, everything after that is not available to server
 *        encodeURIComponent will also escape all of these, so they become available to the server
 **/
type QueryObject = {
    [key: string]: string | number | Array<number | string>,
};
export const constructQueryString = (
    object: QueryObject,
    startFromScratch?: boolean,
) => {
    if (!object) return "";
    return Object.keys(object).reduce((queryString, key, index) => {
        const value = object[key];
        if (typeof value === "string" || typeof value === "number") {
            return startFromScratch && (index === 0 || queryString === "")
                ? `?${key}=${encodeURIComponent("" + value)}`
                : queryString + `&${key}=${encodeURIComponent("" + value)}`;
        } else if (isArrayWithContent(value)) {
            const queryChain = value
                .map(el => `${key}=${encodeURIComponent("" + el)}`)
                .join("&");

            const queryStart =
                startFromScratch && (index === 0 || queryString === "")
                    ? "?"
                    : "&";

            const listString = queryStart.concat(queryChain);
            return queryString + listString;
        }
        return queryString;
    }, "");
};

/*
 *   For use in creating the query object out of the table filters objects
 */
export const mapCompoundKeysOntoQuery = (
    query: *,
    filterObject: *,
    compoundKeys: string[],
    noTime?: boolean = false,
) => {
    compoundKeys.forEach(key => {
        if (!filterObject[key]) return;

        delete query[key];
        Object.keys(filterObject[key]).forEach(subKey => {
            query[`${key}-${subKey}`] = moment.isMoment(
                filterObject[key][subKey],
            )
                ? noTime
                    ? convertDateForAPI(filterObject[key][subKey])
                    : filterObject[key][subKey].toISOString(true)
                : filterObject[key][subKey];
        });
    });
    return query;
};

const compoundKeysMap = {
    assignments: [
        "inspection-point",
        "target-date",
        "last-visit-done",
        "last-report-sent",
    ],
    requests: ["inspection-point"],
    advancedSearch: [
        "created",
        "last-observation",
        "last-visit-done",
        "draft-report-sent",
        "final-report-sent",
        "closed",
    ],
    regionDistribution: ["authority", "inspection-point"],
    communications: ["recipient", "subject", "sentAt"],
};
export const buildQueryForTableFilters = (filters: *, view: string) => {
    if (!filters) return undefined;
    if (!view)
        throw new ReferenceError(
            "Need view identifiacation to parse compound keys",
        );
    const compoundKeys = compoundKeysMap[view] || [];

    let query = { ...filters?.tableFilters };

    const hasTableFilter = (key: string) =>
        !!filters?.tableFilters && !!filters.tableFilters[key];

    if (view === "advancedSearch" && hasTableFilter("inspection-point")) {
        //map query params
        query["inspection-point-name"] = query["inspection-point"].name;
        query["street-name"] = query["inspection-point"].street;
        query["zipcode"] = query["inspection-point"].zipcode;
        query["city"] = query["inspection-point"].city;
        delete query["inspection-point"];
    }
    if (hasTableFilter("person-id"))
        query["person-id"] = filters.tableFilters["person-id"].map(
            person => person.id,
        );

    if (hasTableFilter("assignee-id"))
        query["assignee-id"] = filters.tableFilters["assignee-id"].map(
            person => person.id,
        );

    if (hasTableFilter("inspector-id"))
        query["inspector-id"] = filters.tableFilters["inspector-id"].map(
            person => person.id,
        );

    if (hasTableFilter("lead-inspector-id"))
        query["lead-inspector-id"] = filters.tableFilters[
            "lead-inspector-id"
        ].map(person => person.id);

    if (hasTableFilter("groups")) {
        delete query.groups;
        Object.keys(filters.tableFilters.groups).forEach(
            key => (query[key] = filters.tableFilters.groups[key]),
        );
    }

    if (hasTableFilter("requested-date")) {
        delete query["requested-date"];
        const { before, after } = filters.tableFilters["requested-date"];
        query["requested-after-date-from"] = convertDateForAPI(after);
        query["requested-before-date-until"] = convertDateForAPI(before);
    }
    if (hasTableFilter("requested-date-range")) {
        delete query["requested-date-range"];
        const {
            beforeFrom,
            beforeUntil,
            afterFrom,
            afterUntil,
        } = filters.tableFilters["requested-date-range"];
        query["requested-before-date-from"] = convertDateForAPI(beforeFrom);
        query["requested-before-date-until"] = convertDateForAPI(beforeUntil);
        query["requested-after-date-from"] = convertDateForAPI(afterFrom);
        query["requested-after-date-until"] = convertDateForAPI(afterUntil);
    }

    if (
        isArrayWithContent(compoundKeys) &&
        filters.tableFilters &&
        view !== "communications"
    )
        query = mapCompoundKeysOntoQuery(
            query,
            filters.tableFilters,
            compoundKeys,
            view === "advancedSearch",
        );
    if (filters?.teams) query = { ...query, ["team-id"]: filters.teams };

    return query;
};
