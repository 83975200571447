// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { loadInspection } from "./inspection";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "closeInspection",
    ({ inspectionId }) => `/inspections/${inspectionId}/conclusions`,
    {
        method: "POST",
        errorHandler: notifyApiError("Opdracht afwerken mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const closeInspection = (
    inspectionId: string,
    data: string = "MODULAR",
) => (dispatch: *) => {
    return dispatch(
        load(
            {
                path: { inspectionId },
                data: { reportDistributionMode: data },
            },
            true,
        ),
    ).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Opdracht is afgewerkt",
            }),
        );
        return dispatch(loadInspection(inspectionId));
    });
};
