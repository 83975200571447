// @flow
import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "notes",
    ({ inspectionId, internal }) =>
        `/inspections/${inspectionId}/notes?internal=${internal}`,
    {
        method: "GET",
        errorHandler: notifyApiError("Berichten ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
