const REDIRECTIONS = {
    INSPECTION_DETAILS: {
        message: "Geen inspectie geselecteerd",
        label: "Naar Team opdrachten pagina",
        redirectTo: "/opdrachten/opdrachten-team/",
        id: "inspection_details",
    },
    ASSIGNMENTS: {
        message: "U hebt nog geen opdracht geselecteerd",
        label: "Naar Team opdrachten pagina",
        redirectTo: "/opdrachten/opdrachten-team/",
        id: "assignments",
    },
    ASSIGNMENTS_FORM: {
        message: "U hebt nog geen opdracht geselecteerd",
        label: "Naar Team opdrachten pagina",
        redirectTo: "/opdrachten/opdrachten-team/",
        id: "assignments",
    },
    SHORTCOMINGS: {
        message: "U hebt nog geen Inspectiepunt geselecteerd",
        label: "Naar Inspectiepunten overzicht pagina",
        redirectTo: "/inspectie/zoek-inspectiepunt/",
        id: "shortcomings",
    },
    REACTIONS: {
        message: "U hebt nog geen afgewerkte opdracht geselecteerd",
        label: "Naar Team opdrachten pagina",
        redirectTo: "/opdrachten/opdrachten-team/",
        id: "reactions",
    },
    REASONS: {
        INSPECTIE: {
            message: "U hebt nog geen inspectiepunt geselecteerd",
            label: "Naar Inspectiepunt kiezen pagina",
            redirectTo: "/inspectie/zoek-inspectiepunt/",
            id: "inspection-reasons",
        },
        AANVRAGEN: {
            message: "U heeft nog geen inspectiepunt gekozen",
            label: "Naar Inspectiepunt kiezen pagina",
            redirectTo: "/aanvragen/zoek-inspectiepunt/",
            id: "new-request-reasons",
        },
    },
    INSPECTIONPOINT_DOSSIER: {
        message: "U hebt nog geen inspectiepunt geselecteerd",
        label: "Naar Inspectiepunt kiezen pagina",
        redirectTo: "/inspectie/zoek-inspectiepunt/",
        id: "inspectionpoint_dossier",
    },
    DUMMY_ASSIGNMENT: {
        message: "Deze pagina bestaat nog niet",
        label: "Naar Team opdrachten pagina",
        redirectTo: "/opdrachten/opdrachten-team/",
        id: "assignment_dummy_page",
    },
};

export default REDIRECTIONS;
