// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { constructQueryString } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "getDisclosureLevelsParams",

    ({ query }) => {
        const base = "/disclosure-levels";
        return query ? base + constructQueryString(query, true) : base;
    },
    {
        method: "GET",
        errorHandler: notifyApiError(
            "Parameters publicatie / actieve openbaarheid sturen ophalen mislukt.",
        ),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
export const cancel = actions.cancel;

export const constants = _constants;
