// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadWatchers } from "@stores/get-watchers";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteWatcher",
    ({ inspectionId }) => `/inspections/${inspectionId}/watchers/`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Volger verwijderen mislukt"),
    },
);

/*
 *   execute call to delete a watcher
 */
export const deleteWatcher = (inspectionId: string, personId: string) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { inspectionId }, data: { personId } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Volger verwijderd",
                }),
            );
            dispatch(loadWatchers({ path: { inspectionId } }, true));
        }
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
