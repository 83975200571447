export const VALIDATION = {
    REQUIRED: "Verplicht veld",
    STREET: "Ongeldige straatnaam",
    STREETNUMBER: "Ongeldig huisnummer",
    ZIPCODE: "Ongeldige postcode",
    CITY: "Ongeldige gemeente",
    PHONE_NUMBER: "Ongeldig telefoonnummer",
    EMAIL: "Ongeldig email formaat",
    TOO_LONG: "Invoer is te lang",
    INVALID_DATE: "Onvolledige of ongeldige datum",
    INVALID_TIME: "Onvolledig of ongeldig tijdstip",
    MIN_CHARACTERS: "Minimum ${min} karakters",
    MAX_CHARACTERS: "Maximum ${max} karakters",
    EMAIL_OR_ADDRESS: "Vul ofwel een volledig adres of geldig e-mail in",
    ADDRESS_COMPLETE: "Vul ofwel alle adresvelden in of geen enkel adresveld",
    VALID_BEFORE_AFTER_DATE:
        "Geef minimaal een geldige tegen of vanaf datum in",
    NOT_BEFORE_START_DATE: "De datum mag niet voor de startdatum liggen",
    NOT_IN_FUTURE: "De datum mag niet in de toekomst liggen",
    NOT_IN_PAST: "De datum mag niet in het verleden liggen",
};
export const REGEX = {
    EMAIL: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|$]/gm,
};
