// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "inspectionPointsModular",
    () => `/inspectionpoints/queries`,
    {
        method: "POST",
        errorHandler: notifyApiError("Modular Inspectiepunten ophalen mislukt"),
    },
);

export const loadModularInspectionPoints = (requestedFilters: *) => (
    dispatch: *,
) => {
    return dispatch(
        load({ data: { ...requestedFilters, modularOnly: true } }, true),
    ).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        //send totalElements back to keep the panel expanded if the response is empty
        return response.payload?.totalElements
            ? response.payload.totalElements
            : response.payload;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
