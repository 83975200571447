// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { set } from "./reactions";

const { constants: _constants, reducer, actions } = createApiModule(
    "getFunctionalEntities",
    () => `/functional-entities`,

    {
        method: "GET",
        errorHandler: notifyApiError(
            "Lijst Functionele Entiteiten ophalen mislukt",
        ),
    },
);

export const loadFunctionalEntitiesList = () => (dispatch: *, getState: *) => {
    return dispatch(load({}, true)).then(response => {
        if (response && response.type === constants.SUCCESS) {
            const functionalEntitiesList = [
                ...getState().getFunctionalEntities.data,
            ];
            return dispatch(set(functionalEntitiesList));
        }
        return false;
    });
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
