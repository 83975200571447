// @flow

/**
 * Removes leading
 */
export const stripLeading = (path: string) =>
    path.startsWith("/") ? path.slice(1) : path;

/**
 * Removes trailing
 */
export const stripTrailing = (path: string) =>
    path.endsWith("/") ? path.slice(0, -1) : path;

/**
 * Strips slashes from a path
 */
export const strip = (path: string) => stripLeading(stripTrailing(path));
