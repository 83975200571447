// @flow
import createApiModule from "../create-api-module";
import { update as notify } from "./notifications";
import { update as updateFiles } from "./uploader-value";
import { isArrayWithContent } from "@utils";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "uploadBulkAssignment",
    () => `/inspection-requests/bulk`,
    {
        method: "POST",
        errorHandler: undefined,
    },
);

export const uploadBulkAssignment = (acceptedFiles?: Array<*>) => (
    dispatch: *,
    getState: *,
) => {
    const { uploaderValue } = getState();

    let filesToUpload = [];
    if (acceptedFiles) {
        filesToUpload = acceptedFiles;
        updateFiles({
            toUpload: filesToUpload,
        });
    } else {
        filesToUpload = uploaderValue.toUpload;
    }

    if (filesToUpload && filesToUpload.length > 0) {
        const file = filesToUpload[0];
        // necessary to use the name and size to render it in FR
        const config = {
            size: file.size,
            name: file.name,
        };
        var formData = new FormData();
        formData.append("file", file);

        return dispatch(
            load(
                {
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
                true,
            ),
        ).then((response: *) => {
            if (response) {
                if (
                    response.type === constants.SUCCESS &&
                    !isArrayWithContent(response.payload.errors)
                ) {
                    dispatch(
                        notify({
                            severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                            message: `Opdracht met succes geupload`,
                        }),
                    );
                    // Remove the first element of the array
                    filesToUpload.shift();
                    dispatch(
                        updateFiles({
                            uploaded: [
                                ...uploaderValue.uploaded,
                                {
                                    uuid: response.payload.uuid,
                                    name: config.name,
                                    size: config.size,
                                },
                            ],
                            toUpload: filesToUpload,
                        }),
                    );

                    if (filesToUpload.length > 0) {
                        return dispatch(uploadBulkAssignment());
                    } else {
                        return getState().uploaderValue;
                    }
                }
            }

            if (
                isArrayWithContent(response.payload.errors) ||
                response.type === constants.ERROR ||
                response.type === constants.FAILURE
            ) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.ERROR,
                        message: "Opdracht uploaden mislukt",
                    }),
                );
            }
        });
    }
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
// reducer
export default reducer;

export const constants = _constants;
