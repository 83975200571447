// @flow

import createApiModule from "../create-api-module";
import { getRequest } from "./request";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { PersonUtil } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateRequestAssignee",
    ({ requestId }) => `/inspection-requests/${requestId}/assignee`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Aanvraag updaten mislukt"),
    },
);

/*
 *   execute call to update the status of a request
 *   and if succesful also update selected request record
 */
export const updateAssignee = (requestId: string, person: *) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { requestId }, data: { uuid: person.id } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: `Aanvraag toegewezen aan ${PersonUtil.display(
                        person,
                    )}`,
                }),
            );
            return dispatch(getRequest(requestId));
        }
        return null;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
