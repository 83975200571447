// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { startDownload } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "previewModulePdf",
    ({ inspectionID, moduleInstanceId }) =>
        moduleInstanceId
            ? `/inspections/${inspectionID}/preview-report?module-instance-id=${moduleInstanceId}`
            : `/inspections/${inspectionID}/preview-report`,
    {
        errorHandler: notifyApiError(
            "Tijdelijk verslag genereren mislukt",
            false,
        ),
    },
);

export const previewModulePdf = (
    inspectionID: string,
    moduleInstanceId?: string,
) => (dispatch: *) => {
    dispatch(
        notify({
            severity: NOTIFICATIONS.SEVERITY.INFO,
            message: "Tijdelijk verslag wordt gegenereerd...",
            autoHide: false,
        }),
    );
    return dispatch(
        load(
            {
                path: { inspectionID, moduleInstanceId },
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/pdf",
                },
                responseType: "arraybuffer",
            },
            true,
        ),
    ).then(response => {
        if (!response || response.type !== constants.SUCCESS) return null;
        const uri = window.URL.createObjectURL(new Blob([response.payload]));
        return startDownload(dispatch, uri, `OV-${inspectionID}.pdf`);
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
