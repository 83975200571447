// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { set } from "./requestValues";

const { constants: _constants, reducer, actions } = createApiModule(
    "request",
    ({ requestId }) => `/inspection-requests/${requestId}`,
    {
        method: "GET",
        errorHandler: notifyApiError("Details aanvraag ophalen mislukt"),
    },
);

export const getRequest = (requestId: string) => (dispatch: *) => {
    return dispatch(load({ path: { requestId } }, true)).then(action => {
        if (action && action.type === constants.SUCCESS) {
            return dispatch(set({ ...action.payload, requestId }));
        }
        return null;
    });
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
