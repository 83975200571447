// @flow

import createApiModule from "../create-api-module";
import { set } from "./assignmentsValues";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateAssignmentStatus",
    ({ inspectionId }) => `/inspections/${inspectionId}`,
    {
        headers: { "Content-Type": "application/json-patch+json" },
        method: "PATCH",
        errorHandler: notifyApiError("Status updaten mislukt"),
    },
);

/*
 *   execute call to update the status of an assignment
 *   and if succesful also update selected assignment record
 */
export const updateStatus = (
    inspectionId: string,
    operation: String,
    path: string,
    newValue: string,
) => (dispatch: *, getState: *) => {
    const query = [
        {
            op: operation,
            path,
            value: newValue,
        },
    ];
    return dispatch(load({ path: { inspectionId }, data: query }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Opdrachtstatus aangepast",
                    }),
                );
                let assignmentsValues = { ...getState().assignmentsValues };
                assignmentsValues.selectedRecord.status = newValue;
                return dispatch(set(assignmentsValues));
            }
            return null;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
