// @flow

/**
 * Check if str is valid json
 */

export const jsonValidation = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
