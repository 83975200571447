// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadReferences } from "./institution-type-references";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateInstitutionTypeReferenceGroup",
    ({ referenceId }) => `/institutiontype-references/${referenceId}/group`,
    {
        method: "PUT",
        errorHandler: notifyApiError(
            "Zorgvoorzieningsgroep voor zorgvoorzieningstype aanpassen mislukt",
        ),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const updateReferenceGroup = (referenceId: string, groupId: string) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { referenceId }, data: { uuid: groupId } }, true),
    ).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message:
                    "Zorgvoorzieningsgroep voor zorgvoorzieningstype aangepast",
            }),
        );
        return dispatch(loadReferences(undefined, true));
    });
};
