//@flow

import "./src/styles/index.scss";

import React from "react";
import { Provider } from "react-redux";
//Dont upgrade to @date-io/moment@2.X  while you use Pickers V3.x
// https://github.com/mui-org/material-ui-pickers/issues/1440#issuecomment-607133842
import { MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import momentTZ from "moment-timezone";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import theme from "./src/styles/theme";
import { create } from "./src/redux";
import "moment/locale/nl-be";

const store = create();
// this keeps the timezone offset on dates
// when they are serialized for ajax calls
momentTZ.fn.toJSON = function() {
    return this.format();
};
momentTZ.tz.setDefault("Europe/Brussels");
// source: https://github.com/moment/moment-timezone/issues/647
const useMomentLocale = (localeName: string) => {
    moment.locale(localeName);
    // eslint-disable-next-line no-underscore-dangle
    momentTZ.updateLocale(localeName, moment.localeData()._config);
    momentTZ.locale(localeName);
};
useMomentLocale("nl-be"); // available locales: en, nl-be, fr

export const wrapRootElement = ({ element }: *) => (
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider
                utils={MomentUtils}
                libInstance={momentTZ}
                locale="nl-be"
            >
                {element}
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </Provider>
);
