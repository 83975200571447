// @flow

const definition = [
    {
        label: "Bestandsnaam",
        id: "document",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Beschrijving",
        id: "description",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Geanonimiseerd",
        id: "anonymous",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Datum verzonden op ",
        id: "sentOn",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Aangemaakt door",
        id: "creator",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Aangemaakt op",
        id: "createdAt",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Gepubliceerd door",
        id: "publisher",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Gepubliceerd op",
        id: "publicationDate",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Acties",
        id: "actions",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
