// @flow

/**
 *   NOTE:
 *
 *   The reason we need this value store is because
 *   We need to be able to combine the modules data with
 *   the shortcomings data
 *   to display whether a module has any issues or not
 */

import createValueModule from "../create-value-module";
import cloneDeep from "lodash.clonedeep";

const { reducer, actions } = createValueModule("modules", []);

export default reducer;

export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;

export const updateModule = (updatedModule: *) => (
    dispatch: *,
    getState: *,
) => {
    let modules = cloneDeep(getState().modules);
    modules.forEach(type => {
        type.moduleInstances.forEach(module => {
            if (module.type === "PARENT") {
                module.submoduleInstances.forEach(subModule => {
                    if (subModule.instanceId === updatedModule.instanceId) {
                        subModule.isOfflineAvailable =
                            updatedModule.isOfflineAvailable;
                    }
                });
            } else {
                if (module.instanceId === updatedModule.instanceId) {
                    module.isOfflineAvailable =
                        updatedModule.isOfflineAvailable;
                }
            }
        });
    });

    dispatch(set(modules));
};
