// @flow

import createValueModule from "../create-value-module";
import { NOTIFICATIONS, ERRORS } from "@constants";

const { reducer, actions } = createValueModule("notifications", {
    severity: null,
    message: null,
    type: null,
    primaryAction: null,
    primaryActionText: null,
    secondaryAction: null,
    secondaryActionText: null,
    tertiaryAction: null,
    tertiaryActionText: null,
    autoHide: true,
});

/**
 *  Shortcut for emitting a snackbar error notification for api calls
 *  ATTN:   the structure of the <error> passed to this method
 *          is different from the one in the stores!
 *
 *  TODO:   api errors will likely get user-friendly messages in the future,
 *          update here when this happens
 */

const transformError = (error: *) => {
    if (!error) return undefined;
    const fallback = `${error.statusText} (${error.status})`;
    if (error?.data) {
        const textForKey = ERRORS[error.data.code];
        return textForKey ? `${textForKey} (${error.status})` : fallback;
    }
    return fallback;
};

export const notifyApiError = (
    meaningfulMessage?: string,
    autoHide?: boolean = true,
) => (dispatch: *, error?: *, overRideMessage?: string | Function) => {
    // let the interceptor handle the unauthenticated errors
    // it will show its own dialog, don't wanna override that
    if (error && (error.status === 401 || error.status === 403)) return;

    let errorMessage;
    const transformed = transformError(error);
    if (overRideMessage && typeof overRideMessage === "string") {
        errorMessage = error //$FlowFixMe
            ? `${overRideMessage}: ${transformed})`
            : overRideMessage;
    } else if (overRideMessage && typeof overRideMessage !== "string") {
        errorMessage = overRideMessage(error);
    } else {
        errorMessage =
            meaningfulMessage && error //$FlowFixMe
                ? `${meaningfulMessage}: ${transformed})`
                : transformed || "Oeps, er is iets misgegaan";
    }
    dispatch(
        set({
            severity: NOTIFICATIONS.SEVERITY.ERROR,
            message: errorMessage,
            type: "snackbar",
            autoHide,
        }),
    );
};

export default reducer;

export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
