export const COUNTRIES = [
    "België",
    "Nederland",
    "Frankrijk",
    "Duitsland",
    "Verenigd Koninkrijk",
    "Luxemburg",
].sort();

export const FILE_FORMATS_EXTENTIONS = [
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".txt",
    ".odp",
    ".ods",
    ".pdf",
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".mp3",
    ".mp4",
    ".asf",
    ".wma",
    ".wav",
    ".asx",
    ".wma",
    ".wmv",
    ".avi",
    ".mof",
    ".ogg",
    ".rtf",
    ".zip",
    ".msg",
    ".oft",
    ".eml",
    ".emlx",
    ".mbox",
];

export const MIME_FORMATS = {
    "application/msword": [".doc", ".docx"],
    "application/ogg": [".ogg"],
    "application/pdf": [".pdf"],
    "text/rtf": [".rtf"],
    "application/vnd.ms-excel": [".xls", ".xlsx"],
    "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
    "application/vnd.oasis.opendocument.presentation": [".odp"],
    "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
    "application/zip": [".zip"],
    "audio/mpeg": [".mpeg"],
    "audio/ogg": [".oga"],
    "audio/wav": [".wav"],
    "image/gif": [".gif"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "message/rfc822": [".msg", ".oft", ".eml", ".emlx", ".mbox"],
    "text/plain": [".txt"],
    "video/mp4": [".mp4"],
    "video/mpeg": [".mp3"],
    "video/ogg": [".ogg"],
    "video/x-ms-asf": [".asf"],
    "video/x-ms-wmv": [".wmv"],
    "video/x-ms-wma": [".wma", ".asx", ".wma", ".mof"],
    "video/avi": [".avi"],
};
