// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getReportsForAssignment } from "./get-reports-for-assignment";

const { constants: _constants, reducer, actions } = createApiModule(
    "suppressReport",
    ({ inspectionId, reportId }) =>
        `/inspections/${inspectionId}/reports/${reportId}/publication`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Definitief verslag onderdrukken mislukt"),
    },
);

export const suppressReport = (inspectionId: string, reportId: string) => (
    dispatch: *,
) => {
    return dispatch(
        load(
            {
                path: { inspectionId, reportId },
            },
            true,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Definitief verslag is succesvol onderdrukt",
                }),
            );
            dispatch(getReportsForAssignment(inspectionId));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
