// @flow

const definition = [
    {
        label: "Markering",
        id: "description",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Opmerking",
        id: "comment",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Startdatum",
        id: "startdate",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Einddatum",
        id: "enddate",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
