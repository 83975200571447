// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { getReportsForAssignment } from "./get-reports-for-assignment";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "overrideDraftReport",
    ({ inspectionId }) => `/inspections/${inspectionId}/draft-reports`,

    {
        method: "PUT",
        errorHandler: notifyApiError("Ontwerpverslag overschrijven mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const overrideDraftReport = (
    inspectionId: string,
    newDocumentId: string,
    oldReportId: string,
) => (dispatch: *) =>
    dispatch(
        load(
            { path: { inspectionId }, data: { newDocumentId, oldReportId } },
            true,
        ),
    ).then((response: *) => {
        console.log("Override succeeded");
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Ontwerpverslag succesvol overschreven",
            }),
        );
        return dispatch(getReportsForAssignment(inspectionId));
    });
