// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "submitRequest",
    () => `/inspection-requests`,
    {
        method: "POST",
        clearOnError: true,
        errorHandler: notifyApiError("Aanvraag aanmaken mislukt"),
    },
);

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
