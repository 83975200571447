// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getDisclosureLevelsParamsAction } from "./get-disclosure-levels-params";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateDisclosureLevelsParams",
    ({ id }) => `/disclosure-levels/${id}`,
    {
        method: "PUT",
        errorHandler: notifyApiError(
            "Parameters publicatie / actieve openbaarheid sturen aanpassen mislukt",
        ),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const updateDisclosureLevelsParamsAction = (data: *) => (
    dispatch: *,
) => {
    dispatch(clear());
    return dispatch(load({ path: { id: data.id }, data }, true)).then(
        response => {
            if (!response || response.type !== constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message:
                        "Parameters publicatie / actieve openbaarheid sturen aangepast",
                }),
            );
            return dispatch(getDisclosureLevelsParamsAction(undefined, true));
        },
    );
};
