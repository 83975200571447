// @flow

const definition = [
    {
        label: "",
        id: "",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Beschrijving",
        id: "description",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Bestandsnaam",
        id: "file-name",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Type",
        id: "type",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
