// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getExemptions } from "./exemptions";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "addExemption",
    ({ inspectionPointId }) =>
        `/inspectionpoints/${inspectionPointId}/exemptions`,
    {
        method: "POST",
        errorHandler: notifyApiError("Afwijking toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const addExemption = (
    inspectionPointId: string,
    data: *,
    includeArchived: boolean,
) => (dispatch: *) => {
    return dispatch(load({ path: { inspectionPointId }, data })).then(
        response => {
            if (!response || response.type !== constants.SUCCESS) return false;

            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Afwijking toegevoegd.",
                }),
            );

            return dispatch(
                getExemptions(
                    { path: { inspectionPointId, includeArchived } },
                    true,
                ),
            );
        },
    );
};
