// @flow

const definition = [
    {
        label: "Aanvraag",
        id: "reference",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Inspectiepuntnaam",
        id: "inspectionPointName",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Bestandsnaam",
        id: "fileName",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },

    {
        label: "Actie",
        id: "action",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
];

export default definition;
