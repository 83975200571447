// @flow

import createApiModule from "../create-api-module";
import { update } from "./assignmentsValues";
import { notifyApiError, update as notify } from "./notifications";
import { PersonUtil } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "leadInspector",
    ({ inspectionId, inspectorId }) =>
        `/inspections/${inspectionId}/lead-inspector?inspector-id=${inspectorId}`,
    {
        method: "PATCH",
        errorHandler: notifyApiError("Hoofdinspecteur aanpassen mislukt"),
    },
);

/*
 *   execute call to update lead inspector
 *   and if succesful also update selected assignment record
 */
export const updateLeadInspector = (
    inspectionId: string,
    leadInspector: { id: string, firstName: string, lastName: string },
    updateAssignee?: boolean = false,
) => (dispatch: *) => {
    //
    return dispatch(
        load({ path: { inspectionId, inspectorId: leadInspector.id } }),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: "success",
                    message: `${PersonUtil.display(
                        leadInspector,
                    )} toegewezen als hoofdinspecteur`,
                }),
            );
            return updateAssignee
                ? dispatch(
                      update({
                          selectedRecord: {
                              leadInspector,
                              assignee: leadInspector,
                          },
                      }),
                  )
                : dispatch(update({ selectedRecord: { leadInspector } }));
        }
        return null;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
