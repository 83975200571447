// @flow

import isEqual from "lodash.isequal";
import merge from "lodash.merge";
import { CancelToken } from "axios";

import { createConstants } from "./utils";

// just to get a permanent ref to a changing thing for every call made
let activeCancelTokenMap = new Map();

const STAGES = ["reset", "pending", "failure", "success", "cancel"];
const CANCEL_MESSAGE = "Request cancelled!";

const DEFAULT_OPTIONS = {
    reducer: null,
    initialState: {},
    method: "GET",
    headers: {},
    clearOnFetch: false,
    clearOnError: false,
    debounce: false, // TODO: To implement
    errorHandler: null,
};

/**
 * Create the module reducer
 */
const createReducer = (constants, options) => {
    const initialState = {
        ...options.initialState,
        loading: false,
        error: null,
        data: null,
        params: null,
    };

    return (state: * = { ...initialState }, action: *) => {
        const { type, payload } = action;

        // console.log("create-api-module reducer", action);
        if (options.reducer) {
            const [reducedState, proceed] = options.reducer(state, action);

            if (!proceed) {
                return reducedState;
            }

            state = reducedState;
        }

        if (type === constants.RESET) {
            return { ...initialState };
        }

        if (type === constants.PENDING) {
            return {
                ...state,
                params: payload,
                loading: true,
                error: null,
                data: options.clearOnFetch ? null : state.data,
            };
        }

        if (type === constants.SUCCESS) {
            return {
                ...state,
                loading: false,
                error: null,
                data: payload,
            };
        }

        if (type === constants.FAILURE) {
            return {
                ...state,
                loading: false,
                error: payload,
                data: options.clearOnError ? null : state.data,
            };
        }

        if (type === constants.CANCEL) {
            if (
                activeCancelTokenMap.has(constants.CANCEL) &&
                activeCancelTokenMap.get(constants.CANCEL)
            ) {
                const cancel = activeCancelTokenMap.get(constants.CANCEL);
                // $FlowFixMe
                cancel(CANCEL_MESSAGE);
                return { ...state, loading: false, error: null, data: null };
            } else return state;
            /*throw new ReferenceError(
                    "No active cancel token available for cancelling!",
                );*/
        }

        return state;
    };
};

/**
 * Create the load action
 *
 * DISCLAIMER: Omitting per method caching since backend is inconsistent
 * TODO: Add support for cancellation when forcing a reload
 * TODO: Convert getPath to string OR function
 */
const createLoadAction = (namespace, getPath, constants, options) => (
    params: * = {},
    force: boolean = false,
    overrideErrorMessage?: string | Function,
) => (dispatch: *, getState: *, client: *) => {
    const state = getState()[namespace];

    // Merge with current params
    params = merge({}, state.params, params);

    // Skip if loading still
    if (state.loading) {
        return Promise.resolve("LOADING");
    }

    // Skip if same
    if (!force && isEqual(state.params, params)) {
        if (state.error) {
            return Promise.reject(state.error);
        }

        return Promise.resolve("SUCCESS");
    }

    // Dispatch loading action
    dispatch({
        type: constants.PENDING,
        payload: params,
    });

    let config = {
        url: getPath(params.path),
        method: options.method,
        headers: options.headers,
        data: params.data,
        cancelToken: new CancelToken((token: any) => {
            activeCancelTokenMap.set(constants.CANCEL, token);
        }),
    };

    if (params.responseType) {
        config = merge({}, config, { responseType: params.responseType });
    }

    const request = {
        ...config,
    };

    return client
        .request(request)
        .then(response =>
            dispatch({
                type: constants.SUCCESS,
                payload: response.data,
            }),
        )
        .catch(error => {
            // NOTE: the error passed to the handler is more detaild than what ends up
            // in the store. The store error is the restult of error.toJSON()
            if (error.message === CANCEL_MESSAGE) return;
            if (options.errorHandler)
                options.errorHandler(
                    dispatch,
                    error.response,
                    overrideErrorMessage,
                );

            return dispatch({
                type: constants.FAILURE,
                payload: error.response,
            });
        });
};

/**
 * Create an api module
 */
export default (
    namespace: string,
    getPath: (pathParams: *) => string,
    options: * = {},
) => {
    options = {
        ...DEFAULT_OPTIONS,
        ...options,
    };

    const constants = createConstants(STAGES, "api", namespace);

    const load = createLoadAction(namespace, getPath, constants, options);

    // Should be called actionCreators but called actions for simplicity
    const actions = {
        clear: () => ({
            type: constants.RESET,
        }),
        load,
        refetch: () => load(undefined, true),
        cancel: () => ({ type: constants.CANCEL }),
    };

    return {
        constants,
        reducer: createReducer(constants, options),
        actions,
    };
};
