// @flow
const definition = [
    {
        label: "",
        id: "calendarIcon",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Type",
        id: "type",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Omschrijving",
        id: "description",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "E-mail",
        id: "email",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Acties",
        id: "actions",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
