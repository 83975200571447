// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "requestsForInspectionPoint",
    ({ inspectionPointId }) =>
        `inspectionpoints/${inspectionPointId}/inspection-requests`,
    {
        method: "GET",
        errorHandler: notifyApiError(
            "Extra koppelbare aanvragen ophalen mislukt",
        ),
    },
);

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
