// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadExternalGroups } from "./get-external-groups";

const { constants: _constants, reducer, actions } = createApiModule(
    "addExternalGroupEmail",
    ({ groupId }) => `/fe-groups/${groupId}/email-addresses`,
    {
        method: "POST",
        errorHandler: notifyApiError("Email toevoegen aan groep mislukt"),
    },
);

/*
 *   execute call to add email to external group
 */
export const addExternalGroupEmail = (
    groupId: string,
    emailAddress: string,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { groupId }, data: { emailAddress: emailAddress } }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Email toegevoegd aan groep",
                }),
            );
            return dispatch(loadExternalGroups(undefined, true));
        }
        return true;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
