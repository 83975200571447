// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadFormsSummary } from "./forms-summary";

const { constants: _constants, reducer, actions } = createApiModule(
    "formsScheduleCancel",
    ({ formId, versionId }) =>
        `/forms/${formId}/versions/${versionId}/cancel-scheduling`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Formulier (de)activering cancel mislukt"),
    },
);
export const formsScheduleCancel = (formId: string, versionId: string) => (
    dispatch: *,
) => {
    dispatch(clear());
    return dispatch(load({ path: formId, versionId }, true)).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Formulier (de)activering cancel succesvol",
            }),
        );
        dispatch(loadFormsSummary(undefined, true));
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
