// @flow
import createValueModule from "../create-value-module";
import moment from "moment-timezone";
import {
    selectedPoint,
    locationsAndTypes,
    selectedAssignmentReasons,
    selectedDatesInspection,
} from "../../definitions";

import { validateAndUpdateStore, validateAndUpdateStoreMultiple } from "@utils";
moment.tz.setDefault("Europe/Brussels");

/**
 *  STORE
 */

const { reducer, actions } = createValueModule("createInspection", {
    pages: [
        {
            id: "zoek-inspectiepunt",
            title: "Inspectiepunt zoeken",
            isValid: false,
            isComplete: false,
        },
        {
            id: "kies-inspectiepunt",
            title: "Gegevens inspectiepunten toevoegen",
            isValid: false,
            isComplete: false,
        },
        {
            id: "kies-redenen",
            title: "Selecteer redenen",
            isValid: false,
            isComplete: false,
        },
        {
            id: "data-aanvullen",
            title: "Datums en bijlages",
            isValid: false,
            isComplete: false,
        },
        {
            id: "opdracht-overzicht",
            title: "Opdracht overzicht",
            isValid: true,
            issues: [],
        },
    ],
    activePage: 0,
    selectedPoint: null,
    selectedReasons: null,
    inspectionCreatedAt: moment().startOf("day"),
    planVisitDateAfter: null,
    planVisitDateBefore: null,
    leadInspector: "",
    reference: "",
    visits: [],
    mainInstitutionType: null,
    institutionTypes: null,
    activityTypes: null,
    treeId: -1,
});

export default reducer;

export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;

/**
 *  VALIDATION
 */

const FIELDS = [
    selectedPoint,
    locationsAndTypes,
    selectedAssignmentReasons,
    selectedDatesInspection,
];
export const validateAndUpdateInspection = validateAndUpdateStore(
    "createInspection",
    set,
    FIELDS,
);
export const validateAndUpdateInspectionMultiple = validateAndUpdateStoreMultiple(
    "createInspection",
    set,
    FIELDS,
);
