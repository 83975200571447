//@flow

export const deleteAttachmentFromFe = (
    fileId: string,
    uploaderValue?: *,
    updateFiles: Function,
) => {
    if (!uploaderValue || !fileId) return;

    let acceptedFiles = [...uploaderValue.uploaded];
    const uploaded = acceptedFiles.filter(el => el.uuid !== fileId);
    updateFiles({ ...acceptedFiles, uploaded });
};
