const STATUSSES = {
    CREATED: "CREATED",
    PLANNED: "PLANNED",
    PREPARATION_STARTED: "PREPARATION_STARTED",
    PREPARED: "PREPARED",
    DRAFT_REVIEW_REQUESTED: "DRAFT_REVIEW_REQUESTED",
    DRAFT_REVIEWED: "DRAFT_REVIEWED",
    DRAFT_REPORT_CREATED: "DRAFT_REPORT_CREATED",
    DRAFT_READY_TO_PUBLISH: "DRAFT_READY_TO_PUBLISH",
    DRAFT_REPORT_PUBLISHED: "DRAFT_REPORT_PUBLISHED",
    REACTION_RECEIVED: "REACTION_RECEIVED",
    REACTION_PROCESSED: "REACTION_PROCESSED",
    FINAL_REVIEW_REQUESTED: "FINAL_REVIEW_REQUESTED",
    FINAL_REVIEWED: "FINAL_REVIEWED",
    FINAL_REPORT_CREATED: "FINAL_REPORT_CREATED",
    FINAL_READY_TO_PUBLISH: "FINAL_READY_TO_PUBLISH",
    FINAL_REPORT_PUBLISHED: "FINAL_REPORT_PUBLISHED",
    REOPENED: "REOPENED",
    CANCELLED: "CANCELLED",
    CLOSED: "CLOSED",
};

export const ASSIGNMENTS = {
    TYPES: [
        { label: "Financieel", value: "FINANCIEEL" },
        { label: "Inhoudelijk", value: "INHOUDELIJK" },
        { label: "Financieel & Inhoudelijk", value: "FINANCIEEL_INHOUDELIJK" },
    ],
    LABELS: {
        CREATED: "Toekennen",
        PLANNED: "Voorbereiding",
        PREPARED: "Uitvoeren",
        DRAFT_REVIEW_REQUESTED: "Kwaliteitscontrole OV",
        DRAFT_REVIEWED: "Feedback OV",
        DRAFT_REPORT_CREATED: "OV aangemaakt",
        DRAFT_READY_TO_PUBLISH: "OV te versturen",
        DRAFT_REPORT_PUBLISHED: "Reactietermijn",
        REACTION_RECEIVED: "Verwerk reacties",
        REACTION_PROCESSED: "Reactie verwerkt",
        FINAL_REVIEW_REQUESTED: "Kwaliteitscontrole DV",
        FINAL_REVIEWED: "Feedback DV",
        FINAL_REPORT_CREATED: "DV aangemaakt",
        FINAL_READY_TO_PUBLISH: "DV te versturen",
        FINAL_REPORT_PUBLISHED: "DV verstuurd",
        CANCELLED: "Geannuleerd",
        CLOSED: "Definitief",
        REOPENED: "Heropend ",
    },
    STATUSSES,
    STAGES: {
        PREPARED: "",
        DRAFT_REVIEW_REQUESTED: "givefeedback",
        DRAFT_REVIEWED: "processfeedback",
    },
    MODES: {
        EDIT: "edit",
        REVIEW: "review",
        READ_ONLY: "readonly",
    },
    CASES: {
        OPDRACHT: [
            "CREATED",
            "PLANNED",
            "PREPARED",
            "DRAFT_REVIEWED",
            "REACTION_RECEIVED",
            "DRAFT_REPORT_PUBLISHED",
            "DRAFT_REVIEW_REQUESTED",
            "PREPARATION_STARTED",
            "DRAFT_REVIEW_REQUESTED",
            "DRAFT_REPORT_CREATED",
            "REACTION_PROCESSED",
            "FINAL_REVIEW_REQUESTED",
            "FINAL_REVIEWED",
            "FINAL_REPORT_CREATED",
            "FINAL_REPORT_PUBLISHED",
            "CANCELLED",
            "CLOSED",
        ],
        VERSLAGEN: [],
        FORMULIEREN: [
            "PLANNED",
            "PREPARED",
            "DRAFT_REVIEWED",
            "DRAFT_REVIEW_REQUESTED",
        ],
        REACTIE: [
            "DRAFT_REPORT_PUBLISHED",
            "REACTION_RECEIVED",
            "REACTION_PROCESSED",
            "REACTIE BEHANDELD",
            "FINAL_REVIEW_REQUESTED",
            "FINAL_REVIEWED",
            "FINAL_REPORT_CREATED",
            "FINAL_REPORT_PUBLISHED",
            "CANCELLED",
            "CLOSED",
        ],
        NOTA: [],
    },
    PERMITTED: {
        REACTIE: [
            STATUSSES.DRAFT_REPORT_PUBLISHED,
            STATUSSES.REACTION_RECEIVED,
            STATUSSES.REACTION_PROCESSED,
            STATUSSES.FINAL_REVIEW_REQUESTED,
            STATUSSES.FINAL_REVIEWED,
            STATUSSES.FINAL_REPORT_CREATED,
            STATUSSES.FINAL_REPORT_PUBLISHED,
            STATUSSES.CANCELLED,
            STATUSSES.CLOSED,
        ],
        VERSTUREN: [],
        CHANGE_VISIT: [
            STATUSSES.CREATED,
            STATUSSES.PLANNED,
            STATUSSES.PREPARED,
            STATUSSES.DRAFT_REVIEWED,
            STATUSSES.DRAFT_READY_TO_PUBLISH,
            STATUSSES.REACTION_RECEIVED,
            STATUSSES.FINAL_REVIEWED,
            STATUSSES.FINAL_READY_TO_PUBLISH,
            STATUSSES.REOPENED,
        ],
        CHANGE_REASON: [
            STATUSSES.CREATED,
            STATUSSES.PLANNED,
            STATUSSES.PREPARED,
        ],
        CHANGE_DRAFT_DEADLINE: [
            STATUSSES.PREPARED,
            STATUSSES.DRAFT_REVIEW_REQUESTED,
            STATUSSES.DRAFT_REVIEWED,
        ],
        CHANGE_REACTION_TERM: STATUSSES.DRAFT_REPORT_PUBLISHED,
        CHANGE_FINAL_DEADLINE: [
            STATUSSES.REACTION_RECEIVED,
            STATUSSES.REACTION_PROCESSED,
        ],
        BEFORE_FINAL_REPORT_SENT: [
            STATUSSES.CREATED,
            STATUSSES.PLANNED,
            STATUSSES.PREPARATION_STARTED,
            STATUSSES.PREPARED,
            STATUSSES.DRAFT_REVIEW_REQUESTED,
            STATUSSES.DRAFT_REVIEWED,
            STATUSSES.DRAFT_REPORT_CREATED,
            STATUSSES.DRAFT_READY_TO_PUBLISH,
        ],
    },
    REACTIES_VALUES: {
        OK: "Akkoord",
        FOR_INFO_ONLY: "Verbeteracties",
        DEADLINE_EXTENSION_REQUESTED: "Verlenging",
        CHANGE_REQUESTED: "Inhoudelijke reactie",
    },
    REACTIES_FIELDS: [
        { key: "basicData", label: "Aanpassing basisgegevens" },
        { key: "content", label: "Inhoudelijke aanpassing" },
        { key: "noChange", label: "Geen aanpassing" },
    ],
    AUTHORISED_TO_PROCESS_REACTION: ["CHANGE_REQUESTED"],
    TEKORTEN_STATUSES: {
        ACTIVE: { text: "", color: "", status: "ACTIVE" },
        FOLLOW_UP: { text: "Opvolging", color: "error", status: "FOLLOW_UP" },
        SOLVED: { text: "Weggewerkt", color: "disabled", status: "RESOLVED" },
        PASSED_ON: {
            text: "Doorgegeven",
            color: "disabled",
            status: "PASSED_ON",
        },
    },
    DATE_KEYS: [
        "inspectionCreatedAt",
        "planVisitDateBefore",
        "planVisitDateAfter",
    ],
    HISTORY: {
        ASSIGN: "Opdracht toegekend aan",
        CREATE: "Opdracht aangemaakt",
        REQUEST_REVIEW: "Kwaliteitscontrole",
        REVIEW: "Feedback",
        ADD_REACTION: "Reactie toegevoegd",
        DELETE_REACTION: "Reactie verwijderd",
        STATUS_TRANSITION: "Statusovergang",
        DELETE_INSPECTION_CONTACT: "Contactpersoon verwijderd",
        DEADLINE_EXTENDED: "Deadline verlengd",
        ADD_INSPECTOR: "Inspecteur toegevoegd",
        REMOVE_INSPECTOR: "Inspecteur verwijderd",
        DELETE_NOTE: "Nota verwijderd",
        ASSIGN_REVIEW: "Reviewer toegewezen",
        UPDATE_LAST_OBSERVATION_DONE_ON: "Datum laatste vaststelling aangepast",
        EXTEND_REACTION_DEADLINE: "Reactietermijn verlengd",
        REQUEST_FINAL_REPORT_DEADLINE_EXTENSION:
            "Uitstel definitief verslag aangevraagd",
        REQUEST_DRAFT_REPORT_DEADLINE_EXTENSION:
            "Uitstel ontwerpverslag aangevraagd",
        ADD_INSPECTION_REQUEST: "Aanvraag gekoppeld",
        REMOVE_INSPECTION_REQUEST: "Aanvraag ontkoppeld",
        EXTEND_DRAFT_REPORT_DEADLINE: "Streefdatum OV uitgesteld",
        EXTEND_FINAL_REPORT_DEADLINE: "Streefdatum DV uitgesteld",
        REMOVE_REACTION_ATTACHMENT: "Reactiebijlage verwijderd",
        UPDATE_REACTION: "Reactie aangepast",
        CREATE_REPORT: "Verslag aangemaakt",
        CHANGE_LEAD_INSPECTOR: "Hoofdinspecteur gewijzigd",
        CHANGE_REACTIONS_ALLOWED: "Reacties toegestaan gewijzigd",
        CHANGE_DISCLOSURE_LEVEL: "Openbaarheid aangepast",
        CHANGE_TEAM: "Team gewijzigd",
    },
    NOTIFICATION_TYPES: {
        DRAFT: "SEND_DRAFT_REPORT",
        FINAL: "SEND_FINAL_REPORT",
    },
    DISCLOSURE_LEVELS: {
        NONE: "Geen openbaarheid",
        ACTIVE: "Actieve openbaarheid",
        PASSIVE: "Passieve openbaarheid",
    },
    CONCLUSION_CHOICES: {
        NONE: "Diende niet verstuurd te worden.",
        OUTSIDE_MODULAR: "Werd buiten Modular verstuurd.",
    },
};
