// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getRequest } from "./request";

const { constants: _constants, reducer, actions } = createApiModule(
    "addRequestVisit",
    ({ requestId }) => `/inspection-requests/${requestId}/inspection-points`,

    {
        method: "POST",
        errorHandler: notifyApiError("Bezoek toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const batchAddRequestVisits = (requestId: string, data: Array<*>) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { requestId }, data }, true)).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Inspectiepunten toegevoegd aan aanvraag",
            }),
        );
        dispatch(clear());
        dispatch(getRequest(requestId));
        return true;
    });
};
