// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "organisationStructure",
    ({ inspectionPointId }) =>
        `/inspectionpoints/${inspectionPointId}/structure`,
    {
        errorHandler: notifyApiError("Organisatiestructuur ophalen mislukt"),
    },
);

export const loadOrganisationStructure = (inspectionPointId: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionPointId } }, true)).then(
        action => {
            // timed out
            if (!action || action.type !== constants.SUCCESS) {
                dispatch(clear());
                return null;
            }
            return true;
        },
    );
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
