// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateVisitPeriod",
    ({ inspectionId, visitId, periodId }) =>
        `/inspections/${inspectionId}/visits/${visitId}/periods/${periodId}`,

    {
        method: "PUT",
        errorHandler: notifyApiError("Bezoekperiode aanpassen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

/*
 *   execute call to update a visit period
 */
export const updateVisitPeriod = (
    inspectionId: string,
    visitId: string,
    periodId: string,
    data: *,
) => (dispatch: *) =>
    dispatch(
        load({ path: { inspectionId, visitId, periodId }, data }, true),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            return dispatch(
                notify({
                    message: "Bezoekmoment aangepast",
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                }),
            );
        }
        return false;
    });
