// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateInspectionPoint",
    ({ inspectionPointId }) => `/inspectionpoints/${inspectionPointId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Inspectiepunt aanpassen mislukt"),
    },
);
export const updateInspectionPoint = (inspectionPointId: string, data: *) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionPointId }, data }, true)).then(
        action => {
            if (!action || action.type !== constants.SUCCESS) return false;

            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Inspectiepunt aangepat",
                }),
            );
            return true;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
