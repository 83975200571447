import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

const { constants: _constants, reducer, actions } = createApiModule(
    "getInternalGroups",
    ({ groupId }) => {
        const base = "/groups";
        return groupId ? `${base}/${groupId}` : base;
    },
    {
        method: "GET",
        errorHandler: notifyApiError("Lijst van groepen ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
