// @flow

import createApiModule from "../create-api-module";
import { getRequest } from "./request";
import { clear as clearAttachments } from "./uploader-value";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateRequest",
    ({ requestId }) => `/inspection-requests/${requestId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Aanvraag updaten mislukt"),
    },
);

/*
 *   execute call to update the status of a request
 *   and if succesful also update selected request record
 */
export const updateRequest = (requestId: string, data: *) => (dispatch: *) => {
    return dispatch(load({ path: { requestId }, data }, true)).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Aanvraag aangepast",
                }),
            );
            dispatch(clearAttachments());
            return dispatch(getRequest(requestId));
        }
        return null;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
