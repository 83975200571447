// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as getInspectionPointDetails } from "./inspection-point-details";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeInspectionPointFlag",
    ({ inspectionPointId }) => `/inspectionpoints/${inspectionPointId}/flags`,
    {
        method: "DELETE",
        errorHandler: notifyApiError(
            "Inspectiepunt markering stopzetten mislukt",
        ),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const removeFlag = (inspectionPointId: string, data: *) => (
    dispatch: *,
) =>
    dispatch(load({ path: { inspectionPointId }, data }, true)).then(
        response => {
            if (!response || response.type !== constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Stopzetting markering succesvol bewaard",
                }),
            );
            return dispatch(
                getInspectionPointDetails(
                    { path: { id: inspectionPointId } },
                    true,
                ),
            );
        },
    );
