// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadNotes } from "./inspection-point-notes";

const { constants: _constants, reducer, actions } = createApiModule(
    "addInspectionPointNote",
    ({ inspectionPointId }) => `/inspectionpoints/${inspectionPointId}/notes`,
    {
        method: "POST",
        errorHandler: notifyApiError("Bericht sturen mislukt"),
    },
);

export const addNote = (
    inspectionPointId: string,
    content: string,
    documentId: * = undefined,
) => (dispatch: *) => {
    return dispatch(
        load(
            {
                path: { inspectionPointId },
                data: { content, documentId },
            },
            true,
        ),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Bericht is succesvol gestuurd",
                }),
            );
            dispatch(loadNotes({ path: { inspectionPointId } }, true));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
