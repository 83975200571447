// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeReason",
    ({ reasonId }) => `/reasons/${reasonId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Reden verwijderen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const removeReason = (reasonId: string) => (dispatch: *) => {
    return dispatch(load({ path: { reasonId } }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Reden verwijderd",
            }),
        );
        return true;
    });
};
