// @flow

const definition = [
    {
        label: "Actie",
        id: "action",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Nieuwe waarde",
        id: "new-value",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Bijgewerkt op",
        id: "updated-at",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
    {
        label: "Wie",
        id: "updated-by",
        isSortable: false,
        isFilterable: false,
        type: "text",
    },
];

export default definition;
