// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getCalendar } from "./inactivity-dates";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "addInactivityDate",
    () => `/scheduling-calendar/inactivity-dates`,
    {
        method: "POST",
        errorHandler: notifyApiError("Inactiviteitsdatum toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const addInactivityDate = (data: *) => (dispatch: *) => {
    return dispatch(load({ data }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Inactiviteitsdatum toegevoegd.",
            }),
        );

        return dispatch(getCalendar(undefined, true));
    });
};
