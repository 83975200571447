// @flow

import createApiModule from "../create-api-module";
import { loadInspection } from "./inspection";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateInspection",
    ({ inspectionId }) => `/inspections/${inspectionId}`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Opdracht updaten mislukt"),
    },
);

/*
 *   execute call to update the status of an assignment
 *   and if succesful also update selected assignment record
 */
export const updateInspectionProperties = (inspectionId: string, data: *) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId }, data }, true)).then(
        action => {
            if (!action || action.type !== constants.SUCCESS) return null;

            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Opdracht aangepast",
                }),
            );
            return dispatch(loadInspection(inspectionId));
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
