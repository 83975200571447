// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadExternalGroups } from "./get-external-groups";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeExternalGroupMember",
    ({ groupId, memberId }) => `/fe-groups/${groupId}/members/${memberId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Gebruiker verwijderen van groep mislukt"),
    },
);

/*
 *   execute call to remove member from external group
 */
export const removeExternalGroupMember = (
    groupId: string,
    memberId: string,
) => (dispatch: *) => {
    return dispatch(load({ path: { groupId, memberId } }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Gebruiker verwijderd van groep",
                    }),
                );
                return dispatch(loadExternalGroups(undefined, true));
            }
            return true;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
