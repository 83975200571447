// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { load as getCalendar } from "./inactivity-dates";
import {
    load as addDate,
    constants as addConstants,
} from "./add-inactivity-date";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeInactivityDate",
    ({ id }) => `/scheduling-calendar/inactivity-dates/${id}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Inactiviteitsdatum toevoegen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const deleteInactivityDate = (id: *) => (dispatch: *) => {
    return dispatch(load({ path: { id } }, true)).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Inactiviteitsdatum verwijderd.",
            }),
        );

        return dispatch(getCalendar(undefined, true));
    });
};

export const updateInactivityDate = (data: *) => (dispatch: *) => {
    return dispatch(
        load(
            { path: { id: data.id } },
            true,
            "Inactiviteitsdatum aanpassen mislukt",
        ),
    )
        .then((deleteResponse: *) => {
            if (!deleteResponse || deleteResponse.type !== constants.SUCCESS)
                return false;

            const { description, inactivityDate } = data;
            return dispatch(
                addDate(
                    { data: { description, inactivityDate } },
                    true,
                    "Inactiviteitsdatum aanpassen mislukt, originele datum werd verwijderd. Gelieve nieuwe datum opnieuw toe te voegen.",
                ),
            );
        })
        .then((addResponse: *) => {
            if (!addResponse || addResponse.type !== addConstants.SUCCESS)
                return false;

            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Inactiviteitsdatum toegevoegd.",
                }),
            );

            return dispatch(getCalendar(undefined, true));
        });
};
