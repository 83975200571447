// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { generateUniqueReference } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "inspectionForRequests",
    () => `/inspection-requests/inspection`,

    {
        method: "POST",
        errorHandler: notifyApiError(
            "Opdracht voor aanvragen aanmaken mislukt",
        ),
    },
);

export const createAssignment = (
    inspectionPointId: string,
    inspectionRequestIds: string[],
) => (dispatch: *) => {
    const reference: string = generateUniqueReference("ZI");

    return dispatch(
        load({ data: { inspectionPointId, inspectionRequestIds, reference } }),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: `Opdracht aangemaakt voor de gekozen aanvragen`,
                }),
            );
            return true;
        }
        return false;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
