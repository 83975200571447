// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getReportsForAssignment } from "./get-reports-for-assignment";

const { constants: _constants, reducer, actions } = createApiModule(
    "deleteReport",
    ({ inspectionId, reportId }) =>
        `/inspections/${inspectionId}/reports/${reportId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Verslag verwijderen mislukt"),
    },
);

export const deleteReport = (inspectionId: string, reportId: *) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId, reportId } }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Verslag is succesvol verwijderd",
                    }),
                );

                return dispatch(getReportsForAssignment(inspectionId));
            }
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
