// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateExternalReference",
    ({ inspectionId }) => `/inspections/${inspectionId}/external-reference`,

    {
        method: "PUT",
        errorHandler: notifyApiError(
            "Externe referentie kon niet worden aangepast",
        ),
    },
);

export const updateExternalReference = (inspectionId: string, value: *) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { inspectionId }, data: { value } }, true),
    ).then(response => {
        if (!response || response.type !== constants.SUCCESS) return false;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Externe referentie is met succes aangepast",
            }),
        );

        dispatch(loadInspection(inspectionId));
        return true;
    });
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
