// @flow
import React from "react";
import { REQUESTS } from "@constants";
import type { Definition } from "@types";

export const advancedRequests: Definition = [
    {
        label: "Aanvraagnummer",
        id: "reference",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        id: "inspection-point",
        label: "Inspectiepunten",
        type: "inspectionPoint",
        sortName: "inspectionPointName",
        isFilterable: true,
        isSortable: false,
        options: { hideStreet: false },
    },
    {
        label: "Hoofd-IP-nr",
        id: "external-id",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        type: "groups",
        isSortable: false,
        isFilterable: false,
    },
    {
        label: "Reden",
        id: "reason-id",
        type: "reason",
        isSortable: false,
        isFilterable: true,
    },
    {
        label: "Status",
        id: "status",
        isSortable: false,
        isFilterable: true,
        type: "text",
        options: Object.keys(REQUESTS.LABELS).map<*>((key, index) => ({
            label: REQUESTS.LABELS[key],
            value: key,
            index,
        })),
    },
    {
        label: "Toegewezen aan",
        id: "inspector-id",
        isSortable: false,
        isFilterable: true,
        type: "person",
        options: { single: true },
    },
    {
        label: "Team",
        id: "team-id",
        isSortable: false,
        isFilterable: true,
        type: "team",
    },
    {
        label: "Aanmaakdatum",
        id: "created",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Afgesloten",
        id: "closed",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Referentie opdrachtgever",
        id: "external-reference",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Aanvrager",
        id: "fe-contact-id",
        isSortable: false,
        isFilterable: true,
        type: "feUser",
    },
];

export const advancedRequestsForExternal: Definition = [
    {
        label: "Aanvraagnummer",
        id: "reference",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        id: "inspection-point",
        label: "Inspectiepunten",
        type: "inspectionPoint",
        sortName: "inspectionPointName",
        isFilterable: true,
        isSortable: false,
        options: { hideStreet: false },
    },
    {
        label: "Hoofd-IP-nr",
        id: "external-id",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: (
            <span>
                ZVT en <em>ZAT</em>
            </span>
        ),
        id: "groups",
        type: "groups",
        isSortable: false,
        isFilterable: false,
    },
    {
        label: "Reden",
        id: "reason-id",
        type: "reason",
        isSortable: false,
        isFilterable: true,
    },
    {
        label: "Status",
        id: "status",
        isSortable: false,
        isFilterable: true,
        type: "text",
        options: Object.keys(REQUESTS.LABELS).map<*>((key, index) => ({
            label: REQUESTS.LABELS[key],
            value: key,
            index,
        })),
    },
    {
        label: "Team",
        id: "team-id",
        isSortable: false,
        isFilterable: true,
        type: "team",
    },
    {
        label: "Aanmaakdatum",
        id: "created",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Afgesloten",
        id: "closed",
        sortName: "date",
        isSortable: false,
        isFilterable: true,
        type: "date",
        options: { showVisitFields: false },
    },
    {
        label: "Referentie opdrachtgever",
        id: "external-reference",
        isSortable: false,
        isFilterable: true,
        type: "text",
    },
    {
        label: "Aanvrager",
        id: "fe-contact-id",
        isSortable: false,
        isFilterable: true,
        type: "feUser",
    },
];
