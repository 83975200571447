// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { set as setUser } from "./user";

// dummy users
const USER_INSPECTOR =
    process.env.GATSBY_DUMMY_INSPECTOR &&
    JSON.parse(process.env.GATSBY_DUMMY_INSPECTOR);

const { constants: _constants, reducer, actions } = createApiModule(
    "userInfo",
    () => `/auth/userinfo`,
    {
        errorHandler: notifyApiError("Info gebruiker ophalen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const getUserInfo = () => (dispatch: *) => {
    return dispatch(load()).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;
        else {
            const { payload } = action;
            const rolesFlags = getRolesFlags(payload);
            dispatch(setUser({ ...payload, ...rolesFlags }));
            return true;
        }
    });
};

const getRolesFlags = user => {
    if (!user) return {};

    const hasRole = role => user.roles.includes(role);
    return {
        isSecretary: hasRole("ROLE_SECRETARIAT"),
        isInspector: hasRole("ROLE_INSPECTOR"),
        isAdmin: hasRole("ROLE_ADMIN"),
    };
};

/*
 *  mock load success
 */
export const loadSuccess = () => (dispatch: *) =>
    Promise.resolve(true)
        .then(() =>
            dispatch({
                type: constants.SUCCESS,
                payload: USER_INSPECTOR,
            }),
        )
        .then(({ payload }) => {
            const rolesFlags = getRolesFlags(payload);
            dispatch(setUser({ ...payload, ...rolesFlags }));
            return true;
        });
