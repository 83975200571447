// @flow

import moment from "moment-timezone";

/*
 *   Generate a "unique" reference for requests or inspections
 */
export const generateUniqueReference = (prefix: string) => {
    const year = moment().year();
    const uniqueId = moment().unix();
    return `${prefix}-${year}-${uniqueId}`;
};
