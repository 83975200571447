// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { loadInspection } from "./inspection";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateInspectionTeam",
    ({ inspectionId }) => `/inspections/${inspectionId}/team`,
    {
        method: "PUT",
        errorHandler: notifyApiError("Team voor inspectie aanpassen mislukt"),
    },
);

/*
 *   execute call to update the team of an inspection
 *   and if succesful also update selected inspection record
 */
export const updateInspectionTeam = (inspectionId: string, data: *) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { inspectionId }, data }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: `Team voor inspectie aangepast.`,
                    }),
                );
                return dispatch(loadInspection(inspectionId));
            }
            return null;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
