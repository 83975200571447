// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "cancelAssignment",
    ({ inspectionId }) => `/inspections/${inspectionId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Opdracht annuleren mislukt"),
    },
);

export const cancelAssignment = (inspectionId: string, motivation: string) => (
    dispatch: *,
) => {
    return dispatch(
        load({ path: { inspectionId }, data: { motivation } }),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            return dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Opdracht is succesvol geannuleerd",
                }),
            );
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
