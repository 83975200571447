// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, set as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

import { load as loadShortcoming } from "./shortcoming";

const { constants: _constants, reducer, actions } = createApiModule(
    "updateShortcoming",
    ({ inspectionPointId, shortcomingId }) =>
        `/inspectionpoints/${inspectionPointId}/shortcomings/${shortcomingId}`,
    {
        headers: { "Content-Type": "application/json-patch+json" },
        method: "PUT",
        errorHandler: notifyApiError("Inbreuk updaten mislukt"),
    },
);

export const updateShortcoming = (
    inspectionPointId: string,
    shortcomingId: string,
    data: *,
) => (dispatch: *) => {
    return dispatch(
        load({ path: { inspectionPointId, shortcomingId }, data }),
    ).then(action => {
        if (!action || action.type !== constants.SUCCESS) return null;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Inbreuk is aangepast",
            }),
        );
        return dispatch(
            loadShortcoming(
                { path: { inspectionPointId, shortcomingId } },
                true,
            ),
        );
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
