// @flow

import createApiModule from "../create-api-module";
import { getRequest } from "./request";
import { clear as clearAttachments } from "./uploader-value";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { uploadDocuments } from "./upload-documents";
import { isArrayWithContent } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "uploadRequestAttachment",
    ({ requestId }) => `/inspection-requests/${requestId}/attachments`,
    {
        method: "POST",
        errorHandler: notifyApiError("Bijlagen toevoegen aan aanvraag mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const uploadAttachments = (requestId: string, files: Array<*>) => (
    dispatch: *,
    getState: *,
) => {
    return dispatch(uploadDocuments(files))
        .then((uploaderValue: *) => {
            if (
                !uploaderValue ||
                !isArrayWithContent(uploaderValue.uploaded) ||
                !getState().requestValues
            )
                return null;
            const data = uploaderValue.uploaded.map(el => el.uuid);
            return dispatch(load({ path: { requestId }, data }, true));
        })
        .then(response => {
            if (!response || response.type !== constants.SUCCESS) return null;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Bijlagen succesvol toegevoegd aan de aanvraag",
                }),
            );
            //cleanup
            dispatch(clearAttachments());
            dispatch(clear());
            dispatch(getRequest(requestId));
            return true;
        });
};
