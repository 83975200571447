// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { isArrayWithContent } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "inspectionPoints",
    () => `/inspectionpoints/queries?max-result-size=200`,
    {
        method: "POST",
        errorHandler: notifyApiError("Inspectiepunten ophalen mislukt"),
    },
);

export const loadInspectionPoints = (requestedFilters: *) => (dispatch: *) => {
    if (requestedFilters["type"] === "") {
        delete requestedFilters["type"];
    }
    return dispatch(load({ data: requestedFilters }, true)).then(response => {
        if (response && response.type === constants.SUCCESS) {
            return isArrayWithContent(response.payload);
        }
        return false;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
