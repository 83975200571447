// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { loadInspection } from "./inspection";

const { constants: _constants, reducer, actions } = createApiModule(
    "linkRequests",
    ({ inspectionId }) => `/inspections/${inspectionId}/inspection-requests/`,
    {
        method: "POST",
        errorHandler: notifyApiError("Aanvragen koppelen mislukt"),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;

export const linkRequests = (inspectionId: string, requestIds: string[]) => (
    dispatch: *,
) =>
    dispatch(load({ path: { inspectionId }, data: requestIds }, true)).then(
        response => {
            if (!response || !response.type === constants.SUCCESS) return false;
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Aanvragen succesvol gekoppeld",
                }),
            );
            return dispatch(
                loadInspection(
                    inspectionId,
                    "Aanvragen succesvol gekoppeld, maar opdracht informatie vernieuwen mislukt",
                ),
            );
        },
    );
