// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";
import { constructQueryString } from "@utils";

const { constants: _constants, reducer, actions } = createApiModule(
    "getRegionalDistribution",
    ({ query, page, numOfRecords }) => {
        const qs = constructQueryString(query, true);
        let seperator = "?";
        if (qs.length > 0) {
            seperator = "&";
        }
        return `/regional-distribution${qs}${seperator}page=${page}&size=${numOfRecords}`;
    },
    {
        method: "GET",
        errorHandler: notifyApiError("Regioverdeling ophalen mislukt", false),
    },
);

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const update = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
