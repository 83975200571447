// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeClusterGroup",
    ({ clusterId, groupId }) => `/clusters/${clusterId}/groups/${groupId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Groep verwijderen van cluster mislukt"),
    },
);

/*
 *   execute call to remove group from cluster
 */
export const removeClusterGroup = (clusterId: string, groupId: string) => (
    dispatch: *,
) => {
    return dispatch(load({ path: { clusterId, groupId } }, true)).then(
        action => {
            if (action && action.type === constants.SUCCESS) {
                dispatch(
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                        message: "Groep verwijderd van cluster",
                    }),
                );
            }
            return true;
        },
    );
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
