// @flow
import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { getRequest } from "./request";

const { constants: _constants, reducer, actions } = createApiModule(
    "removeRequestVisit",
    ({ requestId, inspectionPointId }) =>
        `/inspection-requests/${requestId}/inspection-points/${inspectionPointId}`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Bezoek verwijderen mislukt"),
    },
);

export const removeRequestVisit = (
    requestId: string,
    inspectionPointId: string,
) => (dispatch: *) => {
    return dispatch(
        load({
            path: { requestId, inspectionPointId },
        }),
    ).then(action => {
        if (action && action.type === constants.SUCCESS) {
            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Bezoek is succesvol verwijderd",
                }),
            );
            dispatch(getRequest(requestId));
            return true;
        }
        return null;
    });
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
